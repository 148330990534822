<template>
  <TheHeader />
  <div class="container">
    <div v-if="!newJob && state.job?.id"><span>Job {{ state.job.id }} is {{ state.job.status }}</span><span v-if="state.job?.meta?.celery_task_id">&nbsp;[celery_task_id: {{ state.job.meta.celery_task_id }}]</span></div>
    <!-- Customer Selection -->
    <div v-if="!state.customers || state.customersLoading" class="spinner-border" role="status">
      <span class="visually-hidden">Loading customers list...</span>
    </div>
    <div v-else class="mb-3">
      <label for="customerInput" class="form-label pt-3">{{ jobConfig.customerLabel }}</label>
      <input 
        :disabled="!newJob" 
        type="text" 
        class="form-control" 
        id="customerInput" 
        :placeholder="jobConfig.customerLabel" 
        list="customers" 
        v-model="customerSelectedLabel"
      >
      <datalist id="customers">
        <option
          v-for="customer in state.customers"
          :key="customer.customer_id"
          :value="`(${customer.customer_id}) ${customer.schema_name}`"
        />
      </datalist>
    </div>

    <!-- Job-specific Options -->
    <template v-if="jobConfig.options">
      <div v-for="(option, index) in jobConfig.options" :key="index" class="mb-3">
        <label :for="`option-${index}`" class="form-label">{{ option.label }}</label>
        <select 
          v-if="option.type === 'select'"
          :disabled="!newJob" 
          :id="`option-${index}`" 
          class="form-control" 
          v-model="state[option.key]"
        >
          <option v-for="opt in option.values" :key="opt.value" :value="opt.value">
            {{ opt.label }}
          </option>
        </select>
      </div>
    </template>

    <!-- Action Buttons -->
    <div class="d-flex">
      <button 
        v-if="newJob" 
        class="searchButton cbFormTextField-sm mt-2" 
        type="button" 
        @click="run_job"
      >
        {{ jobConfig.buttonText }}
      </button>
      <template v-if="jobConfig.extraButtons">
        <button 
          v-for="(btn, index) in jobConfig.extraButtons" 
          :key="index"
          class="searchButton cbFormTextField-sm mt-2" 
          type="button"
          @click="() => btn.action(btn.url)"
        >
          {{ btn.text }}
        </button>
      </template>
    </div>
    <!-- Logs Section -->
    <div class="mb-3" v-if="!newJob" style="margin-top: 20px">
      <label for="logs" class="form-label">Logs</label>
      <div v-if="state.logsLoading" class="spinner-border" role="status" style="width: 15px; height: 15px">
        <span class="visually-hidden">Loading...</span>
      </div>
      <a v-if="!newJob && cloudwatchLink" :href="cloudwatchLink" target="_blank" class="sm">
        View in CloudWatch
      </a>
      <textarea 
        class="form-control" 
        id="logs" 
        v-bind:value="state.logsText" 
        style="height: 50vh"
        readonly
      ></textarea>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, reactive } from "vue";
import type { Customer } from "@/helpers/interface/trueload";
import { useAPI } from "@/helpers/services/api";
import type { Log, LogRequest } from "@/helpers/interface/onboarding";
import TheHeader from "@/components/TheHeader.vue";
import { useRouter } from "vue-router";

const api = useAPI();
const router = useRouter();
const job_id = computed(() => router.currentRoute.value.params.job_id as string);
const newJob = computed(() => job_id.value === 'new');
const task = computed(() => router.currentRoute.value.name as string);
const cloudwatchLink = computed(() => {
  const logStream = state.job?.meta?.log_stream_name;
  const logGroup = state.job?.meta?.log_group_name;
  const region = 'us-west-2';
  const startTime: number = state.job?.started_at ? Date.parse(state.job.started_at) : 0;
  const endTime: number = state.job?.stopped_at ? Date.parse(state.job.stopped_at) : (new Date()).getTime();
  if (!logGroup || !logStream || !startTime) return '';

  return `https://${region}.console.aws.amazon.com/cloudwatch/home?region=${region}#logsV2:log-groups/log-group/${logGroup.replace(/\//g, '$252F')}/log-events/${logStream.replace(/\//g, '$252F')}$3Fstart$3D${startTime}$26end$3D${endTime}`;
}); 

interface Job {
  id: string
  status: string 
  user_id: string | null
  job_type: string
  customer_id: number
  meta: {
    log_group_name?: string
    log_stream_name?: string
    schema_name?: string
    source?: string
    queue?: string
    [key: string]: any // For other dynamic meta properties
  } | null
  output: {
    error_message?: string
    [key: string]: any // For other dynamic output properties
  } | null
  created_at: string // ISO datetime string
  started_at: string | null // ISO datetime string
  stopped_at: string | null // ISO datetime string
  updated_at: string // ISO datetime string
}

// Add this interface before JOB_CONFIGS
interface JobOption {
  key: string;
  label: string;
  type: string;
  default?: any;
  values: Array<{ value: any; label: string }>;
}

interface ExtraButton {
  text: string;
  url: string;
  action: (url?: string) => void;
}

interface JobConfig {
  task: string;
  customerLabel: string;
  buttonText: string;
  options?: JobOption[];
  extraButtons?: ExtraButton[];
  customersQuery?: string;
  formatPayload: (state: any) => any;
}

const JOB_CONFIGS: Record<string, JobConfig> = {
  generate_extracts: {
    task: 'generate_extracts',
    customerLabel: 'Schema',
    buttonText: 'Generate Extracts',
    options: [{
      key: 'extracts',
      label: 'Extracts',
      type: 'select',
      default: ['all'],
      values: [
        { value: ['all'], label: 'All' },
        { value: ['clear'], label: 'Clear' },
        { value: ['pcoa'], label: 'PCOA' },
        { value: ['scra'], label: 'SCRA' }
      ]
    }],
    formatPayload: (state: any) => ({
      celery_task_name: 'tasks.csse.handle_generate_extracts',
      job_type: 'generate_extracts',
      queue: 'beefy',
      customer_id: state.customer_id,
      pass_job_id: true,
      meta: {
        schema_name: state.schema,
        extract_list: state.extracts,
        source: 'webapp',
        queue: 'beefy',
        customer_id: state.customer_id
      },
      kwargs: {
        schema_name: state.schema,
        extract_list: state.extracts,
        customer_id: state.customer_id
      }
    })
  },
  generate_observations: {
    task: 'generate_observations',
    customerLabel: 'Customer',
    buttonText: 'Generate Observations',
    
    options: [{
      key: 'flag_list',
      label: 'Flags Generated',
      type: 'select',
      default: ['all'],
      values: [
        { value: ['all'], label: 'All' },
        { value: ['promon'], label: 'Proactive Monitoring' },
        { value: ['notes'], label: 'Notes' },
        { value: ['flags'], label: 'Flags' },
        { value: ['genie'], label: 'Applications Only' }
      ]
    }],  // todo add more options (refresh_mv, taxroll_to_inbox, prune_observations, upsert_flag_configs, slack_reporting)
    formatPayload: (state: any) => ({
      customer_id: state.customer_id,
      celery_task_name: 'tasks.csse.handle_generate_observations',
      pass_job_id: true,
      job_type: 'generate_observations',
      queue: 'beefy',
      meta: {
        source: 'webapp',
        queue: 'beefy',
        schema_name: state.schema,
        flag_list: state.flag_list
      },
      kwargs: {
        schema_name: state.schema,
        flag_list: state.flag_list
      }
    })
  },
  scorecard: {
    task: 'scorecard',
    customerLabel: 'Schema',
    buttonText: 'Generate Scorecard',
    options: [
      {
        key: 'new_metadata',
        label: 'New Metadata',
        type: 'select',
        default: false,
        values: [
          { value: true, label: 'true' },
          { value: false, label: 'false' }
        ]
      },
      {
        key: 'refresh',
        label: 'Refresh',
        type: 'select',
        default: false,
        values: [
          { value: true, label: 'true' },
          { value: false, label: 'false' }
        ]
      },
      {
        key: 'alert',
        label: 'Alert',
        type: 'select',
        default: false,
        values: [
          { value: true, label: 'true' },
          { value: false, label: 'false' }
        ]
      }
    ],
    extraButtons: [{
      text: 'Customer Scorecards',
      url: 'https://drive.google.com/drive/folders/1by1V1ZYeqkSIgn9cIKf2chijJJQkUu5U',
      action: (url?: string) => window.open(url, '_blank')
    }],
    formatPayload: (state: any) => ({
      customer_id: state.customer_id,
      celery_task_name: 'tasks.csse.handle_generate_scorecard',
      pass_job_id: true,
      job_type: 'scorecard',
      queue: 'beefy',
      kwargs: {
        schema_name: state.schema,
        customer_id: state.customer_id,
        new_metadata: state.new_metadata,
        refresh: state.refresh,
        alert: state.alert,
      },
      meta: {
        source: 'webapp',
        schema_name: state.schema,
        new_metadata: state.new_metadata,
        refresh: state.refresh,
        alert: state.alert,
      }
    })
  },
  refill_taxroll: {
    task: 'refill_taxroll',
    customerLabel: 'Schema',
    buttonText: 'Refill Taxroll Table',
    formatPayload: (state: any) => ({
      customer_id: state.customer_id,
      celery_task_name: 'tasks.csse.handle_refill_taxroll',
      pass_job_id: true,
      job_type: 'refill_taxroll',
      queue: 'beefy',
      kwargs: {
        schema_name: state.schema,
        customer_id: state.customer_id
      },
      meta: {
        source: 'webapp',
        schema_name: state.schema,
        customer_id: state.customer_id
      }
    })
  },
  cleanup: {
    task: 'cleanup',
    customerLabel: 'Customer',
    buttonText: 'Run Cleanup',
    options: [{
      key: 'generate_observations',
      label: 'Generate Observations',
      type: 'select',
      default: false,
      values: [
        { value: true, label: 'true' },
        { value: false, label: 'false' }
      ]
    }],
    customersQuery: 'customers/?valid_schemas=true&has_clone=true',
    formatPayload: (state: any) => ({
      customer_id: state.customer_id,
      celery_task_name: 'tasks.csse.handle_cleanup',  
      pass_job_id: true,
      job_type: 'cleanup',
      queue: 'beefy',
      kwargs: {
        customer_id: state.customer_id,
        schema_name: state.schema,
        obs: state.generate_observations
      },
      meta: {
        source: 'webapp',
        schema_name: state.schema,
        obs: state.generate_observations
      }
    })
  }
} as const;

const jobConfig = computed(() => {
  const config = JOB_CONFIGS[task.value as keyof typeof JOB_CONFIGS];
  if (!config) throw new Error(`Unknown task type: ${task.value}`);
  return config;
});

// Add this interface to define the dynamic state properties
interface DynamicState {
  [key: string]: any;  // This allows for dynamic properties
  customers: Customer[];
  task: string;
  customersLoading: boolean;
  logsLoading: boolean;
  customer_id: number | null;
  schema: string;
  enable_logs: boolean;
  logStreamEnded: boolean;
  logsText: string;
  lastLogLoadTime: number;
  job: Job | null;
  jobLoading: boolean;
}

// Update the state initialization with the new interface
const state = reactive<DynamicState>({
  customers: [] as Customer[],
  task: task.value,
  customersLoading: false,
  logsLoading: false,
  customer_id: null,
  schema: '',
  enable_logs: false,
  logStreamEnded: false,
  logsText: '',
  lastLogLoadTime: 0,
  job: null,
  jobLoading: false,
  // Initialize dynamic properties based on job config
  ...(jobConfig.value.options?.reduce((acc: Record<string, any>, opt: JobOption) => ({ 
    ...acc, 
    [opt.key]: opt.default ?? opt.values[0].value ?? ''
  }), {}))
});

const customerSelectedLabel = computed({
  get() {
    return state.customer_id 
      ? `(${state.customer_id}) ${state.customers.find((c: Customer) => c.customer_id === state.customer_id!.toString())?.schema_name}` 
      : '';
  },
  set(value: string) {
    const match = value.match(/^\(([^)]+)\)/);
    if (match) {
      state.customer_id = parseInt(match[1]);
      state.schema = state.customers.find((c: Customer) => c.customer_id === match[1])?.schema_name ?? '';
    }
  }
});

const run_job = async () => {
  const job_data = jobConfig.value.formatPayload(state);
  const response = await api.post(`jobs/run_job`, job_data);
  await router.push({
    name: state.task,
    params: { job_id: response.data! }
  });
  setTimeout(loadLogs, 500);
};

const loadLogs = async () => {
  state.jobLoading = true
  const jobResponse = await api.get(`jobs/internal/${job_id.value}`);
  state.job = jobResponse.data;
  
  state.jobLoading = false
  if (!state.job) {
    return;
  }

  state.customer_id = state.job.customer_id;
  jobConfig.value.options?.forEach((opt: JobOption) => {
    if (state.job?.meta && opt.key in state.job.meta) {
      state[opt.key] = state.job.meta[opt.key];
    }
  });
  
  if (state.job.status === 'pending') {
    setTimeout(loadLogs, 5000);
    return;
  }
  // todo fetch logs from job start time
  const logRequest = {
    start_time: state.lastLogLoadTime || (state.job.started_at ? Date.parse(state.job.started_at) : 0)
  } as LogRequest
  state.logsLoading = true
  const response = await api.get(`jobs/logs/${job_id.value}`, { params: logRequest })
  const newLogs: Log[] = response.data
  state.logsLoading = false

  const textarea = document.getElementById('logs') as HTMLInputElement

  newLogs.forEach(
    (log: Log) => {
      if (log.message === "STREAM END") {
        state.logStreamEnded = true
        return
      }
      if (!state.logStreamEnded) {
        state.lastLogLoadTime = log.timestamp + 1
        state.logsText += `${log.message}\n`;
        textarea.scrollTop = textarea.scrollHeight;
      }
    }
  );
  if (!state.logStreamEnded) {
    setTimeout(loadLogs, 5000)
  }
}


onMounted(async () => {
  const customersQuery = jobConfig.value.customersQuery ?? 'customers/?valid_schemas=true';
  state.customersLoading = true;
  const response = await api.get(customersQuery);
  state.customers = response.data;
  state.customersLoading = false;

  state.customer_id = state.job?.customer_id ?? null;
  if (state.customer_id) {
    state.schema = state.customers.find(
      (c: Customer) => c.customer_id === state.customer_id!.toString()
    )?.schema_name ?? '';
  } else {
    state.schema = '';
  }

  if (!newJob.value) {
    setTimeout(loadLogs, 500);
  }
});

onBeforeUnmount(() => {
  state.logStreamEnded = true;
});
</script>
