<template>
    <div id="historyList">
        <div v-if="!fetchAppHistoryListLoading">
            <DataTable :value="historyList" tableStyle="min-width: 50rem" class="align-top">
                <template #empty>
                    <div class="p-2 text-center">
                        No records found.
                    </div>
                </template>
                <Column field="update_details" header="Details">
                    <template v-slot:body="slotProps">
                        <!-- nosemgrep -->
                        <div v-html="cleanHTML(slotProps.data?.update_details || '')"></div>
                    </template>
                </Column>
                <Column field="created_by" header="User"></Column>
                <Column field="created_at" header="Date">
                    <template #body="slotProps">
                        {{ formatDate(slotProps.data.created_at) }}
                    </template>
                </Column>
            </DataTable>
        </div>

        <div v-else>
            <table class="table">
                <thead>
                    <tr>
                        <th>Details</th>
                        <th>User</th>
                        <th>Date</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="i in 4" :key="i">
                        <td>
                            <Skeleton height="1.2rem" width="200px" class="mb-2"></Skeleton>
                            <Skeleton height="1.2rem" width="160px" class="mb-2"></Skeleton>
                            <Skeleton height="1.2rem" width="110px" class="mb-2"></Skeleton>
                            <Skeleton height="1.2rem" width="170px" class="mb-2"></Skeleton>
                            <Skeleton height="1.2rem" width="120px" class="mb-2"></Skeleton>
                        </td>
                        <td>
                            <Skeleton height="1.2rem" width="120px"></Skeleton>
                        </td>
                        <td>
                            <Skeleton height="1.2rem" width="150px"></Skeleton>
                        </td>

                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script setup lang="ts">
import type { HistoryAPIResponse } from "@/helpers/interface/candidates"
import type { AxiosError } from "axios"
import { computed, onMounted, ref } from "vue";
import { useAPI } from "@/helpers/services/api"
import Skeleton from "primevue/skeleton"
import { convertLineBreaksToHTML, formatDate, getApiErrorMessage, sanitizeHTML } from "@/helpers/common";
import { toast } from "@/helpers/toast";
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import { useTaxroll } from "@/stores/taxroll";

const props = defineProps({
    tru_id: {
        type: String,
        required: true
    }
})

const api = useAPI()
const storeTaxroll = useTaxroll()
const fetchAppHistoryListLoading = computed(() => (storeTaxroll.fetchAppSummaryDetailLoading))
const historyList = computed(() => (storeTaxroll.getAppHistoryList))
const loadFailureHandler = (error: any) => {
    const message = getApiErrorMessage(error)
    toast.error(message)
}
const cleanHTML = (html: string) => sanitizeHTML(convertLineBreaksToHTML(html.trim())) || ""
const fetchHistoryList = async () => await storeTaxroll.fetchAppHistoryList(props.tru_id, loadFailureHandler)

onMounted(() => fetchHistoryList())
</script>
