<template>
    <div class="modal-overlay" :style="{ zIndex: 1 }"></div>
    <div class="modal" role="dialog" tabindex="-1" :style="{ display: 'inline', zIndex: 2 }">
        <div class="modal-dialog" :class="modalSize" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">
                        <span class="text-capitalize">{{ props.title }}</span>
                    </h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="props.close">
                        <span class="fa fa-times" aria-hidden="true"></span>
                    </button>
                </div>
                <div class="modal-body" :style="{ maxHeight: calculatedMaxHeight, overflowY: maxHeightPercentage ? 'auto' : 'visible' }">
                    <slot></slot>
                </div>
                <div class="modal-footer">
                    <slot name="footer"></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';

const props = defineProps<{
    title: string;
    close: (payload: MouseEvent) => void;
    size?: string; 
    maxHeightPercentage?: number; 
}>();

const modalSize = computed(() => {
    return props.size ? props.size : ""
})

const calculatedMaxHeight = ref('auto');

onMounted(() => {
    // Calculate max height when the component is mounted
    if (props.maxHeightPercentage) {
        calculatedMaxHeight.value = `${(window.innerHeight * (props.maxHeightPercentage / 100)).toFixed(0)}px`;
    }
});
</script>