<template>
    <main class="public-container">
        <noscript>This page requires JavaScript to be enabled in your browser.</noscript>
        <div class="content-wrapper">
            <div class="content" v-if="!formSubmitted">
                <form class="public-form" @submit.prevent="submitFormHandler">
                    <ApplicationSection title="LGS-Homestead Rev 10-08" subtitle="Application for Homestead Exemption">
                        <b>Important Information</b>
                        <p class="mt-2">
                            To qualify, you must own and occupy the property as your primary legal residence on January
                            1 and be a United States citizen or an alien lawfully present in the United States. Georgia
                            law requires your application to be filed no later than April 1 to qualify for the current
                            tax year. It is the duty of the owner to notify the Tax Assessor’s Office in the event the
                            applicant becomes ineligible for an exemption. The homestead exemption is not applicable to
                            rental property or vacant land.
                        </p>
                    </ApplicationSection>
                    <ApplicationSection title="APPLICANT INFORMATION">
                        <div class="element-container">
                            <label for="other-property-homestead-exemption-address" class="mt-2 form-label">
                                List below the address of any other property where you or your spouse have applied for
                                and been granted a homestead exemption for the current year:
                            </label>
                            <InputText id="other-property-homestead-exemption-address"
                                v-model="formFields.other_property_homestead_exemption_address" />
                        </div>
                        <div class="element-container">
                            <span class="form-label-required">
                                Are you and your spouse a Georgia resident, US citizen or non-citizen with legal
                                authorization from the US Immigration and Naturalization Service?
                            </span>
                            <fieldset>
                                <input v-model="formFields.georgia_resident_and_immigration_status" type="radio"
                                    class="custom-radio" name="georgia-resident-and-immigration-status"
                                    id="georgia-resident-and-immigration-status-1" :value="true" required
                                    @invalid.capture.prevent="elementRequiredHandler">
                                <label for="georgia-resident-and-immigration-status-1">Yes</label>
                                <input v-model="formFields.georgia_resident_and_immigration_status" type="radio"
                                    class="custom-radio" name="georgia-resident-and-immigration-status"
                                    id="georgia-resident-and-immigration-status-0" :value="false" required>
                                <label for="georgia-resident-and-immigration-status-0">No</label>
                            </fieldset>
                        </div>
                        <div class="element-container">
                            <label for="legal-alien-registration-number" class="form-label">
                                If you are a non-citizen with legal authorization from the US Immigration and
                                Naturalization Service, please provide your Legal Alien Registration #
                            </label>
                            <InputText id="legal-alien-registration-number"
                                v-model="formFields.legal_alien_registration_number" />
                        </div>
                        <div class="my-2">
                            <span class="form-label form-label-required">
                                Do you have a spouse/additional owner that will be on this application?
                            </span>
                            <fieldset>
                                <input v-model="formFields.has_spouse_or_additional_owner" type="radio"
                                    class="custom-radio" name="has-spouse-or-additional-owner"
                                    id="has-spouse-or-additional-owner-1" :value="true" required
                                    @invalid.capture.prevent="elementRequiredHandler">
                                <label for="has-spouse-or-additional-owner-1">Yes</label>
                                <input v-model="formFields.has_spouse_or_additional_owner" type="radio"
                                    class="custom-radio" name="has-spouse-or-additional-owner"
                                    id="has-spouse-or-additional-owner-0" :value="false" required>
                                <label for="has-spouse-or-additional-owner-0">No</label>
                            </fieldset>
                        </div>
                        <div class="my-3">
                            <h3 class="subsection-header">Applicant</h3>

                            <ApplicationInfoFields :instanceKey="instanceKey" :owner-number="1"
                                :accept-file-types="ACCEPT_FILE_TYPES" :max-date-string="todayISOString"
                                :file-input-change-handler="fileInputChangeHandler"
                                :require-drivers-license-default="true" :require-birthdate="true"
                                :element-required-handler="elementRequiredHandler" :customerID="form.customer_id"
                                :api_key="form.api_key" v-model:name="formFields.property_owner_name_1"
                                v-model:birthdate="formFields.property_owner_birthdate_1"
                                v-model:email="formFields.property_owner_email_1"
                                v-model:license-number="formFields.property_owner_id_number_1"
                                v-model:phone="formFields.property_owner_phone_1"
                                v-model:address-line="formFields.property_owner_address_line_1"
                                v-model:unit-number="formFields.property_owner_unit_number_1"
                                v-model:city="formFields.property_owner_city_1"
                                v-model:state="formFields.property_owner_state_1"
                                v-model:zip="formFields.property_owner_postal_code_1"
                                v-model:ssn="formFields.property_owner_ssn_1"
                                v-model:property-address-line1="formFields.property_address_line_1"
                                v-model:property-city="formFields.property_city"
                                v-model:property-state="formFields.property_state"
                                v-model:property-postal-code="formFields.property_postal_code" />

                            <div class="element-container">
                                <label for="property-owner-voter-registration-county-1"
                                    class="me-2 form-label form-label-required">
                                    County where you are registered to vote:
                                </label>
                                <InputText id="property-owner-voter-registration-county-1"
                                    v-model="formFields.property_owner_voter_registration_county_1" required
                                    @invalid.capture.prevent="elementRequiredHandler" />
                            </div>
                            <div class="element-container">
                                <label for="property-owner-car-registration-county-1"
                                    class="me-2 form-label form-label-required">
                                    County where car is registered:
                                </label>
                                <InputText id="property-owner-car-registration-county-1"
                                    v-model="formFields.property_owner_car_registration_county_1" required
                                    @invalid.capture.prevent="elementRequiredHandler" />
                            </div>
                            <div class="element-container">
                                <label for="owner-military-service-record" class="me-2 form-label">
                                    If you and/or your spouse are in the military service, list the state shown as
                                    your home of record:
                                </label>
                                <InputText id="owner-military-service-record"
                                    v-model="formFields.military_service_home_of_record" />
                            </div>
                            <div class="element-container">
                                <span class="me-2 form-label form-label-required">
                                    Are you or your spouse/additional owner receiving a homestead exemption at any
                                    other residence including out of County/State?
                                </span>

                                <fieldset>
                                    <input v-model="formFields.receiving_homestead_exemption_elsewhere" type="radio"
                                        class="custom-radio" name="receiving-homestead-exemption-elsewhere"
                                        id="receiving-homestead-exemption-elsewhere-1" :value="true" required
                                        @invalid.capture.prevent="elementRequiredHandler">
                                    <label for="receiving-homestead-exemption-elsewhere-1">Yes</label>
                                    <input v-model="formFields.receiving_homestead_exemption_elsewhere" type="radio"
                                        class="custom-radio" name="receiving-homestead-exemption-elsewhere"
                                        id="receiving-homestead-exemption-elsewhere-0" :value="false" required>
                                    <label for="receiving-homestead-exemption-elsewhere-0">No</label>
                                </fieldset>

                                <div class="element-container"
                                    v-if="formFields.receiving_homestead_exemption_elsewhere">
                                    <label for="location-of-homestead-exemption"
                                        class="me-2 form-label form-label-required">
                                        Where are you currently receiving homestead exemption?:
                                    </label>
                                    <InputText id="location-of-homestead-exemption"
                                        v-model="formFields.location_of_homestead_exemption"
                                        :required="formFields.receiving_homestead_exemption_elsewhere" />
                                </div>
                            </div>
                        </div>

                        <div id="property-owner-2-container" v-if="showOwner2">
                            <h3 class="subsection-header">Spouse</h3>

                            <ApplicationInfoFields :instanceKey="instanceKey" :owner-number="2"
                                :accept-file-types="ACCEPT_FILE_TYPES" :max-date-string="todayISOString"
                                :file-input-change-handler="fileInputChangeHandler"
                                :require-drivers-license-default="true" :require-birthdate="true"
                                :element-required-handler="elementRequiredHandler" :customerID="form.customer_id"
                                :api_key="form.api_key" v-model:name="formFields.property_owner_name_2"
                                v-model:birthdate="formFields.property_owner_birthdate_2"
                                v-model:email="formFields.property_owner_email_2"
                                v-model:license-number="formFields.property_owner_id_number_2"
                                v-model:phone="formFields.property_owner_phone_2"
                                v-model:address-line="formFields.property_owner_address_line_2"
                                v-model:unit-number="formFields.property_owner_unit_number_2"
                                v-model:city="formFields.property_owner_city_2"
                                v-model:state="formFields.property_owner_state_2"
                                v-model:zip="formFields.property_owner_postal_code_2"
                                v-model:ssn="formFields.property_owner_ssn_2"
                                v-model:property-address-line1="formFields.property_address_line_1"
                                v-model:property-city="formFields.property_city"
                                v-model:property-state="formFields.property_state"
                                v-model:property-postal-code="formFields.property_postal_code" />
                        </div>

                        <div class="my-2">
                            <div class="element-container">
                                <div class="my-3">
                                    <span class="me-2 form-label form-label-required">
                                        Were you or your spouse/additional owner age 70 or older as of Jan 1
                                        of the year of this application?
                                    </span>
                                    <fieldset>
                                        <input v-model="formFields.age_70_or_older_as_of_jan_1" type="radio"
                                            class="custom-radio" name="age-70-or-older-as-of-jan-1"
                                            id="age-70-or-older-as-of-jan-1-yes" :value="true" required
                                            @invalid.capture.prevent="elementRequiredHandler">
                                        <label for="age-70-or-older-as-of-jan-1-yes">Yes</label>
                                        <input v-model="formFields.age_70_or_older_as_of_jan_1" type="radio"
                                            class="custom-radio" name="age-70-or-older-as-of-jan-1"
                                            id="age-70-or-older-as-of-jan-1-no" :value="false" required>
                                        <label for="age-70-or-older-as-of-jan-1-no">No</label>
                                    </fieldset>
                                </div>

                                <div class="my-3" v-if="formFields.age_70_or_older_as_of_jan_1 === false">
                                    <span class="me-2 form-label form-label-required">
                                        Were you or your spouse/additional owner age 65 or older as of Jan 1
                                        of the year of this application and combined household income does
                                        not exceed $80,280 and net earned income from interest, rentals, or
                                        work does not exceed $10,000?
                                    </span>
                                    <fieldset>
                                        <input v-model="formFields.age_65_or_older_and_income_limit_10280" type="radio"
                                            class="custom-radio" name="age-65-or-older-and-income-limit-10280"
                                            id="age-65-or-older-and-income-limit-10280-yes" :value="true" required
                                            @invalid.capture.prevent="elementRequiredHandler">
                                        <label for="age-65-or-older-and-income-limit-10280-yes">Yes</label>
                                        <input v-model="formFields.age_65_or_older_and_income_limit_10280" type="radio"
                                            class="custom-radio" name="age-65-or-older-and-income-limit-10280"
                                            id="age-65-or-older-and-income-limit-10280-no" :value="false" required>
                                        <label for="age-65-or-older-and-income-limit-10280-no">No</label>
                                    </fieldset>

                                    <div class="my-3 p-3" v-if="formFields.age_65_or_older_and_income_limit_10280">
                                        <label for="age-65-or-older-and-income-limit-10280-file"
                                            class="form-label-required">
                                            Upload Proof of Income
                                        </label>
                                        <br>
                                        <input type="file" id="age-65-or-older-and-income-limit-10280-file"
                                            :accept="ACCEPT_FILE_TYPES" @change="uploadFiles"
                                            @invalid.capture.prevent="elementRequiredHandler"
                                            :required="formFields.age_65_or_older_and_income_limit_10280"
                                            :disabled="!isFormLoaded" multiple>
                                        <ApplicationFileUploadStatus
                                            containerID="age-65-or-older-and-income-limit-10280-file" />
                                    </div>
                                </div>

                                <div class="my-3" v-if="formFields.age_65_or_older_and_income_limit_10280 === false">
                                    <span class="me-2 form-label form-label-required">
                                        Were you or your spouse/additional owner age 62 or older as of Jan 1
                                        of the year of this application and combined household income does
                                        not exceed $80,280 and net earned income from interest, rentals, or
                                        work does not exceed $25,000 for the previous calendar year?
                                    </span>

                                    <fieldset>
                                        <input v-model="formFields.age_62_or_older_and_income_limit_25000" type="radio"
                                            class="custom-radio" name="age-62-or-older-and-income-limit-25000"
                                            id="age-62-or-older-and-income-limit-25000-yes" :value="true" required
                                            @invalid.capture.prevent="elementRequiredHandler">
                                        <label for="age-62-or-older-and-income-limit-25000-yes">Yes</label>
                                        <input v-model="formFields.age_62_or_older_and_income_limit_25000" type="radio"
                                            class="custom-radio" name="age-62-or-older-and-income-limit-25000"
                                            id="age-62-or-older-and-income-limit-25000-no" :value="false" required>
                                        <label for="age-62-or-older-and-income-limit-25000-no">No</label>
                                    </fieldset>

                                    <div class="my-3 p-3" v-if="formFields.age_62_or_older_and_income_limit_25000">
                                        <label for="age-62-or-older-and-income-limit-25000-file"
                                            class="form-label-required">
                                            Upload Proof of Income
                                        </label>
                                        <br>
                                        <input type="file" id="age-62-or-older-and-income-limit-25000-file"
                                            :accept="ACCEPT_FILE_TYPES" @change="uploadFiles"
                                            @invalid.capture.prevent="elementRequiredHandler"
                                            :required="formFields.age_62_or_older_and_income_limit_25000"
                                            :disabled="!isFormLoaded">
                                        <ApplicationFileUploadStatus
                                            containerID="age-62-or-older-and-income-limit-25000-file" />
                                    </div>
                                </div>

                                <div class="my-3">
                                    <span class="me-2 form-label form-label-required">
                                        Is the applicant or spouse/additional owner a 100% disabled veteran
                                        or is the applicant the unremarried surviving spouse of a 100%
                                        disabled veteran?
                                    </span>
                                    <fieldset>
                                        <input v-model="formFields.is_100_percent_disabled_veteran_or_surviving_spouse"
                                            type="radio" class="custom-radio"
                                            name="is-100-percent-disabled-veteran-or-surviving-spouse"
                                            id="is-100-percent-disabled-veteran-or-surviving-spouse-yes" :value="true"
                                            required @invalid.capture.prevent="elementRequiredHandler">
                                        <label for="is-100-percent-disabled-veteran-or-surviving-spouse-yes">Yes</label>
                                        <input v-model="formFields.is_100_percent_disabled_veteran_or_surviving_spouse"
                                            type="radio" class="custom-radio"
                                            name="is-100-percent-disabled-veteran-or-surviving-spouse"
                                            id="is-100-percent-disabled-veteran-or-surviving-spouse-no" :value="false"
                                            required>
                                        <label for="is-100-percent-disabled-veteran-or-surviving-spouse-no">No</label>
                                    </fieldset>
                                    <div class="my-3 p-3"
                                        v-if="formFields.is_100_percent_disabled_veteran_or_surviving_spouse">
                                        <label for="is-100-percent-disabled-veteran-or-surviving-spouse-file"
                                            class="form-label-required">
                                            Upload letter from Veterans Administration
                                        </label>
                                        <br>
                                        <input type="file" id="is-100-percent-disabled-veteran-or-surviving-spouse-file"
                                            :accept="ACCEPT_FILE_TYPES" @change="uploadFiles"
                                            @invalid.capture.prevent="elementRequiredHandler"
                                            :required="formFields.is_100_percent_disabled_veteran_or_surviving_spouse"
                                            :disabled="!isFormLoaded">
                                        <ApplicationFileUploadStatus
                                            containerID="is-100-percent-disabled-veteran-or-surviving-spouse-file" />
                                    </div>
                                </div>

                                <div class="my-3">
                                    <span class="me-2 form-label form-label-required">
                                        Are you the unremarried surviving spouse/additional owner of a US
                                        service member killed in action?
                                    </span>
                                    <fieldset>
                                        <input v-model="formFields.is_surviving_spouse_of_service_member" type="radio"
                                            class="custom-radio" name="is-surviving-spouse-of-service-member"
                                            id="is-surviving-spouse-of-service-member-yes" :value="true" required
                                            @invalid.capture.prevent="elementRequiredHandler">
                                        <label for="is-surviving-spouse-of-service-member-yes">Yes</label>
                                        <input v-model="formFields.is_surviving_spouse_of_service_member" type="radio"
                                            class="custom-radio" name="is-surviving-spouse-of-service-member"
                                            id="is-surviving-spouse-of-service-member-no" :value="false" required>
                                        <label for="is-surviving-spouse-of-service-member-no">No</label>
                                    </fieldset>
                                </div>
                                <div class="my-3">
                                    <span class="me-2 form-label form-label-required">
                                        Are you the unremarried surviving spouse/additional owner of a
                                        firefighter or peace officer killed in the line of duty
                                    </span>
                                    <fieldset>
                                        <input v-model="formFields.is_surviving_spouse_of_first_responder" type="radio"
                                            class="custom-radio" name="is-surviving-spouse-of-first-responder"
                                            id="is-surviving-spouse-of-first-responder-yes" :value="true" required
                                            @invalid.capture.prevent="elementRequiredHandler">
                                        <label for="is-surviving-spouse-of-first-responder-yes">Yes</label>
                                        <input v-model="formFields.is_surviving_spouse_of_first_responder" type="radio"
                                            class="custom-radio" name="is-surviving-spouse-of-first-responder"
                                            id="is-surviving-spouse-of-first-responder-no" :value="false" required>
                                        <label for="is-surviving-spouse-of-first-responder-no">No</label>
                                    </fieldset>
                                </div>

                                <div class="my-3">
                                    <span class="me-2 form-label form-label-required">
                                        Were you or your spouse/additional owner disabled as of Jan. 1 of
                                        the year of this application and Household income from prior year
                                        was below $25,000?
                                    </span>

                                    <fieldset>
                                        <input v-model="formFields.disability_and_income_below_25000_as_of_jan_1"
                                            type="radio" class="custom-radio"
                                            name="disability-and-income-below-25000-as-of-jan-1"
                                            id="disability-and-income-below-25000-as-of-jan-1-yes" :value="true"
                                            required @invalid.capture.prevent="elementRequiredHandler">
                                        <label for="disability-and-income-below-25000-as-of-jan-1-yes">Yes</label>
                                        <input v-model="formFields.disability_and_income_below_25000_as_of_jan_1"
                                            type="radio" class="custom-radio"
                                            name="disability-and-income-below-25000-as-of-jan-1"
                                            id="disability-and-income-below-25000-as-of-jan-1-no" :value="false"
                                            required>
                                        <label for="disability-and-income-below-25000-as-of-jan-1-no">No</label>
                                    </fieldset>

                                    <div v-if="formFields.disability_and_income_below_25000_as_of_jan_1">
                                        <fieldset>
                                            <div class="mt-3 p-3">
                                                <span class="form-label-required">
                                                    Upload Certificate from licensed physician stating that you are
                                                    mentally or physically disabled to seek gainful employment and
                                                    such capacity is likely permanent. </span>
                                                <br>
                                                <input type="file"
                                                    id="disability-and-income-below-25000-as-of-jan-1-file"
                                                    :accept="ACCEPT_FILE_TYPES" @change="uploadFiles"
                                                    @invalid.capture.prevent="elementRequiredHandler"
                                                    :required="formFields.disability_and_income_below_25000_as_of_jan_1"
                                                    :disabled="!isFormLoaded">
                                                <ApplicationFileUploadStatus
                                                    containerID="disability-and-income-below-25000-as-of-jan-1-file" />
                                            </div>
                                        </fieldset>
                                        <fieldset class="p-3">
                                                <span class="form-label-required">
                                                    Upload Proof of Income
                                                </span>
                                                <br>
                                                <input type="file"
                                                    id="disability-and-income-below-25000-as-of-jan-1-file-2"
                                                    :accept="ACCEPT_FILE_TYPES" @change="uploadFiles"
                                                    @invalid.capture.prevent="elementRequiredHandler"
                                                    :required="formFields.disability_and_income_below_25000_as_of_jan_1"
                                                    :disabled="!isFormLoaded">
                                                <ApplicationFileUploadStatus
                                                    containerID="disability-and-income-below-25000-as-of-jan-1-file-2" />
                                        </fieldset>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </ApplicationSection>
                    <ApplicationSection title="PROPERTY INFORMATION">
                        <div id="section-b" class="my-3">
                            <div class="element-container">
                                <label for="parcel-number" class="me-2 form-label form-label-required">
                                    Map/Parcel Number:
                                </label>
                                <InputText id="parcel-number" v-model="formFields.parcel_number"
                                    :disabled="isADAccountNumDisabled" @invalid.capture.prevent="elementRequiredHandler"
                                    required />
                            </div>

                            <b>Location of Property</b>

                            <div class="element-container mt-2">
                                <label for="owner-name" class="me-2 form-label form-label-required">
                                    Street Address:
                                </label>
                                <InputText id="owner-name" v-model="formFields.property_address_line_1"
                                    @invalid.capture.prevent="elementRequiredHandler" required />
                            </div>
                            <div class="element-container">
                                <label for="owner2-unit-number" class="form-label">
                                    Unit Number:
                                </label>
                                <InputText id="owner2-unit-number" v-model="formFields.property_unit_number" />
                            </div>
                            <div class="element-container">
                                <label for="owner2-city" class="form-label form-label-required">
                                    City:
                                </label>
                                <InputText id="owner2-city" v-model="formFields.property_city"
                                    @invalid.capture.prevent="elementRequiredHandler" required />
                            </div>
                            <div class="element-container">
                                <label for="owner2-state" class="form-label form-label-required">
                                    State:
                                </label>
                                <InputText id="owner2-state" v-model="formFields.property_state"
                                    @invalid.capture.prevent="elementRequiredHandler" required />
                            </div>
                            <div class="element-container">
                                <label for="owner2-postal-code" class="form-label form-label-required">
                                    Zip:
                                </label>
                                <InputText id="owner2-postal-code" v-model="formFields.property_postal_code"
                                    @invalid.capture.prevent="elementRequiredHandler" required />
                            </div>

                            <div class="element-container">
                                <span class="form-label form-label-required">
                                    Is this your Primary residence?
                                </span>
                                <div class="my-2">
                                    <fieldset>
                                        <input v-model="formFields.is_primary_residence" type="radio"
                                            class="custom-radio" name="is-primary-residence" id="is-primary-residence-1"
                                            :value="true" required @invalid.capture.prevent="elementRequiredHandler">
                                        <label for="is-primary-residence-1" class="me-2">Yes</label>
                                        <input v-model="formFields.is_primary_residence" type="radio"
                                            class="custom-radio" name="is-primary-residence" id="is-primary-residence-0"
                                            :value="false" required>
                                        <label for="is-primary-residence-0">No</label>
                                    </fieldset>
                                </div>
                            </div>
                            <div v-if="formFields.is_primary_residence === false">
                                <div class="element-container">
                                    <label for="owner-name" class="me-2 form-label form-label-required">
                                        Street Address:
                                    </label>
                                    <InputText id="owner-name" v-model="formFields.property_address_line_1"
                                        :required="formFields.is_primary_residence === false" maxlength="75"
                                        spellcheck="false" @invalid.capture.prevent="elementRequiredHandler" />
                                </div>
                                <div class="element-container">

                                    <label for="owner2-unit-number" class="form-label form-label-required">Unit
                                        Number:</label>
                                    <InputText id="owner2-unit-number" v-model="formFields.property_unit_number"
                                        :required="formFields.is_primary_residence === false" maxlength="75"
                                        spellcheck="false" @invalid.capture.prevent="elementRequiredHandler" />
                                </div>
                                <div class="element-container">

                                    <label for="owner2-city" class="form-label form-label-required">City:</label>
                                    <InputText id="owner2-city" v-model="formFields.property_city"
                                        :required="formFields.is_primary_residence === false" maxlength="75"
                                        spellcheck="false" @invalid.capture.prevent="elementRequiredHandler" />
                                </div>
                                <div class="element-container">

                                    <label for="owner2-state" class="form-label form-label-required">State:</label>
                                    <InputText id="owner2-state" v-model="formFields.property_state"
                                        :required="formFields.is_primary_residence === false" maxlength="75"
                                        spellcheck="false" @invalid.capture.prevent="elementRequiredHandler" />
                                </div>
                                <div class="element-container">
                                    <label for="owner2-postal-code" class="form-label form-label-required">Zip:</label>
                                    <InputText id="owner2-postal-code" v-model="formFields.property_postal_code"
                                        :required="formFields.is_primary_residence === false" maxlength="75"
                                        spellcheck="false" @invalid.capture.prevent="elementRequiredHandler" />
                                </div>
                            </div>


                            <div class="element-container">
                                <label for="property-date-purchased" class="me-2 form-label">
                                    Date Property Purchased:
                                </label>
                                <DateComboBox v-model="formFields.property_date_purchased"
                                    inputId="property-date-purchased" />
                            </div>

                            <!-- <div class="element-container">
                                <label for="property-from-whom-purchased" class="form-label form-label-required">
                                    From Whom Purchased:
                                </label>
                                <InputText id="property-from-whom-purchased"
                                    v-model="formFields.property_from_whom_purchased"
                                    @invalid.capture.prevent="elementRequiredHandler" required />
                            </div>
                            <div class="element-container">
                                <label for="property-purchase-price" class="form-label form-label-required">
                                    Purchase Price:
                                </label>
                                <InputNumber fluid inputId="property-purchase-price"
                                    v-model="formFields.property_purchase_price"
                                    @invalid.capture.prevent="elementRequiredHandler" required />
                            </div>
                            <div class="element-container">
                                <label for="property-amount-lien" class="form-label form-label-required">
                                    Amount of Lien:
                                </label>
                                <InputNumber fluid inputId="property-amount-lien"
                                    v-model="formFields.property_amount_lien"
                                    @invalid.capture.prevent="elementRequiredHandler" required />
                            </div>
                            <div class="element-container">
                                <label for="kind-of-title-held" class="form-label form-label-required">
                                    Kind of Title Held:
                                </label>
                                <InputText id="kind-of-title-held" v-model="formFields.property_kind_of_title_held"
                                    @invalid.capture.prevent="elementRequiredHandler" required />
                            </div>
                            <div class="element-container">
                                <label for="lien-due-to" class="form-label form-label-required">
                                    To Whom is Lien Due:
                                </label>
                                <InputText id="lien-due-to" v-model="formFields.property_lien_due_to"
                                    @invalid.capture.prevent="elementRequiredHandler" required />
                            </div>
                            <div class="element-container">
                                <label for="lot-size-or-acres" class="form-label form-label-required">
                                    Lot Size or Number of Acres:
                                </label>
                                <InputText id="lot-size-or-acres" v-model="formFields.property_lot_size_or_acres"
                                    @invalid.capture.prevent="elementRequiredHandler" required />
                            </div>
                            <div class="element-container">
                                <label for="land-lot-numbers" class="form-label form-label-required">
                                    Land Lot Numbers:
                                </label>
                                <InputText id="land-lot-numbers" v-model="formFields.property_land_lot_numbers"
                                    @invalid.capture.prevent="elementRequiredHandler" required />
                            </div>
                            <div class="element-container">
                                <label for="land-district-number" class="form-label form-label-required">
                                    Land District Number:
                                </label>
                                <InputText id="land-district-number" v-model="formFields.property_land_district_number"
                                    @invalid.capture.prevent="elementRequiredHandler" required />
                            </div>
                            <div class="element-container">
                                <label for="property-book" class="form-label form-label-required">
                                    Deed Recorded: Book:
                                </label>
                                <InputText id="property-book" v-model="formFields.property_book"
                                    @invalid.capture.prevent="elementRequiredHandler" required />
                            </div>
                            <div class="element-container">
                                <label for="property-page" class="form-label form-label-required">
                                    Page:
                                </label>
                                <InputText id="property-page" v-model="formFields.property_page"
                                    @invalid.capture.prevent="elementRequiredHandler" required />
                            </div> -->
                            <div class="element-container">
                                <span class="form-label form-label-required">
                                    Is any part of the property rented?
                                </span>
                                <div class="my-2">
                                    <fieldset>
                                        <input v-model="formFields.is_property_rented" type="radio" class="custom-radio"
                                            name="is-property-rented" id="is-property-rented-1" :value="true" required
                                            @invalid.capture.prevent="elementRequiredHandler">
                                        <label for="is-property-rented-1">Yes</label>
                                        <input v-model="formFields.is_property_rented" type="radio" class="custom-radio"
                                            name="is-property-rented" id="is-property-rented-0" :value="false" required>
                                        <label for="is-property-rented-0">No</label>
                                    </fieldset>
                                </div>
                            </div>
                            <div class="element-container p-2" v-if="formFields.is_property_rented">
                                <label for="property-part-rented" class="form-label">If yes, what part is
                                    rented?</label>
                                <InputText id="property-part-rented" v-model="formFields.property_part_rented" />
                            </div>

                            <div class="element-container">
                                <span class="form-label form-label-required">
                                    Is any part of the property used for business purposes?
                                </span>
                                <div class="my-2">
                                    <fieldset>
                                        <input v-model="formFields.is_property_used_for_business" type="radio"
                                            class="custom-radio" name="is-property-used-for-business"
                                            id="is-property-used-for-business-1" :value="true" required
                                            @invalid.capture.prevent="elementRequiredHandler">
                                        <label for="is-property-used-for-business-1">Yes</label>
                                        <input v-model="formFields.is_property_used_for_business" type="radio"
                                            class="custom-radio" name="is-property-used-for-business"
                                            id="is-property-used-for-business-0" :value="false" required>
                                        <label for="is-property-used-for-business-0">No</label>
                                    </fieldset>
                                </div>
                            </div>
                            <div class="element-container p-2" v-if="formFields.is_property_used_for_business">
                                <span class="form-label">
                                    What kind of business & how much of the property is used?
                                </span>
                                <InputText id="lot-size-or-acres"
                                    v-model="formFields.property_business_use_description" />
                            </div>
                        </div>

                    </ApplicationSection>

                    <div v-if="formFields.age_70_or_older_as_of_jan_1 === false">
                        <ApplicationSection
                            title="COMPLETE THIS SECTION TO DETERMINE ELIGIBILITY FOR NET INCOME REQUIREMENT">
                            <b>SECTION C1:</b>
                            <br>
                            <br>
                            <b>
                                If filing Joint Income Tax Return, Applicant must complete Column 1A only. If filing
                                separately, both Columns 1A and 1B must be completed
                            </b>
                            <div class="my-4">
                                <div class="element-container">
                                    <b>INCOME FOR TAX YEAR ENDING DECEMBER 31,</b>
                                    <InputText id="sectionC1-income-tax-year"
                                        v-model="formFields.sectionC1_income_tax_year" type="number"
                                        style="width: 100px" @invalid.capture.prevent="elementRequiredHandler"
                                        required />
                                </div>
                            </div>
                            <div id="section-c">
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th class="remove-left-border" style="border-top: none"></th>
                                            <th>Column 1A</th>
                                            <th>Column 1B</th>
                                        </tr>
                                        <tr>
                                            <th class="remove-left-border" style="border-top: none"></th>
                                            <th>Applicant</th>
                                            <th>Spouse</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(c1, index) in formFields.sectionC1" :key="index">
                                            <td>{{ c1.description }}
                                            </td>
                                            <td>
                                                <InputNumber fluid v-model="c1.applicant1"
                                                    :inputId="'applicant-name-' + index"
                                                    :disabled="sectionC1LineFieldDisabled.includes(index + 1)" />
                                            </td>
                                            <td>
                                                <InputNumber fluid v-model="c1.applicant2"
                                                    :inputId="'applicant2-name-' + index"
                                                    :disabled="sectionC1LineFieldDisabled.includes(index + 1)" />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </ApplicationSection>

                        <ApplicationSection
                            title="COMPLETE THIS SECTION TO DETERMINE ELIGIBILITY FOR FEDERAL ADJUSTED GROSS INCOME REQUIREMENT">
                            <b>SECTION C2:</b>
                            <br>
                            <br>
                            <b>
                                For each member residing in the household, complete the social security number & federal
                                adjusted gross income in the spaces below
                            </b>
                            <div class="my-4">
                                <div class="element-container">
                                    <b>INCOME FOR TAX YEAR ENDING DECEMBER 31,</b>
                                    <InputText id="sectionC2-income-tax-year"
                                        v-model="formFields.sectionC2_income_tax_year" type="number"
                                        style="width: 100px" @invalid.capture.prevent="elementRequiredHandler"
                                        required />
                                </div>
                            </div>
                            <div id="section-c2">
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th width="25%" class="remove-left-border"></th>
                                            <th width="55%">Name of Household Member</th>
                                            <th width="20%">Federal Adjusted Gross Income</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(c2, index) in formFields.sectionC2" :key="index">
                                            <td width="10%">Line {{ index + 1 }}</td>
                                            <td>
                                                <InputText :id="'household-member-' + index" v-model="c2.name" />
                                            </td>
                                            <td>
                                                <InputNumber :inputId="'fed-adjusted-gross-income-' + index" fluid
                                                    v-model="c2.value" />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="2">
                                                ADJUSTED GROSS INCOME-TOTAL OF LINES 1 THRU 7 MUST BE LESS THAN $30,000
                                            </td>
                                            <td>
                                                <InputNumber inputId="sectionC2-gross-income-total" fluid disabled
                                                    v-model="formFields.sectionC2_gross_income_total" />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </ApplicationSection>
                    </div>

                    <ApplicationSection title="AFFIDAVIT OF APPLICANT">
                        <p class="mt-2">
                            I, the undersigned, do solemnly swear that the statements made in support of this
                            application are true and correct, that I am the bona fide owner of the property described
                            in this application, that I shall occupy or actually occupied same on Jan 1 of the year for
                            which application is made, that I am an eligible applicant for the homestead exemption
                            applied
                            for, qualifying or meeting the definition of the word "applicant" as defined in O.C.G.A. §
                            48-5-40 and that no transaction has been made in collusion with another for the purpose
                            of obtaining a homestead exemption contrary to law.
                        </p>
                        <p>
                            Select the date sworn to and subscribed before me:
                            <b class="text-decoration-underline">{{ today.format("MMMM D, YYYY") }}</b>
                            <InputText type="hidden" :value="today" />
                        </p>
                        <div class="element-container">
                            <span class="form-label form-label-required">
                                Signature of Property Owner/Applicant or Authorized Representative
                            </span>
                            <Signature v-model:signature_of_applicant="formFields.signature_of_applicant" />
                        </div>
                    </ApplicationSection>

                    <ApplicationSection title="">
                        <div class="list-item">
                            <input v-model="formFields.agree_to_terms_and_conditions" type="checkbox"
                                class="custom-checkbox" name="agree-to-terms-and-conditions-yes"
                                id="agree-to-terms-and-conditions" :value="true"
                                @invalid.capture.prevent="elementRequiredHandler">
                            <label for="agree-to-terms-and-conditions" class="form-label form-label-required">
                                I agree to terms and conditions
                            </label>
                        </div>

                        <SubmitButtonContainer v-if="istValidUUID" />

                        <Message severity="error" :closable="false" class="m-2 mb-5" v-if="istValidUUID === false">
                            <i class="fas fa-exclamation-triangle"></i>
                            {{ NOT_VALID_UUID_ERR_MESSAGE }}
                        </Message>

                    </ApplicationSection>

                </form>
            </div>
            <div class="content text-center" v-else>
                <ApplicationSection title="LGS-Homestead Rev 10-08">
                    <p>
                        Your submission was successful.
                        <br>
                        <br>
                        Application ID: <b>{{ confirmationNumber }}</b>
                    </p>
                    <p>
                        <a href="#" @click="refreshForm()">Go back</a>
                    </p>
                </ApplicationSection>
            </div>
        </div>
    </main>
</template>

<script setup lang="ts">
import ApplicationSection from "@/components/ApplicationForm/ApplicationSection.vue"
import ApplicationFileUploadStatus from "@/components/ApplicationForm/ApplicationFileUploadStatus.vue"
import { computed, onMounted, ref, watch } from "vue"
import { generateUUIDv4, hidePFGetHelpButton } from "@/helpers/common"
import { getFileExtension, getFileSizeText, isValidFileType } from "@/helpers/files"
import { useApplicationForm } from "@/stores/applicationForm"
import { type NamForm, type ApplicationDataGAC1, type ApplicationDataGAC2 } from '@/helpers/interface/applicationForm'
import type { AxiosError } from "axios"
import { getApiErrorMessage, setPageTitle } from "@/helpers/common"
import { toast } from "@/helpers/toast"
import { useRoute } from "vue-router"
import { useAPI } from "@/helpers/services/api"
import Message from 'primevue/message'
import { isValidEmail } from "@/helpers/regex"
import InputText from 'primevue/inputtext'
import InputNumber from 'primevue/inputnumber';
import Signature from "@/components/ApplicationForm/Signature.vue"
import dayjs from "dayjs"
import DateComboBox from "@/components/Shared/DateComboBox.vue"
import ApplicationInfoFields from "@/components/ApplicationForm/ga/ApplicationInfoFields.vue"
import SubmitButtonContainer from "@/components/ApplicationForm/SubmitButtonContainer.vue"

const INVALID_EMAIL_MESSAGE = "You must enter a valid email address. Example: name@example.com"
const publicAPI = useAPI({ authGuard: false })
const route = useRoute()
sessionStorage.setItem("state", "ga")
const storeApplicationForm = useApplicationForm()
const ACCEPT_FILE_TYPES = ".png, .jpg, .jpeg, .bmp, .gif, .pdf"
const today = dayjs()
const todayISOString = today.toISOString().substring(0, 10)

const formSubmitted = ref(false)
const formFields = computed(() => (storeApplicationForm.gaFields))
const uploadState = computed(() => (storeApplicationForm.uploadState))

/* this key must be included in all HTTP requests to the server as a header
    for file uploads and form submission itself
*/
const instanceKey = ref("")
instanceKey.value = generateUUIDv4()
const confirmationNumber = ref(0)
const formID = route.params.form_id as string
const form = ref({
    id: formID,
    customer_id: '',
    name: '',
    state: '',
    fips_code: '',
    api_key: '',
} as NamForm)

const isFormLoaded = computed(() => {
    return form.value.customer_id !== ''
})

const NOT_VALID_UUID_ERR_MESSAGE = "Error: The form cannot be submitted due to an invalid ID. Please report this issue to the county."
const istValidUUID = ref<Boolean | null>(null)
const loadNamForm = async (formID: string) => {
    istValidUUID.value = true
    try {
        const response = await publicAPI.get(`/applications/form/${formID}`)
        form.value = response.data
    } catch (error) {
        istValidUUID.value = false
        toast.error(NOT_VALID_UUID_ERR_MESSAGE, {
            duration: 0,
            dismissible: false,
            position: "top-right"
        });
    }
}

const sectionC1LineFieldDisabled = [3, 5, 7, 10]
watch(() => formFields.value.sectionC1, (sectionC1: ApplicationDataGAC1[] | null) => {
    if (!sectionC1) return
    // Calculate line 3
    sectionC1[2].applicant1 = Number(sectionC1[0].applicant1) + Number(sectionC1[1].applicant1)
    sectionC1[2].applicant2 = Number(sectionC1[0].applicant2) + Number(sectionC1[1].applicant2)

    // Calculate line 5
    sectionC1[4].applicant1 = Math.max(0, Number(sectionC1[2].applicant1) - Number(sectionC1[3].applicant1))
    sectionC1[4].applicant2 = Math.max(0, Number(sectionC1[2].applicant2) - Number(sectionC1[3].applicant2))

    // Calculate line 7
    sectionC1[6].applicant1 = Number(sectionC1[4].applicant1) + Number(sectionC1[5].applicant1)
    sectionC1[6].applicant2 = Number(sectionC1[4].applicant2) + Number(sectionC1[5].applicant2)

    // Calculate line 10
    sectionC1[9].applicant1 = Number(sectionC1[6].applicant1) - (Number(sectionC1[7].applicant1) + Number(sectionC1[8].applicant1))
    sectionC1[9].applicant2 = Number(sectionC1[6].applicant2) - (Number(sectionC1[7].applicant2) + Number(sectionC1[8].applicant2))
}, { deep: true })

watch(() => formFields.value.sectionC2, (sectionC2: ApplicationDataGAC2[] | null) => {
    formFields.value.sectionC2_gross_income_total = sectionC2?.reduce((sum, item) => sum + item.value, 0) as number
}, { deep: true })

const fileInputChangeHandler = (e: Event, isDriversLicense: boolean = false) => {
    const maxMegaBytes = isDriversLicense ? 10 : 20
    const MAX_FILE_SIZE_BYTES = maxMegaBytes * 1024 * 1024  // MB converted by bytes
    const input = e.target as HTMLInputElement
    if (!input.files)
        return;
    const selectedFile = input.files[0]

    if (selectedFile) {
        if (!isValidFileType(selectedFile.name, ACCEPT_FILE_TYPES.split(","))) {
            const extension = getFileExtension(selectedFile.name)
            alert(`Files of type '${extension.toUpperCase()}'' may not be uploaded.`)
            input.value = "";
            return;
        }
        if (selectedFile.size > MAX_FILE_SIZE_BYTES) {
            const fileSizeText = getFileSizeText(selectedFile.size)
            const maxSizeText = getFileSizeText(MAX_FILE_SIZE_BYTES)
            alert(`The selected file is ${fileSizeText}, which is larger than the maximum allowed (${maxSizeText}).`)
            input.value = "";
            return;
        }
        if (selectedFile.size === 0) {
            alert("The selected file contains no data and may not be uploaded.");
            input.value = "";
            return;
        }
    }
}

const elementRequiredHandler = (e: Event) => {
    const input = e.target as HTMLInputElement;
    let container: any = input.closest("fieldset");
    const min = input.getAttribute("min") || null
    const max = input.getAttribute("max") || null
    if (!container) {
        container = input.parentNode as HTMLElement;
    }
    if (!container)
        throw "Unable to process required element because it's not attached to the DOM"

    let displayText = "This field is required";
    switch (input.type) {
        case "radio":
            displayText = "Please choose an option"
            break;
        case "file":
            displayText = "You must upload a file"
            break;
        case "checkbox":
            displayText = "Please check this box to proceed"
            break;
        case "tel":
            displayText = input.getAttribute("title")?.toString() || "You must follow this format (555-123-4567)"
            break;
        case "email":
            displayText = input.getAttribute("title")?.toString() || INVALID_EMAIL_MESSAGE
            break;
        case "number":
            displayText = (min && max) ? `Value must be a number between ${min} and ${max}` : "Value must be a number"
            if ((~~input.step) > 1) {
                displayText += ` in increments of ${input.step}`
            }
            break;
    }
    displayInvalidField(container, displayText)
}

const showOwner2 = computed(() => (formFields.value.has_spouse_or_additional_owner))


const displayInvalidField = (target: HTMLElement, text: string) => {
    const INVALID = "public-field-invalid";

    target.setAttribute("data-invalid-text", text)
    target.classList.add(INVALID);
    target.scrollIntoView()

    setTimeout(() => {
        target.setAttribute("data-invalid-text", "")
        target.classList.remove(INVALID)
    }, 5000);
}

const convertBase64ToFile = (base64: string, filename: string, contentType: string) => {
    const byteCharacters = atob(base64)
    const byteNumbers = new Array(byteCharacters.length)
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers)
    return new File([byteArray], filename, { type: contentType })
}

const uploadSignature = async (base64Signature: string) => {
    return await storeApplicationForm.uploadFile(
        instanceKey.value,
        convertBase64ToFile(base64Signature, "signature.png", "image/png"),
        form.value.customer_id!,
        "application_attachments",
        "signature_of_applicant"
    )
}

const createSubmissionPayload = async () => {
    const payload = { ...formFields.value } as any

    Object.keys(payload).forEach(key => {
        // convert all dates (from PrimeVue datepicker) to ISO strings
        if (payload[key] instanceof Date) {
            if (isNaN(+payload[key])) {
                payload[key] = null
            } else {
                payload[key] = payload[key].toISOString().substring(0, 10)
            }
        }
        if (payload[key] === "") {
            payload[key] = null;
        }
    })

    const idSeed = instanceKey.value.slice(0, 4)

    payload['tru_id'] = instanceKey.value
    payload['application_id'] = parseInt(idSeed, 16).toString()
    payload['form_id'] = form.value.id
    // payload['effective_date'] = getEffectiveDate()
    delete payload['start_year_of_exemption']


    const sigUpl = await uploadSignature(payload['signature_of_applicant'])
    payload['signature_of_applicant'] = sigUpl?.id

    return payload
}

const submitFormHandler = async () => {
    if (formFields.value.signature_of_applicant.length === 0) {
        const target = document.getElementById("signature-container") as HTMLElement
        displayInvalidField(target, "Your signature is required")
        return;
    }
    if (!isValidEmail(formFields.value.property_owner_email_1)) {
        const input = document.getElementById("property-owner-email-1") as HTMLElement
        let container: any = input.closest("fieldset");
        if (!container) container = input.parentNode as HTMLElement;
        if (container) {
            displayInvalidField(container, INVALID_EMAIL_MESSAGE)
            return;
        }
    }
    if (showOwner2.value && formFields.value.property_owner_email_2 && !isValidEmail(formFields.value.property_owner_email_2)) {
        const input = document.getElementById("property-owner-email-2") as HTMLElement
        let container: any = input.closest("fieldset");
        if (!container) container = input.parentNode as HTMLElement;
        if (container) {
            displayInvalidField(container, INVALID_EMAIL_MESSAGE)
            return;
        }
    }

    if (uploadState.value > 0) {
        toast.info("Hold tight, we're processing your upload.")
        return;
    }

    storeApplicationForm.getOrSetFormLoading(true)
    const payload = await createSubmissionPayload()
    try {
        const resp = await publicAPI.post(
            `/applications/${form.value.fips_code}`,
            payload,
            {
                "headers": {
                    "Content-Type": "application/json",
                    "X-Api-Key": form.value.api_key,
                }
            }
        )
        if (resp.data && resp.data.application_id) {
            confirmationNumber.value = resp.data.application_id as number
        }
        formSubmitted.value = true
        AppState.clear()
    } catch (error) {
        toast.error(getApiErrorMessage(error as AxiosError))
    }
    storeApplicationForm.getOrSetFormLoading(false)
}

const uploadFiles = async (e: Event) => {
    if (!isFormLoaded.value) {
        await loadNamForm(formID)
    }
    if (!isFormLoaded.value) {
        throw new Error("Unauthorized form")
    }
    fileInputChangeHandler(e)
    const input = e.target as HTMLInputElement
    if (!input.files) return;
    const file = input.files && input.files[0]
    const id = input.getAttribute("id")?.toString() || ""
    await storeApplicationForm.uploadFile(
        instanceKey.value,
        file,
        form.value.customer_id!,
        "application_attachments",
        id,
        input
    )
}

const refreshForm = () => window.location.reload()

const AppState = {
    KEY: storeApplicationForm.GA_STATE_STORAGE_KEY,

    clear: () => {
        try {
            sessionStorage.removeItem(AppState.KEY)
        } catch (ex) {
            console.warn("Unable to clear form state from storage")
        }
    },

    save: () => {
        try {
            if (isFormLoaded.value) {
                sessionStorage.setItem(AppState.KEY, JSON.stringify(formFields.value));
            }
        } catch (ex) {
            console.warn("Unable to utilize storage to persist form state.")
        }
    },

    events: {
        windowBeforeUnload: () => {
            if (formSubmitted.value) {
                return;
            }
            return "Are you sure you want to leave this page? Your progress may be lost."
        }
    }

}
watch(formFields.value, AppState.save, { deep: true });
const isADAccountNumDisabled = computed(() => !!route.query.account_num)

onMounted(async () => {
    setPageTitle("Application for Homestead Exemption")
    formFields.value.parcel_number = (route.query.account_num as string) || ""
    await loadNamForm(formID)
    window.onbeforeunload = AppState.events.windowBeforeUnload
    hidePFGetHelpButton()
})


</script>


<style>
@import "@/assets/public/public-base.css";
</style>
