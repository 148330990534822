<template>
  <div class="container">
    <div>
      <button type="button" class="btn btn-outline-primary btn-sm" @click="$router.push({name: 'onboardingConfigs'})">Data Config List</button>
      <button type="button" class="btn btn-outline-primary btn-sm" @click="toggleNotification()">Open Config Assignment GUI</button>
      <input type="checkbox" class="mx-1" id="listed" :disabled="state.customersLoading"
        v-model="state.listed">
      <label for="listed" class="form-label">Listed Customers</label>
      <input type="checkbox" class="mx-1" id="exclude_qa" :disabled="state.customersLoading"
        v-model="state.exclude_qa">
      <label for="exclude_qa" class="form-label">Exclude QA Schemas</label>
    </div>
    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col">Customer</th>
          <th scope="col">Customer ID</th>
          <th scope="col">Config</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="state.customersLoading || state.configsLoading">
          <td colspan="3" style="text-align: center">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </td>
        </tr>
        <tr v-else v-for="customer in state.customerConfigs" :key="customer.customer_id">
          <td>{{ customer.customer_name }}</td>
          <td>{{ customer.customer_id }}</td>
          <td>
            <div v-if="customer.configs.taxroll_config">
              <router-link :to="{name: 'onboardingConfig', params: { id: customer.configs.taxroll_config }}">Taxroll Config: {{ getConfigName(customer.configs.taxroll_config) }}</router-link>
            </div>
            <div v-if="!customer.configs.taxroll_config" @click="toggleNotification(customer.customer_id, 'taxroll_config')" role="button" style="color: #26639d;cursor:pointer">No Taxroll Config Assigned</div>
            <div v-if="customer.configs.digital_config">
              <router-link :to="{name: 'onboardingConfig', params: { id: customer.configs.digital_config }}">Digital Config: {{ getConfigName(customer.configs.digital_config) }}</router-link>
            </div>
            <div v-if="!customer.configs.digital_config" @click="toggleNotification(customer.customer_id, 'digital_config')" role="button" style="color: #26639d;cursor:pointer">No Digital Config Assigned</div>
            <div v-if="customer.configs.ocr_config">
              <router-link :to="{name: 'onboardingConfig', params: { id: customer.configs.ocr_config }}">OCR Config: {{ getConfigName(customer.configs.ocr_config) }}</router-link>
            </div>
            <div v-if="!customer.configs.ocr_config" @click="toggleNotification(customer.customer_id, 'ocr_config')" role="button" style="color: #26639d;cursor:pointer">No OCR Config Assigned</div>

          </td>
        </tr>
        <tr>
          <td colspan="3">
            <button type="button" class="btn btn-outline-primary btn-sm" @click="$router.push({name: 'onboardingConfig', params: { id: 'new' }})">New Config</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <teleport to="body">
		<div class="modal-overlay" v-if="notificationVisibility.showNotification" @click="toggleNotification('', '')"></div>
		<OnboardingConfigPopup @closePopup="toggleNotification" class="notification" :customer_id="state.customer" :config_type="state.config_type"
			v-if="notificationVisibility.showNotification" />
	</teleport>
</template>

<script setup lang="ts">
import { useAPI } from "@/helpers/services/api"
import {reactive, onMounted, watch, ref, onBeforeUnmount} from "vue";
import type {
  Config,
  CustomerConfigList
} from "@/helpers/interface/onboarding";
import { hidePFGetHelpButton } from "@/helpers/common";
import OnboardingConfigPopup from "@/components/Admin/Onboarding/Onboarding-configPopup.vue";

const state = reactive({
  customerConfigs: [] as Array<CustomerConfigList>,
  configs: [] as Array<Config>,
  customersLoading: false,
  configsLoading: false,
  customer: '',
  config_type: '',
  listed: true,
  exclude_qa: true,
})

const api = useAPI()

const notificationVisibility = ref({ showNotification: false })
const toggleBodyScrolling = (allowScrolling: boolean) => document.body.style.overflow = allowScrolling ? 'auto' : 'hidden';
const toggleNotification = (customer: string = '', config_type: string = '') => {
  state.customer = customer;
  state.config_type = config_type;
	notificationVisibility.value.showNotification = !notificationVisibility.value.showNotification;
	toggleBodyScrolling(!notificationVisibility.value.showNotification);
}
onBeforeUnmount(() => toggleBodyScrolling(true))

const getCustomerConfigs = async () => {
  state.customersLoading = true;
  const response = await api.get(`onboarding/ingestions/configs`, {params: {listed: state.listed, exclude_qa: state.exclude_qa}});
  state.customerConfigs = response.data;
  state.customersLoading = false;
}

const getConfigs = async () => {
  state.configsLoading = true;
  const response = await api.get(`onboarding/configs/`);
  state.configs = response.data;
  state.configsLoading = false;
}

const getConfigName = (id: string | number): string => {
  return state?.configs?.find(config => config.id == id)?.name || id.toString()
}

watch(() => [state.listed, state.exclude_qa], () => {
  getCustomerConfigs()
})

onMounted(async () => {
  await Promise.all([
    getCustomerConfigs(),
    getConfigs()
  ])
  hidePFGetHelpButton()
});

</script>

<style scoped>
</style>