<template>
    <div>
        <label class="config-header">User Preferences</label>
        <div class="row">
            <div class="col-lg-6">
                <form @submit.prevent="" class="my-3">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-8">
                                    <label for="enableBetaFeatures">Enable beta features</label>
                                    <Checkbox v-model="betaFeatures" binary class="ms-2" @change="enableBetaFeatures"
                                        :inputId="'enableBetaFeatures'" value="on" />
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-6">
                <form @submit.prevent="" class="my-3">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-8">
                                    <label for="role-impersonate-dropdown">Impersonate Role</label>
                                    <div class="element-container mt-2">
                                        <Select :options="roles" :optionValue="'id'" :optionLabel="'name'"
                                            v-model="roleImpersonate" @change="handleRoleChange"
                                            :inputId="'role-impersonate-dropdown'" class="w-50"
                                            placeholder="Select a role">
                                        </Select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue"
import { StorageKeyEnum, storageManager } from "@/helpers/storageManager"
import Checkbox from 'primevue/checkbox'
import Select from "primevue/select"
import { toast } from "@/helpers/toast"
import { useAPI } from "@/helpers/services/api"
import { useProfile } from "@/stores/profile"


const api = useAPI()
const profile = useProfile()

const roleImpersonate = ref<string | null>(null)

const betaFeatures = ref((storageManager.getItem(StorageKeyEnum.EnableBetaFeatures) === "on"))
const roles = ref([] as { id: string, name: string }[])


const enableBetaFeatures = () => {
    storageManager.setItem(StorageKeyEnum.EnableBetaFeatures, betaFeatures.value ? "on" : "off")
    toast.success("Saved!")
}

const fetchRoles = () => {
    api.get("users/roles/")?.then(response => {
        roles.value = response.data["roles"]
    })
}


const handleRoleChange = async () => {
    if (!roleImpersonate.value)
        return

    const roleID = roleImpersonate.value.toString()
    const role = roles.value.find(x => x.id === roleImpersonate.value)

    await profile.assumeRole(roleID, role?.name || "Unknown Role")
    window.location.reload()
}

onMounted(() => {
    fetchRoles()
})
</script>
