import type { AxiosError } from "axios";
import DOMPurify from 'dompurify';
import dayjs from "dayjs"
import { computed } from "vue"
import { useProfile } from "@/stores/profile"
import { toast } from "@/helpers/toast";
import { StorageKeyEnum, storageManager } from "@/helpers/storageManager";
import type { Operator } from "@/helpers/interface/general";
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'

// Constants
const MAX_NOTE_LENGTH = 4000
const MONETARY_INPUT_TOOLTIP = "A monetary value greater than or equal to zero. Example: 5000"
const RECOVERED_AMOUNT_TOOLTIP = "The estimated amount recovered by removing this exemption. The default is taxable value subtracted from assessed value. If you do not know this number, you may leave the default or use zero. The value may be changed later."
const APPVET_DETAILS_VIEW_ONLY_PATH = "/application-view/"
const RELEASED_IR_GRAPHIC_URL = "/images/truescoredial-1.png"
const PV_SCROLL_HEIGHT = "100vh"
const DEFAULT_HOMEPAGE = "/candidates-list/all"

// Enums
enum QueueCode {
    Inbox = "inbox",
    Questionnaire = "questionnaire",
    Unqualified = "unqualified",
    Snooze = "snooze",
    Archive = "archive",
    Monitor = "monitor",
    All = "all",
    InvestigateNow = "investigate_now",
    InvestigationReports = "released_ir",
    UnderInvestigation = "unreleased_ir",
    AssignedToMe = "assigned_to_me"
}

enum SharedSettings {
    promon_default_snooze_days = "promon_default_snooze_days",
    application_default_snooze_days = "application_default_snooze_days"
}

enum InternalSettings {
    app_id_property_name = "app_id_property_name",
    application_processing = "application_processing",
    prioritization_model = "prioritization_model",
    appvetter_dashboard_url = "appvetter_dashboard_url",
    promon_dashboard_url = "promon_dashboard_url",
    enable_beta_features = "enable_beta_features",
    proactive_monitoring = "proactive_monitoring",
    application_vetter = "application_vetter",
    investigation_services = "investigation_services",
    promon_list_layout = "promon_list_layout",
    application_parcel_display = "application_parcel_display",
    promon_default_snooze_days = SharedSettings.promon_default_snooze_days,
    application_default_snooze_days = SharedSettings.application_default_snooze_days
}

enum ClientSettings {
    promon_default_snooze_days = SharedSettings.promon_default_snooze_days,
    application_default_snooze_days = SharedSettings.application_default_snooze_days
}

type UpdateTypeMap = { [key: number]: string };

const updateTypes: UpdateTypeMap = {
    1: "System Update",
    2: "User Update",
    3: "Snooze Alarm",
    4: "Flag Added",
    5: "New Candidate",
    6: "Historical",
    7: "New Label",
    8: "Deleted Label",
    9: "Questionnaire Submitted",
    10: "Sold Update",
    11: "Questionnaire Created",
    12: "Mass Update",
    13: "Questionnaire Mailed",
    14: "Document Update",
    15: "Proactive Update",
    16: "NCOA Mail Addr Update",
    17: "Investigation Report",
    18: "TaxRoll Exemption Update",
    19: "TaxRoll Exemption Update",
    20: "TaxRoll Attribute Update",
    21: "Investigation Report Released",
    22: "Mail Module - Added",
    23: "Mail Module - Mailed",
    24: "Mail Module - Deleted",
    25: "Mail Module - Expired",
    26: "Voter Details",
    27: "Note Added"
};

const automationUsers = [
    "TrueRoll",
    "--system event--",
    "--citizen event--",
    "--sold event--",
    "--mass update--",
    "--questionnaire event--",
    "--proactive monitoring--",
    "--taxroll updater--",
];

type stringOrNothing = string | null | undefined;


const ucfirst = (name: any) => {
    if (!(name == null || name == undefined || name == "")) {
        const capitalizedFirst = name[0].toUpperCase();
        const rest = name.slice(1);
        return capitalizedFirst + rest;
    }
    return name
}

function ucwords(sentence: string) {
    return sentence?.replace(/\b\w/g, (char) => char.toUpperCase());
}

const blankIf = (str: string | null | undefined) => ((str == null || str == undefined) ? '' : str)

const prepareFilterFieldObject = (field: string, type: string, value: any) => ({ field, type, value })

const sortObjectsByKey = <T>(array: T[], key: keyof T): T[] => {
    return array.sort((a, b) => {
        if (a[key] < b[key]) {
            return -1;
        }
        if (a[key] > b[key]) {
            return 1;
        }
        return 0;
    });
}

const getApiErrorMessage = (error: AxiosError, options?: { "featureName"?: string }) => {
    if (!error.response)
        return "Unexpected error (0)"

    const featureName = options?.featureName ?? "Item"

    switch (error.response.status) {
        case 400:
            return "Bad Request"
        case 401:
            return "Unauthorized"
        case 403:
            return "You do not have permission to use this feature"
        case 404:
            return `${featureName} not found`
        case 422:
            return getApiErrorFromJson(error.response.data as Partial<ApiErrorResponse>)
        case 500:
            return "Server error"
        case 503:
            return "The application is down for maintenance. Please wait a few minutes, and try again."
        default:
            return `Unexpected error (${error.response.status})`
    }
}

type ApiErrorResponse = {
    detail?: string,
    error?: string
}

const getApiErrorFromJson = (data: Partial<ApiErrorResponse>): string => {
    if (data.error)
        return data.error

    if (Array.isArray(data.detail) && data.detail.length) {
        const item = data.detail[0]
        const propName = item.loc[item.loc.length - 1].replaceAll("_", " ")
        const formattedName = ucfirst(propName)
        return `${formattedName}: ${item.msg}`
    }

    return "Please confirm you have entered all fields in the expected format, and try again."
}

const getUuidFromPath = (url?: string) => {
    if (!url) {
        url = window.location.href
    }
    const match = url.match(/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/);
    return match ? match[0] : null;
}

const formatProperties = (source: {}, prettyName: any, only: string[] = [], remove: string[] = []) => {
    const array = []
    if (source != null) {
        for (const [key, value] of Object.entries(source)) {
            const pretty_name = prettyName[key];
            if (pretty_name !== undefined) {
                if (only.length !== 0) {
                    if (only.some((arr) => arr === key)) array.push({ "key": pretty_name, "value": value });
                }
                else if (remove.length !== 0) {
                    if (!remove.some((arr) => arr === key)) array.push({ "key": pretty_name, "value": value });
                }
                else array.push({ "key": pretty_name, "value": value });
            }
        }
    }
    // Order the array based on the pretty_name
    array.sort((a, b) => {
        const indexA = Object.values(prettyName).indexOf(a.key);
        const indexB = Object.values(prettyName).indexOf(b.key);
        return indexA - indexB;
    });
    return array
}

const getCookie = (name: string): string | number | null => {
    const cookie = document.cookie
        .split(";")
        .find((cookie) => cookie.trim().startsWith(`${name}=`));

    if (cookie) {
        const [, value] = cookie.split("=");
        return decodeURIComponent(value);
    }

    return null;
}

const setCookie = (name: string, value: string | number, days: number = 7) => {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + days);

    const encodedValue = encodeURIComponent(value);
    let cookieValue = `${name}=${decodeURIComponent(encodedValue)}${days ? `; expires=${expirationDate.toUTCString()}` : ""}; path=/`;

    // Check if the website is accessed over HTTPS and add the Secure attribute
    if (location.protocol === "https:") {
        cookieValue += "; Secure";
    }

    document.cookie = cookieValue;
}

const removeCookie = (name: string) => {
    if (getCookie(name)) {
        document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    }
}

// Used by old promon ui
const getQueueName = (queue: string) => {
    return (queue?.toLowerCase() === "unreleased_ir") ? "Being investigated by TrueRoll" : queue || ""
}

// Used by beta/new promon ui
const getQueueText = (queue: string) => {
    let queueText = ""
    switch (queue?.toLowerCase()) {
        case "unreleased_ir":
            queueText = "under investigation"
            break;
        case "released_ir":
            queueText = "investigate reports"
            break;
        case "investigate_now":
            queueText = "investigate now"
            break;
        case "assigned_to_me":
            queueText = "assigned to me"
            break;
        default:
            queueText = queue
            break;
    }
    return queueText
}


const sanitizeHTML = (html: string) => {
    const htmlOutput = DOMPurify.sanitize(html, { RETURN_DOM: true, ADD_ATTR: ['target'] })
    return htmlOutput?.innerHTML
}

const convertLineBreaksToHTML = (text: string) => {
    // If the `text` does not contain any HTML tags, replace all line breaks with HTML break tags.
    const html = (isHTML(text)) ? text : text.replace(/\n/g, "<br>")
    return html;
}

const isHTML = (str: string): boolean => {
    // Basic regex to check if the `str` has a html tags
    const regexForHTML = /<[^>]+>/
    return regexForHTML.test(str)
}


const downloadArrayToCSV = async (data: {}[], filename: string = "table", customHeaderName: string[] = []) => {
    if (data.length === 0) return
    const csvData = convertArrayToCSV(data, customHeaderName)
    const blob = new Blob([csvData], { type: 'text/csv' })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `${filename}-${dayjs().toISOString()}.csv`)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
}

const convertArrayToCSV = (dataArray: { [key: string]: any }[], customHeaderName: string[] = []) => {
    const items = dataArray
    const replacer = (key: string, value: any) => value === null ? '' : value
    const filenameHeaderName = Object.keys(items[0])
    const csv = [
        customHeaderName.join(','), // inject the custom header name
        ...items.map(row => filenameHeaderName.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','))
    ].join('\r\n')
    return csv
}


const downloadCSV = (data: string, filename: string) => {
    const blob = new Blob([data], { type: 'text/csv' })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `${filename}.csv`)
    document.body.appendChild(link)
    link.click()
    URL.revokeObjectURL(url)
}

const getDisplayDate = (date: string | undefined) => {
    return date ? dayjs(date).format("MM/DD/YYYY") : ""
}

const getCurrencyFormatter = () => {
    return new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" })
}

const generateUUIDv4 = () => {
    // Create a Uint8Array with 16 bytes
    const array = new Uint8Array(16);

    // Fill the array with cryptographically secure random numbers
    crypto.getRandomValues(array);

    // Convert to hexadecimal and apply some special UUID v4 properties
    const hexArray: string[] = Array.from(array).map((byte) => {
        return ('0' + byte.toString(16)).slice(-2);
    });

    // Set the 13th digit to "4" to indicate it is a version 4 UUID
    hexArray[6] = '4' + hexArray[6][1];

    // Set the high bits of the 17th digit to comply with RFC 4122 variant
    const highBits = ['8', '9', 'a', 'b'];
    const randomHighBit = highBits[Math.floor(Math.random() * 4)];
    hexArray[8] = randomHighBit + hexArray[8][1];

    // Construct the UUID string
    const uuid = `${hexArray.slice(0, 4).join('')}-${hexArray.slice(4, 6).join('')}-${hexArray.slice(6, 8).join('')}-${hexArray.slice(8, 10).join('')}-${hexArray.slice(10, 16).join('')}`;

    return uuid;
}

const filterObjectWithValues = (obj: Record<string, any>) => {
    const result: Record<string, any> = {};
    for (const key in obj) {
        if (obj[key] !== null && obj[key] !== "") {
            result[key] = obj[key];
        }
    }
    return result;
};


const situsInfoPrettyNames = {
    "situs_full_taxroll": "Address",
    "parcel_num": "Parcel Num",
    "owner_name": "Parties",
    "legal_description": "Legal",
    "class": "Class",
    "exmpt_description": "Exemption(s)",
    "market_value": "Market $",
    "assessed_value": "Assessed $",
    "taxable_value": "Taxable $",
    "deed_date": "Qualification Date",
    "county_lookup_url": "County URL",
    "mail_full_taxroll": "Address",
    "mail_county": "County",
    "queue": "Queue",
    "reason": "Last Reason",
    "unqualified_start_year": "Unqual Start Yr",
    "lien_or_back_taxes": "Lien",
    "snooze_until": "Snooze Until",
    "final_outcome": "Final Outcome",
    "recovered_amount": "Estimated Recovered Amount",
}


const filterStatus = (source: any, field: string) => {
    if (!Object.keys(source).includes(field)) {
        return false
    }
    switch (source["queue"]) {
        case "archive":
            if (!["queue", "unqualified_start_year", "lien_or_back_taxes", "final_outcome", "recovered_amount"].includes(field))
                return false
            break
        case "unqualified":
            if (!["queue", "reason", "unqualified_start_year", "lien_or_back_taxes", "recovered_amount"].includes(field))
                return false
            break
        case "snooze":
            if (!["queue", "reason", "snooze_until", "recovered_amount"].includes(field))
                return false
            break
        case "monitor":
            if (!["queue", "reason", "recovered_amount"].includes(field))
                return false
            break
        default:  // inbox, questionnaire
            if (field !== "queue")
                return false
    }
    const value = source[field]
    return (value !== null && value !== "" && value !== undefined)
}


const getDisplayValue = (value: any) => {
    if (typeof value === "boolean")
        return value ? "Yes" : "No"
    if (typeof value === "number")
        return value.toString()
    if (typeof value === "string" && /^(\d{4})-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])T([01][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]([+-][01][0-9]|2[0-3]):[0-5][0-9]$/.test(value))
        return dayjs(value).format("MM/DD/YYYY")
    return ucfirst((value || "").toString())
}


type PermissionType = 'read' | 'create' | 'update' | 'delete';
type PermissionFeature = 'customers' | 'released_investigation_reports'
    | 'unreleased_investigation_reports' | 'candidates'
    | 'candidates_assigned' | 'candidates_archived'
    | 'mailing_templates' | 'postal_contact_workflows'
    | 'labels' | 'applications'
    | 'applications_vet' | 'users'
    | 'ingestion_configs' | 'data_requests' | 'uploads'
    | 'reports_assessed_value' | 'reports_unclaimed' | 'internal_release'
    | 'investigation_quick_links' | "internal_settings";

const validateUserPermission = (type: PermissionType, feature: PermissionFeature) => {
    const storeProfile = useProfile()
    const userPermissions = computed(() => storeProfile.getCurrentPermissions)
    const requestPermission = `${type}:${feature}`
    return userPermissions.value.includes(requestPermission)
}

const dollarStringToFloat = (stringValue: string) => (typeof stringValue === 'string') ? parseFloat(stringValue.replace(/[^0-9.]/g, '')) : stringValue

const setPageTitle = (title: string) => {
    const titlePrefix = title ? title + " - " : ""
    window.document.title = titlePrefix + "TrueRoll"
}

const copyTextToClipboard = async (text: string | null) => {
    if (!text) return
    if (!navigator.clipboard) {
        toast.error("Clipboard API not available")
    }

    try {
        await navigator.clipboard.writeText(text)
        toast.success("Copied!", { position: "bottom" })
    } catch (error: unknown) {
        toast.error(`Failed to copy text: ${(error as Error).message}`)
    }
}

// Function to calculate scrollHeight
export const calculateScrollHeight = (containerRef: HTMLElement | null, marginBuffer = 160, minHeight = 300): number => {
    if (containerRef) {
        // Get viewport height
        const viewportHeight = window.innerHeight
        const footer = document.getElementById("page-footer")
        const footerOffset = footer ? footer.offsetHeight : 0
        // Calculate scrollHeight
        const newScrollHeight = viewportHeight - containerRef.getBoundingClientRect().top - marginBuffer - footerOffset
        return Math.max(newScrollHeight, minHeight)
    }
    return 600 // default
}

const formatNumberWithCommas = (num: number): string => num.toLocaleString('en-US')
const formatDate = (date: string | undefined | null) => !date ? "" : dayjs(date).format("M/D/YYYY")
const timeDiffForHumans = (date: string | undefined | null, unit: "second" | "minute" | "hour" | "day" | "week" | "month" | "year") => {
    const days_elapsed = dayjs().diff(date?.split('T')[0], unit)
    return days_elapsed
}

const getDocumentCategoryName = (documentType: string) => {
    switch (documentType) {
        case 'exemption-disabled-documentation-file':
        case 'exemption-disabled-veteran-documentation-file':
        case 'exemption-surviving-spouse-armed-services-documentation-file':
        case 'exemption-surviving-spouse-first-responder-documentation-file':
        case 'exemption-donated-residence-documentation-file':
        case 'applicant-not-on-deed-documentation-file':
        case 'heir-property-owner-occupies-property-documentation-file':
        case 'waive-reason-active-duty-military-id-file':
        case 'waive-reason-active-duty-utility-bill-file':
        case 'waive-reason-special-drivers-documentation-file':
            return 'User Attachment';

        case 'property-owner-id-file-1':
        case 'property-owner-id-file-2':
            return 'Identification';

        case 'pdf':
            return 'Audit Record';

        default:
            return 'Other';
    }
}

const getQueryParams = (url: string): URLSearchParams => {
    const queryString = url.split('?')[1];
    return new URLSearchParams(queryString);
}

const betaFeaturesEnabled = () => {
    return (storageManager.getItem(StorageKeyEnum.EnableBetaFeatures) === "on")
}


const allFilterOperator: Operator[] = [
    ">",
    ">=",
    "<",
    "<=",
    "=",
    "!=",
    "is null",
    "is not null",
    "in",
    "not in",
    "like",
    "not like",
    "ilike",
    "not ilike",
    "starts",
    "ends",
    "~*",
    "has all"
]


const getNormalizedPriorityScore = (score: number | string | undefined | null) => {
    // TODO: convert Xyonix confidence to TrueRoll 1-5
    // convert any value to integer
    return ~~(score || 0)
}


const getPriorityScoreGraphicUrl = (score: number | string | undefined | null) => {
    const normalizedScore = getNormalizedPriorityScore(score)
    return `/images/truescoredial${normalizedScore}.png`
}


const wrapTextWithParagraphs = (input: string): string => {
    // replace blocks of text separated with <BR> tags with paragraphs
    const parts = input.split(/<br\s*\/?>/i);
    const wrappedParts = parts.map(part => `<p>${part.trim()}</p>`);
    return wrappedParts.join("");
}

const promonQueueTabs = [
    { label: "Inbox", iconClass: "fas fa-inbox", id: "inbox" },
    { label: "Snooze", iconClass: "fas fa-clock", id: "snooze", badge: 0 },
    { label: "Questionnaire", iconClass: "fas fa-question-circle", id: "questionnaire" },
    { label: "Unqualified", iconClass: "fas fa-user-times", id: "unqualified" },
    { label: "All", iconClass: "fas fa-list", id: "all" },
    { label: "Archive", iconClass: "fas fa-archive", id: "archive" },
    { label: "Investigate Now", iconClass: "fas fa-eye", id: "investigate_now", badge: 0, hidden: true },
    { label: "Investigation Reports", iconClass: "fas fa-file-circle-check", id: "released_ir", badge: 0, hidden: true },
    { label: "Under Investigation", iconClass: "fas fa-file-pen", id: "unreleased_ir", hidden: true },
    { label: "Assigned To Me", iconClass: "fas fa-user-tag", id: "assigned_to_me" },
    { label: "Activity", iconClass: "fas fa-chart-line", id: "activity" },
]

const galleriaThumbnailButtons = () => {
    setTimeout(() => {
        // Remove disabled attribute in thumbnail buttons
        const previousThumbnailButton = document.querySelector(".p-galleria-thumbnail-prev")
        if (!previousThumbnailButton) return
        if (previousThumbnailButton?.getAttributeNames().includes("disabled")) {
            previousThumbnailButton?.removeAttribute("disabled")
            previousThumbnailButton?.classList.remove("p-disabled")
        }

        const nextThumbnailButton = document.querySelector(".p-galleria-thumbnail-next")
        if (!nextThumbnailButton) return
        if (nextThumbnailButton?.getAttributeNames().includes("disabled")) {
            nextThumbnailButton?.removeAttribute("disabled")
            nextThumbnailButton?.classList.remove("p-disabled")
        }
    }, 2000)
}

const galleriaKeyboardSupport = (hook: string, handleKeyDown: any) => {
    const galleriaRef = document.querySelector(".p-galleria-mask") as HTMLDivElement
    if (galleriaRef) {
        if (hook === 'attach') {
            galleriaRef.addEventListener('keydown', handleKeyDown)
            galleriaRef.addEventListener('focus', () => handleKeyDown)
            galleriaRef.addEventListener('blur', () => handleKeyDown)
        }
        if (hook === 'detach') {
            galleriaRef.removeEventListener('keydown', handleKeyDown)
            galleriaRef.removeEventListener('focus', () => handleKeyDown)
            galleriaRef.removeEventListener('blur', () => handleKeyDown)
        }
    }
}

const isImageFile = (filename: string): boolean => {
    const imageExtensions = /\.(jpg|jpeg|png|gif|bmp|webp|svg|tiff)$/i;
    return imageExtensions.test(filename);
}

const getFormattedAddress = (streetNumber: stringOrNothing, streetName: stringOrNothing, city: stringOrNothing, state: stringOrNothing, postalCode: stringOrNothing) => {
    const cleaner = (items: stringOrNothing[]) => items.map(x => (x || "").trim()).filter(x => !!x)
    const address = cleaner([streetNumber, streetName]).join(" ")
    const parts = cleaner([address, city, state])
    return `${parts.join(", ")} ${(postalCode || "").trim()}`.trim()
}

const isSnoozeExpired = (date: string): boolean => {
    dayjs.extend(isSameOrAfter)

    const currentDate = dayjs()
    return (currentDate.isSameOrAfter(date, 'day'))
}

const stringifyPrimitive = (value: any): string => {
    if (value === null || value === undefined)
        return ""

    if (typeof value === "boolean")
        return value ? "Yes" : "No"

    if (typeof value === "number")
        return value.toString()

    if (value.constructor.name === "Date")
        return value.toLocaleDateString()

    if (Array.isArray(value)) {
        return value.map(x => stringifyPrimitive(x)).join(", ")
    }

    return value.toString()
}


const generateAdHocDataTable = (array: Record<string, any>[]): HTMLElement => {
    const table = document.createElement("table");
    const thead = document.createElement("thead");
    const tbody = document.createElement("tbody");

    table.appendChild(thead);
    table.appendChild(tbody);

    table.classList.add("table", "table-striped")

    if (array.length === 0) {
        return table;
    }

    const headers = Object.keys(array[0]);

    const headerRow = document.createElement("tr");
    headers.forEach(header => {
        const th = document.createElement("th");
        th.classList.add("text-capitalize")
        th.textContent = header.replace(/_/g, " ");
        headerRow.appendChild(th);
    });
    thead.appendChild(headerRow);

    array.forEach(row => {
        const tr = document.createElement("tr");
        headers.forEach(header => {
            const td = document.createElement("td");
            td.textContent = stringifyPrimitive(row[header]);
            tr.appendChild(td);
        });
        tbody.appendChild(tr);
    });

    return table;
};


const scrollToView = (query: string, behavior: ScrollBehavior = "auto") => {
    const element = document.getElementById(query);
    if (element) {
        element.scrollIntoView({ behavior: behavior, block: 'start' });
    }
}


const isImpersonatingRole = () => {
    const json = storageManager.getItem(StorageKeyEnum.ImpersonationCache)
    if (json) {
        const data = JSON.parse(json)
        return !!data
    }
    return false
}

const qShadowRoot = (selector: string) => {
    const container = document?.querySelector(".productfruits--container") as HTMLDivElement
    return container?.shadowRoot?.querySelector(selector) as HTMLDivElement
}

const hidePFGetHelpButton = () => {
    const checkContainerExistence = setInterval(() => {
        const pfContainer = document?.querySelector(".productfruits--container") as HTMLDivElement
        if (pfContainer) {
            const styleOverride = document.createElement("style");
            styleOverride.textContent = `
                .pfruits-banner.pfruits-banner {
                    z-index: 1;
                }
                .productfruits--lifering.productfruits--lifering {
                    display: none;
                }
            `
            pfContainer?.shadowRoot?.appendChild(styleOverride)
            clearInterval(checkContainerExistence)
        }
    }, 100)
    setTimeout(() => clearInterval(checkContainerExistence), 5000) // Stop checking after 5 seconds
}


const toggleCheckboxes = (selector: string, disable: boolean = false) => {
    const checkboxes = document.querySelectorAll<HTMLInputElement>(selector)
    checkboxes?.forEach((checkbox) => {
        if (disable) {
            checkbox.setAttribute("disabled", "true") // Disable checkbox
        } else {
            checkbox.removeAttribute("disabled") // Enable checkbox
        }
    })
}

const phoneInputAttributes = {
    inputmode: 'tel',
    autocomplete: 'tel'
}

const clearInvalidDate = (dateString: string) => {
    const date = dayjs(dateString)
    if (!date.isValid()) return null

    // Rules
    // Check if year <= 1900
    const year = date.format("YYYY")
    if (year <= "1900") return null
    

    // Valid date
    return formatDate(dateString)
}

export {
    APPVET_DETAILS_VIEW_ONLY_PATH,
    MAX_NOTE_LENGTH,
    MONETARY_INPUT_TOOLTIP,
    RECOVERED_AMOUNT_TOOLTIP,
    RELEASED_IR_GRAPHIC_URL,
    PV_SCROLL_HEIGHT,
    DEFAULT_HOMEPAGE,
    QueueCode,
    InternalSettings,
    ClientSettings,
    automationUsers,
    blankIf,
    convertLineBreaksToHTML,
    downloadArrayToCSV,
    formatProperties,
    generateAdHocDataTable,
    generateUUIDv4,
    getApiErrorMessage,
    getCookie,
    getCurrencyFormatter,
    getFormattedAddress,
    getQueueName,
    isHTML,
    isImpersonatingRole,
    prepareFilterFieldObject,
    removeCookie,
    sanitizeHTML,
    setCookie,
    sortObjectsByKey,
    ucfirst,
    ucwords,
    updateTypes,
    downloadCSV,
    getDisplayDate,
    filterObjectWithValues,
    situsInfoPrettyNames,
    filterStatus,
    getDisplayValue,
    getUuidFromPath,
    validateUserPermission,
    dollarStringToFloat,
    setPageTitle,
    formatNumberWithCommas,
    formatDate,
    getDocumentCategoryName,
    getQueryParams,
    betaFeaturesEnabled,
    timeDiffForHumans,
    getQueueText,
    allFilterOperator,
    convertArrayToCSV,
    getNormalizedPriorityScore,
    getPriorityScoreGraphicUrl,
    wrapTextWithParagraphs,
    promonQueueTabs,
    isImageFile,
    galleriaThumbnailButtons,
    galleriaKeyboardSupport,
    isSnoozeExpired,
    stringifyPrimitive,
    copyTextToClipboard,
    scrollToView,
    qShadowRoot,
    hidePFGetHelpButton,
    toggleCheckboxes,
    phoneInputAttributes,
    clearInvalidDate
}