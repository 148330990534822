<template>
    <div ref="candidateDataView" id="candidateDataView">
        <div class="row action-toolbar-container" id="taxroll-list-table-header-section">
            <div class="col-8" v-if="!loading">
                <Button :disabled="totalRecords <= 0 || toggleShowAllSelectedItems" v-if="!selectAllCurrentPage"
                    id="select-all" type="button" outlined class="me-2" @click="onSelectAllChange()"
                    severity="secondary">
                    <span class="fa fa-check-square" aria-hidden="true"></span>
                    Select All
                </Button>
                <Button v-else id="deselect-all" type="button" outlined class="me-2" @click="clearSelection()"
                    severity="secondary">
                    <span class="fa fa-square-minus" aria-hidden="true"></span>
                    Clear Selection
                </Button>
                <Button type="button" class="me-2" id="queue-action-item" @click="onBulkActionClick('UPDATE_QUEUE')"
                    severity="success" :disabled="(selectedItems.length <= 0 && !selectAllCurrentPage)">
                    <span class="fa fa-tasks" aria-hidden="true"></span>
                    Update Queue
                </Button>
                <SplitButton label="Assign" rounded class="me-2" :model="assignActionItems" id="assign-action-item"
                    :disabled="(selectedItems.length <= 0 && !selectAllCurrentPage)"
                    @click="onBulkActionClick('ASSIGN')">
                    <span class="fa fa-user-plus" aria-hidden="true"></span>
                    Assign
                </SplitButton>
                <SplitButton label="Labels" rounded class="me-2" :model="labelActionItems" id="label-action-item"
                    :disabled="(selectedItems.length <= 0 && !selectAllCurrentPage)"
                    @click="onBulkActionClick('ADD_LABEL')" severity="info">
                    <span class="fa fa-tags" aria-hidden="true"></span>
                    Labels
                </SplitButton>
                <Button type="button" class="me-2" id="note-action-item" @click="onBulkNoteClick" severity="info"
                    :disabled="(selectedItems.length <= 0 && !selectAllCurrentPage)">
                    <span class="fa fa-comment" aria-hidden="true"></span> Note
                </Button>
                <span v-if="props.queue === 'unreleased_ir'">
                    <Button type="button" class="me-2" id="delete-ir-action-item" @click="onBulkIRClick('delete')"
                        severity="info" :disabled="(selectedItems.length <= 0 && !selectAllCurrentPage)">
                        <span class="fa fa-file-excel" aria-hidden="true"></span> Delete
                    </Button>
                </span>
            </div>
            <div class="col-8" v-else>
                <Skeleton width="115px" height="34px" class="ms-2 mb-2"></Skeleton>
            </div>
            <div class="col-4">
                <StrapCardHeaderActions v-model:quickSearchQuery="quickSearchQuery" v-model:filters="filters"
                    :quickSearchQueryTitle="quickSearchQueryTitle" :sortTitles="sortTitles" :filterConfig="filterConfig"
                    :loading="loading" :sortOrder="sortOrder" :parentDataView="candidateDataView"
                    :toggleShowAllSelectedItems="toggleShowAllSelectedItems" @changeSortOrder="changeSortOrder"
                    @submitFilters="handleSubmitFilters" @removeFilters="handleRemoveFilters"
                    @onFiltersDirtyChanged="onFiltersDirtyChanged" ref="strapCardHeaderActionsRef" />
            </div>
        </div>

        <div :class="{ 'd-none': selectedItems.length <= 0 || selectAllCurrentPage || loading }" class="text-center"
            id="list-header-select-all-message">
            <Message class="mb-2" severity="secondary" :closable="false">
                You have selected
                <a v-tooltip.bottom="`View selected ${textCandidate}`" href="javascript:;"
                    @click="showAllSelectedItems()" id="view-all-selected">
                    {{ selectedItems.length }} {{ textCandidate }}.
                </a>
                <a href="javascript:;" @click="clearAllSelectedItems()">Clear Selection </a>
            </Message>
        </div>

        <div :class="{ 'd-none': !selectAllCurrentPage }" class="text-center" id="list-header-select-all-message">
            <Message class="mb-2" severity="secondary" :closable="false">
                <span v-if="!selectAll">
                    All {{ formatNumberWithCommas(totalSelectedCandidate) }} candidates on this
                    page are selected.
                    <a v-if="!selectAll && (selectedItems.length < totalRecords)" href="javascript:;"
                        @click="() => selectAll = true">
                        Select all {{ formatNumberWithCommas(totalRecords) }} candidates in this
                        tab
                    </a>
                </span>
                <span v-else>
                    All {{ formatNumberWithCommas(totalSelectedCandidate) }} candidates are
                    selected.
                    <a v-if="selectAll" href="javascript:;" @click="clearSelection()">
                        Clear Selection
                    </a>
                </span>
            </Message>
        </div>

        <div :class="{ 'd-none': !showQSMessage }" class="text-center" id="list-header-select-all-message">
            <Message class="mb-2" severity="secondary" :closable="false"
                v-if="(totalRecords === 0 && !enabledQuickSearchAllQueue) && props.queue !== QueueCode.All">
                Would you like to search through
                <a href="javascript:;" @click="() => quickSearchAllQueue()">
                    all queues
                </a>
                instead?
            </Message>

            <Message class="mb-2" severity="secondary" :closable="false"
                v-if="(totalRecords === 0) && props.queue === QueueCode.All && !qsIncludeArchive">
                Would you like to
                <a href="javascript:;" @click="() => quickSearchAllQueue(true)">
                    include archived candidates
                </a>
                in your search?
            </Message>
        </div>

        <div v-if="loading">
            <div v-for="i in 5" :key="i" class="row candidate-list-row-card">
                <div class="col align-content-center">
                    <div class="p-4">
                        <Skeleton width="80%" height="1rem" class="mb-2"></Skeleton>
                        <Skeleton width="70%" height="1rem" class="mb-2"></Skeleton>
                        <Skeleton width="40%" height="1rem" class="mb-2"></Skeleton>
                    </div>
                </div>
                <div class="col-2 align-content-center">
                    <Skeleton width="80%" height="1rem" class="mb-2"></Skeleton>
                    <Skeleton width="60%" height="1rem" class="mb-2"></Skeleton>
                </div>
                <div class="col-2 align-content-center">
                    <Skeleton width="50%" height="1rem" class="mb-2"></Skeleton>
                    <Skeleton width="80%" height="1rem" class="mb-2"></Skeleton>
                </div>
                <div class="col-2 align-content-center">
                    <div class="p-3">
                        <Skeleton width="100%" height="1rem" class="mb-2"></Skeleton>
                        <div class="d-flex mb-2">
                            <Skeleton width="50%" height="1rem" class="me-2"></Skeleton>
                            <Skeleton width="80%" height="1rem" class="me-2"></Skeleton>
                            <Skeleton width="30%" height="1rem" class="me-2"></Skeleton>
                        </div>
                    </div>
                </div>
                <div class="col-2 align-content-center">
                    <div class="p-3 d-flex">
                        <Skeleton width="100%" height="4rem" class="me-2"></Skeleton>
                    </div>
                </div>
            </div>
        </div>

        <BasicStrapCards :loading="loading" :data="data" :firstIndex="first" :rows="rows" :componentID="props.queue"
            :totalRecords="totalRecords" @onpage="(event) => onPage(event)" :parentDataView="candidateDataView">
            <template #list="slotProps">
                <div v-for="(item, index) in (slotProps.data as CandidateListNew[])" :key="index"
                    class="row dataview-list-row-card" :id="`row_${index}`"
                    :class="{ 'attention-needed fw-bold': item.queue === 'snooze' && isSnoozeExpired(item.snooze_until) }">
                    <div class="col-1 align-content-center" id="selectionColumn">
                        <div class="p-3">
                            <Checkbox v-model="selectedItems" :inputId="item.tru_id" name="category"
                                :value="item.tru_id" />
                        </div>
                    </div>
                    <div class="col align-content-center clipboard-copy-wrapper">
                        <div class="p-3">
                            <div class="mb-2">
                                <span>
                                    <a :href="`/candidate-details/${item.tru_id}`" target="_blank">
                                        {{ item.parcel_num }}
                                    </a>
                                </span>
                                <a href="javascript:;" v-tooltip.bottom="'Add to Quick Search'"
                                    class="quickSearchLink ms-2" @click="addToQuickSearch(item.parcel_num)">
                                    <span class="fa fa-search fa-xs"></span>
                                </a>

                                <a href="javascript:;" v-tooltip.bottom="'Copy to Clipboard'" class="copy-to-clipboard"
                                    @click="copyTextToClipboard(item.parcel_num)">
                                    <span class="fa-regular fa-copy fa-xs"></span>
                                </a>
                            </div>
                            <div class="mb-2">
                                {{ item.situs }}
                            </div>
                            <div v-if="item?.owners?.length">
                                <div class="mb-2" v-for="(owner, index) in item.owners" :key="index">
                                    {{ owner }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col align-content-center">
                        <div class="p-3">
                            <div v-if="item?.exemptions?.length">
                                <div class="mt-2" v-for="(exemption, index) in item.exemptions" :key="index">
                                    {{ exemption }}
                                </div>
                            </div>
                            <div v-if="item.effective_date" class="mt-2">
                                Effective {{ formatDate(item.effective_date) }}
                            </div>
                        </div>
                    </div>
                    <div class="col align-content-center">
                        <div class="p-3">
                            <div v-if="item.last_queue_change" class="mb-2">
                                <span v-tooltip="formatDate(item.last_queue_change)">
                                    {{ lastQueueChange(item.last_queue_change, item.queue) }}
                                </span>
                            </div>
                            <div class="mb-2" v-if="props.queue === 'inbox'">
                                <div v-if="updatedOn(item)">
                                    <span v-tooltip="formatDate(updatedOn(item))">
                                        <template v-if="timeDiffForHumans(updatedOn(item), 'day')">
                                            Updated {{ timeDiffForHumans(updatedOn(item), 'day') }} days ago
                                        </template>
                                        <template v-else>
                                            Updated {{ dayjs(updatedOn(item)).fromNow() }}
                                        </template>
                                    </span>
                                </div>
                            </div>
                            <div v-if="!['investigate_now', 'released_ir'].includes(props.queue)">
                                <div class="mb-2 text-capitalize">Moved from: {{ getQueueText(item.prev_queue) }}</div>
                                <div class="mb-2" v-if="item.last_queue_change_user">By: {{
                                    item.last_queue_change_user }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="col align-content-center">
                        <div class="p-3">
                            <span v-if="item.assigned_user_id">
                                <Chip :label="item.assigned_user" class="me-2 mb-2 tr-chip assigned-to-chip" />
                            </span>
                            <span v-else class="mb-2">
                                <i>Unassigned</i>
                            </span>
                            <div v-if="item.labels" class="d-block mt-2">
                                <span v-for="(label, index) in item.labels" :key="index">
                                    <Chip :label="label" class="me-2 mb-2 tr-chip label-chip" />
                                </span>
                            </div>
                        </div>

                    </div>
                    <div class="col align-content-center">
                        <div class="p-3">
                            <div v-if="promonListLayoutQueues.includes(props.queue)">
                                <div v-if="promonListLayout === 'flags'">
                                    <span v-for="flag in item.flags" :key="flag" class="d-block" style="margin: 3px">
                                        {{ flag }}
                                    </span>
                                </div>
                                <div v-else class="candidate-list-img-container">
                                    <div v-if="item.released_ir">
                                        <img :src="RELEASED_IR_GRAPHIC_URL" alt="Priority Score">
                                    </div>
                                    <div v-else>
                                        <img :src="getPriorityScoreGraphicUrl(item.priority_score)"
                                            alt="Priority Score">
                                    </div>
                                </div>
                            </div>
                            <div v-else-if="props.queue === 'questionnaire'">{{ item.mailing_template }}</div>
                            <div v-else-if="props.queue === 'unqualified'">
                                <div class="mb-2">
                                    {{ formatter.format(item.recovered_amount as unknown as number) }}
                                </div>
                                <div>
                                    <StrapCardListDetailsButton :id="item.tru_id"
                                        :click-handler="handleActivateListDrawerClick" />
                                </div>
                            </div>
                            <div v-else-if="props.queue === 'snooze'">
                                <div class="mb-2">
                                    Snoozed
                                    <span v-if="isSnoozeExpired(item.snooze_until)">Expired</span>
                                    <span v-else>Until</span>
                                    {{ formatDate(item.snooze_until) }}
                                </div>
                                <div>
                                    <StrapCardListDetailsButton :id="item.tru_id"
                                        :click-handler="handleActivateListDrawerClick" />
                                </div>
                            </div>
                            <div v-else-if="props.queue === 'archive'">
                                <div class="mb-2 text-capitalize">{{ item.final_outcome }}</div>
                            </div>
                            <div v-else-if="props.queue === 'released_ir'">
                                <img :src="RELEASED_IR_GRAPHIC_URL" alt="Released IR">
                            </div>
                            <div v-else-if="props.queue === 'investigate_now'">
                                <StrapCardListDetailsButton :id="item.tru_id"
                                    :click-handler="handleActivateListDrawerClick" />
                            </div>
                            <div v-else>
                                <div class="mb-2 text-capitalize">{{ getQueueText(item.queue) }}</div>
                                <div>
                                    <StrapCardListDetailsButton :id="item.tru_id"
                                        :click-handler="handleActivateListDrawerClick" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </BasicStrapCards>

        <div id="promon-modals">
            <BulkActionModalDialog :visible="bulkAssign.showAssignDialog.value"
                :dialogTitle="`Assign ${formatNumberWithCommas(totalSelectedCandidate)} ${textCandidate}`"
                :closeDialog="closeAssignDialog" :selectAll="selectAll" :selectAllCurrentPage="selectAllCurrentPage">
                <template #form-fields>
                    <Select v-model="bulkAssign.user.value" :options="userList" optionLabel="name"
                        placeholder="-- Assign User --" class="d-flex my-2" />
                    <Textarea v-model="bulkAssign.optionalNote.value" :maxlength="MAX_NOTE_LENGTH"
                        placeholder="Add note" />
                </template>
                <template #footer-button>
                    <Button @click="submitBulkAssign('assign')" id="bulk-assign" :disabled="bulkAssign.loading.value">
                        <span class="fa fa-plus-circle" aria-hidden="true" v-if="!bulkAssign.loading.value"></span>
                        <span class="fa fa-spinner fa-spin" aria-hidden="true"
                            v-if="bulkAssign.loading.value"></span>
                        Assign
                    </Button>
                </template>
            </BulkActionModalDialog>

            <BulkActionModalDialog :visible="bulkAssign.showUnassignDialog.value"
                :dialogTitle="`Unassign ${formatNumberWithCommas(totalSelectedCandidate)} ${textCandidate}`"
                :closeDialog="closeAssignDialog" :selectAll="selectAll" :selectAllCurrentPage="selectAllCurrentPage">
                <template #form-fields>
                    <Textarea v-model="bulkAssign.optionalNote.value" :maxlength="MAX_NOTE_LENGTH"
                        placeholder="Add note" />
                </template>
                <template #footer-button>
                    <Button @click="submitBulkAssign('unassign')" id="bulk-unassign"
                        :disabled="bulkAssign.loading.value" severity="warning">
                        <span class="fa fa-user-times" aria-hidden="true" v-if="!bulkAssign.loading.value"></span>
                        <span class="fa fa-spinner fa-spin" aria-hidden="true"
                            v-if="bulkAssign.loading.value"></span>
                        Unassign
                    </Button>
                </template>
            </BulkActionModalDialog>

            <BulkActionModalDialog :visible="bulkAssign.showAssignToMeDialog.value"
                :dialogTitle="`Assign ${formatNumberWithCommas(totalSelectedCandidate)} ${textCandidate} to me`"
                :closeDialog="closeAssignDialog" :selectAll="selectAll" :selectAllCurrentPage="selectAllCurrentPage">
                <template #form-fields>
                    <Textarea v-model="bulkAssign.optionalNote.value" :maxlength="MAX_NOTE_LENGTH"
                        placeholder="Add note" />
                </template>
                <template #footer-button>
                    <Button @click="submitBulkAssign('assigntome')" id="bulk-assign-to-me"
                        :disabled="bulkAssign.loading.value">
                        <span class="fa fa-plus-circle" aria-hidden="true" v-if="!bulkAssign.loading.value"></span>
                        <span class="fa fa-spinner fa-spin" aria-hidden="true"
                            v-if="bulkAssign.loading.value"></span>
                        Assign To Me
                    </Button>
                </template>
            </BulkActionModalDialog>

            <BulkActionModalDialog :visible="bulkLabel.showAddDialog.value"
                :dialogTitle="`Add labels to ${formatNumberWithCommas(totalSelectedCandidate)} ${textCandidate}`"
                :closeDialog="closeLabelDialog" :selectAll="selectAll" :selectAllCurrentPage="selectAllCurrentPage">
                <template #form-fields>
                    <MultiSelect :show-toggle-all="false" placeholder="-- Select Label --" display="chip" required
                        v-model="bulkLabel.add.value" multiple optionLabel="label_name" :options="labelList"
                        class="d-flex my-2" />
                    <Textarea v-model="bulkLabel.optionalNote.value" :maxlength="MAX_NOTE_LENGTH"
                        placeholder="Add note" />
                </template>
                <template #footer-button>
                    <Button @click="submitBulkLabel('add')" id="add-bulk-label" :disabled="bulkLabel.loading.value">
                        <span class="fa fa-plus-circle" aria-hidden="true" v-if="!bulkLabel.loading.value"></span>
                        <span class="fa fa-spinner fa-spin" aria-hidden="true"
                            v-if="bulkLabel.loading.value"></span>
                        Add
                    </Button>
                </template>
            </BulkActionModalDialog>

            <BulkActionModalDialog :visible="bulkLabel.showRemoveDialog.value"
                :dialogTitle="`Remove labels from ${formatNumberWithCommas(totalSelectedCandidate)} ${textCandidate}`"
                :closeDialog="closeLabelDialog" :selectAll="selectAll" :selectAllCurrentPage="selectAllCurrentPage">
                <template #form-fields>
                    <MultiSelect :show-toggle-all="false" placeholder="-- Select Label --" display="chip" required
                        v-model="bulkLabel.remove.value" multiple optionLabel="label_name" :options="labelList"
                        class="d-flex my-2" />
                    <Textarea v-model="bulkLabel.optionalNote.value" :maxlength="MAX_NOTE_LENGTH"
                        placeholder="Add note" />
                </template>
                <template #footer-button>
                    <Button @click="submitBulkLabel('remove')" id="remove-bulk-label" severity="warning"
                        :disabled="bulkLabel.loading.value">
                        <span class="fa fa-trash" aria-hidden="true" v-if="!bulkLabel.loading.value"></span>
                        <span class="fa fa-spinner fa-spin" aria-hidden="true"
                            v-if="bulkLabel.loading.value"></span>
                        Remove
                    </Button>
                </template>
            </BulkActionModalDialog>

            <BulkActionModalDialog :visible="bulkNote.showNoteDialog?.value"
                :dialogTitle="`Add note to ${formatNumberWithCommas(totalSelectedCandidate)} ${textCandidate}`"
                :closeDialog="closeNoteDialog" :selectAll="selectAll" :selectAllCurrentPage="selectAllCurrentPage">
                <template #form-fields>
                    <Textarea v-model="bulkNote.note.value" :maxlength="MAX_NOTE_LENGTH" placeholder="Add note" />
                </template>
                <template #footer-button>
                    <Button @click="submitBulkNote" id="bulk-note" :disabled="bulkNote.loading.value">
                        <span class="fa fa-edit" aria-hidden="true" v-if="!bulkNote.loading.value"></span>
                        <span class="fa fa-spinner fa-spin" aria-hidden="true"
                            v-if="bulkNote.loading.value"></span>
                        Submit
                    </Button>
                </template>
            </BulkActionModalDialog>

            <BulkActionModalDialog :visible="bulkIR.showDeleteIRDialog?.value"
                :dialogTitle="`Delete ${formatNumberWithCommas(totalSelectedCandidate)} report(s)`"
                :closeDialog="closeIRDialog" :selectAll="selectAll" :selectAllCurrentPage="selectAllCurrentPage">
                <template #form-fields>
                    <Message severity="warn" :closable="false" class="my-2">
                        Are you sure you want to delete the {{ formatNumberWithCommas(totalSelectedCandidate) }}
                        selected
                        investigation report(s) ? This action cannot be undone.
                    </Message>
                </template>
                <template #footer-button>
                    <Button @click="submitBulkIR('delete')" :disabled="bulkIR.loading.value" id="delete-ir-action-item">
                        <span class="fa fa-file-excel" aria-hidden="true" v-if="!bulkIR.loading.value"></span>
                        <span class="fa fa-spinner fa-spin" aria-hidden="true" v-if="bulkIR.loading.value"></span>
                        Delete
                    </Button>
                </template>
            </BulkActionModalDialog>

            <BulkActionModalDialog :visible="bulkQueue.showQueueDialog?.value" :dialogTitle="bulkQueue.modalTitle.value"
                :closeDialog="closeQueueDialog" :selectAll="selectAll" :selectAllCurrentPage="selectAllCurrentPage">
                <template #form-fields>

                    <div class="element-container">
                        <label for="queue" class="form-label form-label-required">Move to</label>
                        <Select :scrollHeight="PV_SCROLL_HEIGHT" id="queue" v-model="selectedQueue"
                            :options="filteredQueueOptions" optionLabel="label" optionValue="value"
                            placeholder="-- Select --" class="d-flex" />
                    </div>

                    <CandidateUpdateQueueGeneric v-if="useGenericOptionsForQueueChange(bulkQueue.queue.value)"
                        v-model:optionalNote="bulkQueue.optionalNote.value" />

                    <CandidateUpdateQueueQuestionnaire v-if="bulkQueue.queue.value === 'questionnaire'"
                        v-model:mailingTemplate="bulkQueue.mailingTemplate.value"
                        v-model:optionalNote="bulkQueue.optionalNote.value"
                        :mailingTemplateDropdown="mailingTemplateDropdown" />

                    <CandidateUpdateQueueMonitor v-if="bulkQueue.queue.value === 'monitor'"
                        v-model:reasons="bulkQueue.reason.value" v-model:optionalNote="bulkQueue.optionalNote.value"
                        :reasonsDropdown="reasonsDropdown" />

                    <CandidateUpdateQueueSnooze v-if="bulkQueue.queue.value === 'snooze'"
                        v-model:reasons="bulkQueue.reason.value" v-model:optionalNote="bulkQueue.optionalNote.value"
                        v-model:snoozeUntil="bulkQueue.snoozeUntil.value" :reasonsDropdown="reasonsDropdown"
                        :snoozeDays="snoozeDays" />

                    <CandidateUpdateQueueUnqualified v-if="bulkQueue.queue.value === 'unqualified'"
                        v-model:reasons="bulkQueue.reason.value"
                        v-model:unqualifiedStartYear="bulkQueue.unqualifiedStartYear.value"
                        v-model:mailingTemplate="bulkQueue.mailingTemplate.value"
                        v-model:lienOrBackTaxes="bulkQueue.lienOrBackTaxes.value"
                        v-model:backTaxLienAmount="bulkQueue.backTaxLienAmount.value"
                        v-model:optionalNote="bulkQueue.optionalNote.value"
                        v-model:recoveredAmount="bulkQueue.recoveredAmount.value"
                        :totalCandidateSelected="totalSelectedCandidate"
                        :mailingTemplateDropdown="mailingTemplateDropdown" :reasonsDropdown="reasonsDropdown" />

                    <CandidateUpdateQueueArchive v-if="bulkQueue.queue.value === 'archive'"
                        v-model:finalOutcome="bulkQueue.finalOutcome.value"
                        v-model:unqualifiedStartYear="bulkQueue.unqualifiedStartYear.value"
                        v-model:lienOrBackTaxes="bulkQueue.lienOrBackTaxes.value"
                        v-model:backTaxLienAmount="bulkQueue.backTaxLienAmount.value"
                        v-model:optionalNote="bulkQueue.optionalNote.value"
                        v-model:recoveredAmount="bulkQueue.recoveredAmount.value"
                        :totalCandidateSelected="totalSelectedCandidate" />

                </template>
                <template #footer-button>
                    <Button @click="toggleUpdateQueueAction('confirm')" id="add-bulk-queue"
                        :disabled="bulkQueue.loading.value || bulkQueueUpdateButtonDisabled">
                        <span class="fa fa-plus-circle" aria-hidden="true" v-if="!bulkQueue.loading.value"></span>
                        <span class="fa fa-spinner fa-spin" aria-hidden="true" v-else></span>
                        Update
                    </Button>
                </template>
            </BulkActionModalDialog>
        </div>
    </div>

    <Drawer v-model:visible="listDrawerVisible" position="right" header="Details">
        <CandidateListDrawerContent :tru_id="listDrawerItemID" />
    </Drawer>

    <ModalDialog v-if="showArchiveWarningModal" title="Queue Update Confirmation"
        :close="() => showArchiveWarningModal = false">
        <Message severity="warn" :closable="false" class="my-2">
            Please note that {{ countSelectedArchiveItems }} of the selected candidates are archived.
            <br>
            <br>
            Are you sure you want to proceed with this change?
        </Message>

        <template #footer>
            <Button @click="toggleUpdateQueueAction('submit')" severity="warning"
                :disabled="bulkQueue.loading.value || bulkQueueUpdateButtonDisabled">
                <span class="fa fa-plus-circle" aria-hidden="true" v-if="!bulkQueue.loading.value"></span>
                <span class="fa fa-spinner fa-spin" aria-hidden="true" v-else></span>
                Confirm
            </Button>
            <Button severity="secondary" @click="toggleUpdateQueueAction('close')">Close</Button>
        </template>
    </ModalDialog>
</template>

<script setup lang="ts">
import { computed, ref, onMounted, watch, onUnmounted } from 'vue'
import { useAuth0 } from '@auth0/auth0-vue';
import { useAPI } from "@/helpers/services/api"
import { useProfile } from "@/stores/profile"
import { toast } from "@/helpers/toast"
import {
    RELEASED_IR_GRAPHIC_URL,
    getApiErrorMessage,
    formatDate,
    QueueCode,
    timeDiffForHumans,
    ucfirst,
    validateUserPermission,
    formatNumberWithCommas,
    MAX_NOTE_LENGTH,
    getCurrencyFormatter,
    getQueueText,
    ucwords,
    getPriorityScoreGraphicUrl,
    PV_SCROLL_HEIGHT,
    promonQueueTabs,
    DEFAULT_HOMEPAGE,
    isSnoozeExpired,
    InternalSettings,
    copyTextToClipboard,
    scrollToView,
    toggleCheckboxes
} from "@/helpers/common"
import type {
    DataTableEvent,
    FilterConfig,
    FilterFields,
    FilterSchema,
    QueueTabsCode,
    QueueTabsText
} from "@/helpers/interface/general"
import { FilterMatchMode } from '@primevue/core/api'
import Chip from 'primevue/chip'
import Skeleton from 'primevue/skeleton';
import type { OperatorExclusion, OperatorSubstitution } from "@/helpers/interface/general"
import type { CandidateListNew, Label } from "@/helpers/interface/candidates";
import BasicStrapCards from "@/components/Shared/BasicStrapCards.vue"
import Select from 'primevue/select'
import MultiSelect from 'primevue/multiselect'
import Textarea from 'primevue/textarea';
import Message from 'primevue/message'
import StrapCardHeaderActions from "@/components/Shared/StrapCardHeaderActions.vue"
import Checkbox from 'primevue/checkbox';
import SplitButton from 'primevue/splitbutton'
import Button from 'primevue/button'
import Drawer from 'primevue/drawer'
import CandidateListDrawerContent from "@/components/Candidates/CandidateListDrawerContent.vue"
import StrapCardListDetailsButton from "@/components/Shared/StrapCardListDetailsButton.vue"
import type { Auth0User } from "@/helpers/interface/admin-page";
import { ClaimsFields } from "@/helpers/roles";
import { useAdminDetails } from "@/stores/adminDetails";
import {
    performAssignAction,
    performIRAction,
    performLabelUpdate,
    performNoteAction,
    performQueueAction,
    type BulkIRAction
} from "@/helpers/bulkActions";
import dayjs, { Dayjs } from "dayjs"
import relativeTime from 'dayjs/plugin/relativeTime'
import BulkActionModalDialog from '@/components/Shared/BulkActionModalDialog.vue'
import CandidateUpdateQueueQuestionnaire from '@/components/Candidates/CandidateUpdateQueueQuestionnaire.vue'
import CandidateUpdateQueueMonitor from '@/components/Candidates/CandidateUpdateQueueMonitor.vue'
import CandidateUpdateQueueSnooze from '@/components/Candidates/CandidateUpdateQueueSnooze.vue'
import CandidateUpdateQueueUnqualified from '@/components/Candidates/CandidateUpdateQueueUnqualified.vue'
import CandidateUpdateQueueArchive from '@/components/Candidates/CandidateUpdateQueueArchive.vue'
import CandidateUpdateQueueGeneric from '@/components/Candidates/CandidateUpdateQueueGeneric.vue'
import { useCandidateDetailsStore } from '@/stores/candidateDetails'
import { useTaxroll } from '@/stores/taxroll'
import ModalDialog from '@/components/Shared/ModalDialog.vue'

dayjs.extend(relativeTime)

const props = defineProps({
    queue: {
        type: String,
        required: true
    }
})
const emits = defineEmits(["updateQueue", "qsAllQueue"])
const api = useAPI()
const { user } = useAuth0()
const storeProfile = useProfile()
const storeTaxroll = useTaxroll()
const storeAdminDetails = useAdminDetails()
const storeCandidateDetails = useCandidateDetailsStore()
const formatter = getCurrencyFormatter()
const textCandidate = computed(() => totalSelectedCandidate.value > 1 ? "candidates" : "candidate")
const userId = computed(() => {
    return user.value?.sub || null
})
let snoozeDays = 0;

const strapCardHeaderActionsRef = ref<typeof StrapCardHeaderActions | null>(null)
const loading = ref(true)
watch(() => storeProfile.loadingState, (loadingState: boolean) => {
    loading.value = loadingState
})

const loadingFilterSchema = ref(true)
const filterSchema = ref<FilterSchema[]>([])
const filterConfig = ref<FilterConfig | null>(null)
const first = ref(0)
const rows = ref(25)
const totalRecords = ref(0)
const data = ref<CandidateListNew[]>([])
type BulkActionsAvailable = "UPDATE_QUEUE" | "ASSIGN" | "UNASSIGN" | "ASSIGN_TO_ME" | "ADD_LABEL" | "REMOVE_LABEL" | null
const selectedBulkAction = ref<BulkActionsAvailable>(null)
const userFilters = computed(() => {
    return filters.value.filter(obj => obj.field && obj.type)
})

const listDrawerItemID = ref("")
const listDrawerVisible = ref(false)

const dataParams = ref<DataTableEvent | null>(null)
const dataFiltersInitialState = {
    tru_id: { value: null, matchMode: FilterMatchMode.CONTAINS },
    parcel_num: { value: null, matchMode: FilterMatchMode.CONTAINS },
    applicant: { value: null, matchMode: FilterMatchMode.CONTAINS },
    flags: { value: null, matchMode: FilterMatchMode.IN },
    tax_year: { value: null, matchMode: FilterMatchMode.IN },
    exemptions: { value: null, matchMode: FilterMatchMode.IN },
    submitted_date: { value: null, matchMode: FilterMatchMode.LESS_THAN_OR_EQUAL_TO },
    application_status_recommendation: { value: null, matchMode: FilterMatchMode.IN },
    application_status: { value: null, matchMode: FilterMatchMode.EQUALS },
    assigned_user_id: { value: null, matchMode: FilterMatchMode.EQUALS },
}
const dataFilters = ref({ ...dataFiltersInitialState })
const selectedItems = ref<string[]>([])
const selectAllCurrentPage = ref(false)
const selectAll = ref(false)
const totalSelectedCandidate = computed(() => {
    if (selectAll.value) {
        return totalRecords.value
    }
    if (selectAllCurrentPage.value) {
        return data.value.length
    }
    else {
        return selectedItems.value.length
    }
})


/*** Quick Search ***/
const quickSearchQueryTitle = "Search any combination of name, parcel or address, separated by commas."
const addToQuickSearch = (parcelNum: string) => {
    if (quickSearchQuery.value) quickSearchQuery.value += `, ${parcelNum}`
    else quickSearchQuery.value = parcelNum
}
const quickSearchQuery = ref<string>(storeTaxroll.getQuickSearchQuery || "")
const showQSMessage = computed(() => !loading.value && filteringWithQS.value && quickSearchQuery.value)
const filteringWithQS = ref<boolean>(false)
const enabledQuickSearchAllQueue = ref<boolean>(false)
const qsIncludeArchive = ref<boolean>(false)
const quickSearchAllQueue = async (includeArchive: boolean = false) => {
    qsIncludeArchive.value = includeArchive
    storeTaxroll.setQuickSearchQuery(quickSearchQuery.value)
    emits("qsAllQueue")
    await initializeApp()
}

const getDefaultFilters = () => {
    if (props.queue !== QueueCode.All) {
        return [{ field: "queue", type: "=", value: props.queue }]
    }
    else if (props.queue === QueueCode.All) {
        return [{ field: "queue", type: "not in", value: "archive" }]
    }
    return []
}
const defaultFilters = ref(getDefaultFilters())
let removedDefaultFilter: FilterFields
const initialFilters = [
    {
        field: "",
        type: "",
        value: ""
    },
]

const filters = ref<FilterFields[]>(initialFilters)
const filterSelectedItems = ref<FilterFields[] | null>(null)
const currentPage = ref(1)
const isFilteringDirty = ref(false)
const hasPermissionToReadCandidates = computed(() => validateUserPermission("read", "candidates"))
const hasPermissionForUnreleasedInvestigationQueue = computed(() => validateUserPermission("read", "unreleased_investigation_reports"))
const investigationServicesEnabled = computed(() => (storeProfile.investigationServicesEnabled))
const promonListLayoutQueues = ['inbox', 'monitor', 'unreleased_ir', 'assigned_to_me']

const onBulkNoteClick = () => {
    if (checkFiltersDirty())
        return
    bulkNote.showNoteDialog.value = true
}

const onBulkActionClick = (operation: BulkActionsAvailable) => {
    if (checkFiltersDirty())
        return
    hasArchiveItems()
    selectedBulkAction.value = operation
}

const onBulkIRClick = (action: "delete") => {
    if (checkFiltersDirty())
        return
    if (action === "delete") {
        bulkIR.showDeleteIRDialog.value = true
    }
}

const onFiltersDirtyChanged = (value: boolean) => {
    isFilteringDirty.value = value
}

const checkFiltersDirty = () => {
    if (isFilteringDirty.value) {
        toast.warning("Your filter changes have not been applied.<br>Please review your choices, and press 'Go'.", { "position": "top" })
        return true
    }
    return false
}

const handleRemoveFilters = (emitFilter: any) => {
    filters.value = emitFilter.filters
    if (emitFilter.removedDefaultFilter) {
        removedDefaultFilter = emitFilter.removedDefaultFilter
    }

    if (emitFilter.removeAll) {
        defaultFilters.value = []
    }
    setFilterConfig()
    loadData(null)
}
const handleSubmitFilters = (emitFilter: any) => {
    selectedItems.value = [] // clear all selected items
    filters.value = emitFilter.filters
    qsIncludeArchive.value = false
    if (!emitFilter.includeDefaults) {
        defaultFilters.value = []
    }
    loadData(null, emitFilter.qs)
}

const handleActivateListDrawerClick = (id: string) => {
    listDrawerItemID.value = id
    listDrawerVisible.value = true
}

const operatorSubstitutions: OperatorSubstitution[] = [
    { type: "date", operator: ">=", text: "Is After" },
    { type: "date", operator: "<", text: "Is Before" },
    { field: "parcel_value", operator: ">=", text: "Is Greater Than" },  //per product, omit the "or equal to" part
    { field: "parcel_value", operator: "<=", text: "Is Less Than" },  //per product, omit the "or equal to" part
]

const operatorExclusions: OperatorExclusion[] = [
    { field: "queue", operator: "is null" },
    { field: "prev_queue", operator: "is null" },
]

const onSelectAllChange = (checked: boolean = true) => {
    selectAllCurrentPage.value = checked
    if (selectAll.value) {
        selectAll.value = checked
    }
    selectAllChange()
}

const selectAllChange = () => {
    toggleCheckboxes(".p-dataview-content .p-checkbox input", selectAllCurrentPage.value)
    selectedItems.value = selectAllCurrentPage.value ? data.value.map(data => data.tru_id) : []
}

const queueTabs = ref<string[]>(promonQueueTabs.filter((q) => q.id !== 'monitor').map((q: any) => (q.id)) as [])
queueTabs.value.push("unreleased_ir")
const checkQueueParam = () => {
    if (!queueTabs.value.includes(props.queue)) {
        window.location.href = DEFAULT_HOMEPAGE
    }
}

/*** Bulk Queue ***/
const queueOptions = [
    { label: 'Inbox', value: "inbox" },
    { label: 'Questionnaire', value: "questionnaire" },
    { label: 'Monitor', value: "monitor" },
    { label: 'Unqualified', value: "unqualified" },
    { label: 'Snooze', value: "snooze" },
    { label: 'Archive', value: "archive" },
    { label: 'Investigate Now', value: "investigate_now", hidden: true },
    { label: "Investigation Reports", value: "released_ir", hidden: true },
    { label: "Under Investigation", value: "unreleased_ir", hidden: true }
]
const filteredQueueOptions = computed(() => queueOptions.filter((q: any) => !q.hidden))
watch(() => hasPermissionForUnreleasedInvestigationQueue.value, (show) => displayUnderInvestigationOption(show))
const displayUnderInvestigationOption = (show: boolean) => {
    const options = ["unreleased_ir", "released_ir"]
    queueOptions.map((option: any) => {
        if (options.includes(option.value)) {
            option.hidden = (!show) ? true : false
        }
    })
}

watch(() => investigationServicesEnabled.value, (show) => displayInvestigationQueueOptions(show))
const displayInvestigationQueueOptions = (show: boolean | undefined) => {
    const underInvestigationOption = ["investigate_now"]
    queueOptions.map((option: any) => {
        if (underInvestigationOption.includes(option.value)) {
            option.hidden = (!show) ? true : false
        }
    })
}

const queuesWithReasons = ["monitor", "unqualified", "snooze"]
const selectedQueue = ref<QueueTabsCode | null>(null)
watch(() => selectedQueue.value, async (queue: any) => {
    if (!queuesWithReasons.includes(queue) || !queue) return
    await storeCandidateDetails.fetchReasonsList(loadFailureHandler, queue)
})
const mailingTemplateDropdown = computed(() => storeCandidateDetails.getMailTemplateList)
const reasonsDropdown = computed(() => storeCandidateDetails.getReasonsList)
const bulkQueue = {
    loading: ref(false),
    showQueueDialog: computed(() => selectedBulkAction.value === "UPDATE_QUEUE"),
    optionalNote: ref(""),
    prevQueue: ref<string>(props.queue),
    queue: computed(() => selectedQueue.value),
    mailingTemplate: ref<number | null>(null),
    snoozeUntil: ref<string>(""),
    reason: ref<string>(""),
    unqualifiedStartYear: ref<string | null>(null),
    lienOrBackTaxes: ref<string | null>(null),
    backTaxLienAmount: ref<number>(0),
    finalOutcome: ref<string>(""),
    recoveredAmount: ref<number>(0),
    modalTitle: computed((): string => {
        let title = `Update ${formatNumberWithCommas(totalSelectedCandidate.value)} ${textCandidate.value} to`
        title += (bulkQueue.queue.value) ? ` ${getQueueText(bulkQueue.queue.value)}` : "..."
        return title
    }),
    parentURL: "/taxroll"
}
const bulkQueueUpdateButtonDisabled = computed(() => {
    const {
        queue,
        mailingTemplate,
        reason,
        snoozeUntil,
        unqualifiedStartYear,
        lienOrBackTaxes,
        finalOutcome,
        recoveredAmount,
        optionalNote
    } = bulkQueue

    if (!queue.value) return true

    const requireOptionalNote = reason.value.toLowerCase() === 'other'
    const isSingleApplication = totalSelectedCandidate.value === 1

    const hasRequiredFieldsForUnqualified = (includeRecoveredAmount = false) => {
        return unqualifiedStartYear.value && lienOrBackTaxes.value && reason.value &&
            (!requireOptionalNote || optionalNote.value) &&
            (!includeRecoveredAmount || recoveredAmount.value)
    }

    const hasRequiredFieldsForArchive = (includeRecoveredAmount = false) => {
        return unqualifiedStartYear.value && lienOrBackTaxes.value &&
            (!includeRecoveredAmount || recoveredAmount.value)
    }

    const archiveConditions = (): boolean => {
        return finalOutcome.value === 'exemption removed'
            ? !hasRequiredFieldsForArchive(isSingleApplication) || !finalOutcome.value
            : !finalOutcome.value
    }

    const unqualifiedConditions = (): boolean => {
        return !hasRequiredFieldsForUnqualified(isSingleApplication)
    }

    const conditions: Partial<Record<QueueTabsCode | QueueTabsText, boolean>> = {
        questionnaire: !mailingTemplate.value,
        monitor: (requireOptionalNote) ? !reason.value || !optionalNote.value : !reason.value,
        snooze: (requireOptionalNote) ? !snoozeUntil.value || !reason.value || !optionalNote.value : !snoozeUntil.value || !reason.value,
        unqualified: unqualifiedConditions(),
        archive: archiveConditions()
    }
    return conditions[queue.value] ?? false
})

const useGenericOptionsForQueueChange = (queue: string | null) => {
    if (!queue)
        return false
    const possibleQueues = [
        QueueCode.Inbox.toString(),
        QueueCode.InvestigateNow.toString(),
        QueueCode.InvestigationReports.toString(),
        QueueCode.UnderInvestigation.toString()
    ]
    return possibleQueues.includes(queue)
}

const fetchMailingTemplateList = async () => {
    await storeCandidateDetails.fetchMailTemplateList(loadFailureHandler)
}
const submitBulkQueue = async () => {
    const additionalParam = (selectAll.value ? {
        filters: getFilterList()
    } : {
        tru_ids: selectedItems.value
    })
    const result = await performQueueAction(api, bulkQueue, additionalParam)
    if (result === "success") {
        if (props.queue !== "all") clearAllSelectedItems(false)
        loadData(null, filteringWithQS.value)
        emits("updateQueue", {
            prevQueue: bulkQueue.prevQueue.value,
            queue: bulkQueue.queue.value,
        })
        resetToInitialState()
        closeQueueDialog()
    }
    bulkQueue.loading.value = false
}
const closeQueueDialog = () => {
    selectedBulkAction.value = null
    selectedQueue.value = null
    bulkQueue.optionalNote.value = ""
    bulkQueue.snoozeUntil.value = ""
    bulkQueue.reason.value = ""
    bulkQueue.mailingTemplate.value = null
    bulkQueue.unqualifiedStartYear.value = null
    bulkQueue.lienOrBackTaxes.value = null
    bulkQueue.backTaxLienAmount.value = 0
    bulkQueue.finalOutcome.value = ""
    bulkQueue.recoveredAmount.value = 0
    showArchiveWarningModal.value = false
}

const updatedOn = (candidate: CandidateListNew): string | null => {
    const updatedAt = dayjs(candidate.updated_at)
    const parcelUpdated = dayjs(candidate.parcel_updated)
    let updatedOn: Dayjs | null = null

    if (!updatedAt.isValid() && !parcelUpdated.isValid()) return updatedOn
    else if (!updatedAt.isValid()) updatedOn = parcelUpdated
    else if (!parcelUpdated.isValid()) updatedOn = updatedAt
    else updatedOn = (updatedAt.isAfter(parcelUpdated) ? updatedAt : parcelUpdated)

    return formatDate(updatedOn.toISOString())
}

const lastQueueChange = (date: string, queue: string) => {
    const dayjsDate = dayjs(date)
    if (!dayjsDate.isValid()) return null
    // e.g. 2 months in Inbox / A year in Inbox
    return `${ucfirst(dayjsDate.fromNow(true))} in ${ucwords(getQueueText(queue))}`
}

/*** Bulk Assign ***/
const assignActionItems = [
    { label: 'Assign', command: () => onBulkActionClick("ASSIGN") },
    { label: 'Unassign', command: () => onBulkActionClick("UNASSIGN") },
    {
        label: 'Assign to me',
        command: () => onBulkActionClick("ASSIGN_TO_ME"),
        disabled: !(user.value?.[ClaimsFields['app_metadata']]?.is_client)
    },
]
const bulkAssign = {
    sub: user.value?.sub,
    user: ref<Auth0User | null>(null),
    optionalNote: ref(""),
    loading: ref(false),
    showAssignDialog: computed(() => selectedBulkAction.value === "ASSIGN"),
    showAssignToMeDialog: computed(() => selectedBulkAction.value === "ASSIGN_TO_ME"),
    showUnassignDialog: computed(() => selectedBulkAction.value === "UNASSIGN"),
    parentURL: "/taxroll"
}
const userList = computed(() => storeAdminDetails.getUsersList)
const fetchUsersList = async () => {
    await storeAdminDetails.fetchUsersList(loadFailureHandler)
}
const submitBulkAssign = async (action: "assign" | "unassign" | "assigntome") => {
    const additionalParam = (selectAll.value ? {
        filters: getFilterList()
    } : {
        tru_ids: selectedItems.value
    })
    const result = await performAssignAction(api, action, bulkAssign, additionalParam)
    if (result === "success") {
        loadData(null, filteringWithQS.value)
        resetToInitialState()
        closeAssignDialog()
    }
    bulkAssign.loading.value = false
}
const closeAssignDialog = () => {
    selectedBulkAction.value = null
    bulkAssign.user.value = null
    bulkAssign.optionalNote.value = ""
}

/*** Bulk Label ***/
const labelActionItems = [
    { label: 'Add', command: () => onBulkActionClick("ADD_LABEL") },
    { label: 'Remove', command: () => onBulkActionClick("REMOVE_LABEL") },
]
const bulkLabel = {
    add: ref<Label[]>([]),
    remove: ref<Label[]>([]),
    optionalNote: ref(""),
    loading: ref(false),
    showAddDialog: computed(() => selectedBulkAction.value === "ADD_LABEL"),
    showRemoveDialog: computed(() => selectedBulkAction.value === "REMOVE_LABEL"),
    parentURL: "/taxroll"
}
const labelList = ref<Label[]>([])
const submitBulkLabel = async (action: "add" | "remove") => {
    const additionalParam = (selectAll.value ? {
        filters: getFilterList()
    } : {
        tru_ids: selectedItems.value
    })
    const result = await performLabelUpdate(api, action, bulkLabel, additionalParam)
    if (result === "success") {
        loadData(null, filteringWithQS.value)
        resetToInitialState()
        closeLabelDialog()
    }
    bulkLabel.loading.value = false
}
const closeLabelDialog = () => {
    selectedBulkAction.value = null
    bulkLabel.add.value = []
    bulkLabel.remove.value = []
    bulkLabel.optionalNote.value = ""
}

/*** Bulk Note ***/
const bulkNote = {
    note: ref(""),
    loading: ref(false),
    showNoteDialog: ref(false),
    parentURL: "/taxroll"
}
const submitBulkNote = async () => {
    const additionalParam = (selectAll.value ? {
        filters: getFilterList()
    } : {
        tru_ids: selectedItems.value
    })
    const result = await performNoteAction(api, bulkNote, additionalParam)
    if (result === "success") {
        loadData(null, filteringWithQS.value)
        resetToInitialState()
        closeNoteDialog()
    }
    bulkNote.loading.value = false
}
const closeNoteDialog = () => {
    selectedBulkAction.value = null
    bulkNote.note.value = ""
    bulkNote.showNoteDialog.value = false
}

/*** Release IR ***/
const bulkIR = {
    action: ref<BulkIRAction>(null),
    loading: ref(false),
    showDeleteIRDialog: ref(false),
    parentURL: "/taxroll"
}
const submitBulkIR = async (action: BulkIRAction) => {
    const additionalParam = (selectAll.value ? {
        filters: getFilterList()
    } : {
        tru_ids: selectedItems.value
    })
    bulkIR.action.value = action
    const result = await performIRAction(api, bulkIR, additionalParam)
    if (result === "success") {
        loadData(null, filteringWithQS.value)
        emits("updateQueue", {
            prevQueue: props.queue,
            queue: "inbox",
        })
        resetToInitialState()
        closeIRDialog()
    }
    bulkIR.loading.value = false
}
const closeIRDialog = () => {
    selectedBulkAction.value = null
    bulkIR.showDeleteIRDialog.value = false
}

// Check if there are any 'archive' items in the selected items
const showArchiveWarningModal = ref(false)
const toggleUpdateQueueAction = (action: "confirm" | "submit" | "close") => {
    if (action === "confirm") {
        if (countSelectedArchiveItems.value > 0) {
            showArchiveWarningModal.value = true
            selectedBulkAction.value = null
        }
        else submitBulkQueue()
        return
    }

    if (action === "submit") {
        submitBulkQueue()
        return
    }

    if (action === "close") {
        selectedBulkAction.value = "UPDATE_QUEUE"
        showArchiveWarningModal.value = false
        return
    }
}
const countSelectedArchiveItems = ref(0)
const hasArchiveItems = async () => {
    const filter = [
        {
            field: "tru_id",
            type: "in",
            value: selectedItems.value
        },
        { field: "queue", type: "=", value: "archive" }
    ]
    try {
        const response = await api.post("/taxroll/count", filter)
        countSelectedArchiveItems.value = response.data.count || 0
    }
    catch (error: any) {
        toast.error(getApiErrorMessage(error))
    }
}

const initFilters = () => {
    dataParams.value = {
        first: 0,
        rows: 25,
        page: 1,
        sortField: null,
        sortOrder: null,
        filters: dataFilters.value
    }
    dataFilters.value = dataFiltersInitialState
}

const clearSelection = () => {
    selectAllCurrentPage.value = false
    selectAll.value = false
    selectedBulkAction.value = null
    selectAllChange()
}

const resetToInitialState = () => {
    // Check if the selected bulk action is not "UPDATE_QUEUE"
    if (selectedBulkAction.value !== "UPDATE_QUEUE" && !showArchiveWarningModal.value) {
        // If either filtering is applied or there are user filters
        if (filteringWithQS.value || userFilters.value.length) {

            // If the queue is "all", no need to reset
            if (props.queue === "all") return

            // Reset selected items and clear selection
            selectedItems.value = []
            clearSelection()
            return
        }
    }
    else {
        // If the queue is "all", no need to reset
        if (props.queue === "all") return
        // Reset selected items and clear selection for specific queue
        selectedItems.value = []
        clearSelection()
    }
}

const toggleShowAllSelectedItems = ref(false)
const showAllSelectedItems = () => {
    toggleShowAllSelectedItems.value = true
    filterSelectedItems.value = [
        {
            field: "tru_id",
            type: "in",
            value: selectedItems.value
        }
    ]
    loadData()
    toggleCheckboxes(".p-dataview-content .p-checkbox input", true)
}

const clearAllSelectedItems = (refreshList: boolean = true) => {
    toggleShowAllSelectedItems.value = false
    selectedItems.value = []
    filterSelectedItems.value = []
    if (refreshList) loadData()
    toggleCheckboxes(".p-dataview-content .p-checkbox input")
}

// For Dynamic ScrollHeight Calculation
const candidateDataView = ref<HTMLElement | null>(null)

// Sort
const sortOrder = ref<"desc" | "asc">("desc")
const defaultSortOrder = (): "desc" | "asc" => {
    const ascQueues = ["questionnaire", "unqualified", "snooze"];
    const defaultSortOrder = ascQueues.includes(props.queue) ? "asc" : "desc";
    return defaultSortOrder
}
const changeSortOrder = () => {
    sortOrder.value = sortOrder.value === "asc" ? "desc" : "asc"
    loadData()
}

const getListPayload = (event: any) => {
    return {
        "filter": getFilterList(),
        "page": event?.page,
        "page_size": event?.rows,
        "sort": getSortList(),
        "include_total": false
    }
}

const getFilterList = () => {
    let output: FilterFields[] = []

    // Filtering using quick search
    if (filteringWithQS.value && quickSearchQuery.value) {
        const qsQuery = {
            field: "quick_search",
            type: "=",
            value: quickSearchQuery.value
        }
        output.push(qsQuery)
    }

    // Filtering using filter options/section
    else {
        if (toggleShowAllSelectedItems.value && filterSelectedItems.value?.length) {
            output = filterSelectedItems.value
        }
        else output = [...userFilters.value]
    }

    // Remove any default filter the user requested to be removed
    if (removedDefaultFilter) {
        defaultFilters.value = defaultFilters.value.filter(o =>
            !(o.field === removedDefaultFilter.field &&
                o.type === removedDefaultFilter.type &&
                o.value === removedDefaultFilter.value)
        )
    }

    // Add default filters if QS is not enabled
    if (!enabledQuickSearchAllQueue.value) {
        if (defaultFilters?.value.length) {
            defaultFilters.value.forEach(defaultFilter => {
                // Check if the default filter already exists
                const exists = output.some(item => item.field === defaultFilter.field && item.type === defaultFilter.type);
                // If it doesn't exist, push the filter to the output
                if (!exists) {
                    output.push(defaultFilter);
                }
            });
        }
    }

    const userAssignedFilter: FilterFields = { field: "assigned_to", type: "in", value: [userId.value] }

    if (userId.value) {
        const isAssignedToPresent = output.some((x: FilterFields) => x.field === "assigned_to")

        if (props.queue === "assigned_to_me") {
            return [userAssignedFilter]
        }

        if (!isAssignedToPresent && !hasPermissionToReadCandidates.value && props.queue !== QueueCode.All) {
            output.push(userAssignedFilter)
        }
    }

    if (filteringWithQS.value && qsIncludeArchive.value) {
        // Promon
        // QS: When filtering all queues, including archived items, remove the filter that blocks the archive
        output = output.filter((o) => (!(o.field === "queue" && o.type === "not in" && o.value === "archive")))
    }

    return output
}

const sortTitles = ref({
    asc: "Last Queue Change: Oldest First",
    desc: "Last Queue Change: Newest First",
})
const getSortList = () => {
    let fieldName = "last_queue_change";
    let customSortOrder = sortOrder.value
    switch (props.queue) {
        case QueueCode.Inbox:
        case QueueCode.AssignedToMe:
            fieldName = "priority_score";
            sortTitles.value = {
                asc: "Priority Score: Low to High",
                desc: "Priority Score: High to Low",
            }
            break;
        case QueueCode.Snooze:
            fieldName = "snooze_until";
            sortTitles.value = {
                asc: "Snooze Until: Earliest First",
                desc: "Snooze Until: Latest First",
            }
            customSortOrder = defaultSortOrder() === sortOrder.value ? "desc" : "asc"
            break;
    }

    return [{ "field": fieldName, "dir": customSortOrder }]
}

const loadData = async (event: any = null, qs: boolean = false) => {
    loading.value = true
    dataParams.value = { ...dataParams.value, page: event?.page || 1, rows: event?.rows || 25 }

    filteringWithQS.value = qs
    const listPayload = getListPayload(dataParams.value)
    const requests = []

    const listRequest = api.post("/taxroll/list", listPayload)?.then(response => {
        data.value = response.data?.data || []
    })?.catch(error => {
        data.value = []
        toast.error(getApiErrorMessage(error))
    })
    requests.push(listRequest)

    const countPayload = listPayload.filter
    const countRequest = api.post("/taxroll/count", countPayload)?.then(response => {
        totalRecords.value = response.data.count || 0
    })?.catch(error => {
        toast.error(getApiErrorMessage(error))
    })
    requests.push(countRequest)

    await Promise.all(requests)

    let sidebarContent = document.querySelector("#sharedSideNav .sidebar-content") as HTMLDivElement
    if (sidebarContent) {
        sidebarContent.style.marginBottom = totalRecords.value === 0 ? "60px" : "0px"
    }
    loading.value = false
}

const fetchLabelsDropdownList = async () => {
    try {
        const response = await api.get('/labels/?candidates=true');
        const items = response.data ? response.data : []
        labelList.value = items as Label[]
    }
    catch (error: any) {
        labelList.value = [];
        toast.error(getApiErrorMessage(error))
    }
}

const loadFailureHandler = (error: any) => {
    const message = getApiErrorMessage(error, { "featureName": "Report" })
    toast.error(message)
}

const onPage = async (event: DataTableEvent) => {
    if (isFilteringDirty.value) {
        toast.warning("Your current filter settings are unsaved. <br> To see updates, please apply them by pressing 'Apply,' as changes may not be reflected on this page.", { "position": "top", "duration": 5000 })
        return
    }
    event.page += 1
    dataParams.value = event
    currentPage.value = event.page
    rows.value = event.rows as number
    await loadData(event, filteringWithQS.value)
    if (selectAll.value) selectAllChange()
    scrollToView("row_0")
}

const setFilterConfig = () => {
    const fieldExclusions = props.queue === QueueCode.All ? [] : ["queue"]
    filterConfig.value = {
        activeFilters: filters.value,
        currentFilteredFields: userFilters.value.length,
        showDefaultFilters: (props.queue === "all" ? true : false),
        defaultFilters: defaultFilters.value,
        filterSchema: filterSchema.value,
        operatorSubstitutions: operatorSubstitutions,
        operatorExclusions: operatorExclusions,
        fieldExclusions: fieldExclusions
    }
}

const fetchFilterSchema = async () => {
    loadingFilterSchema.value = true
    try {
        const response = await api.get('/taxroll/filters')
        filterSchema.value = response.data as FilterSchema[]
        //setFilterConfig()
    }
    catch (error: any) {
        filterSchema.value = [];
        toast.error(getApiErrorMessage(error))
    }
    loadingFilterSchema.value = false
}

// Filter overlay (popup)
const overlayStyle = ref({})
const filterButton = ref<HTMLElement | null>(null)

const setFilterDivPosition = () => {
    if (filterButton.value && candidateDataView.value) {
        const filterButtonRect = filterButton.value.getBoundingClientRect()
        const candidateDataViewRect = candidateDataView.value.getBoundingClientRect()
        overlayStyle.value = {
            position: 'absolute',
            top: `${filterButtonRect.bottom + 15}px`,
            right: `${candidateDataViewRect.right - filterButtonRect.right + 10}px`
        }
    }
}

const promonListLayout = ref()
const getDefaultSnoozeDays = async () => snoozeDays = await storeAdminDetails.getDefaultSnoozeDays(InternalSettings.promon_default_snooze_days)
const fetchInternalSettings = async () => {
    getDefaultSnoozeDays()
    if (!promonListLayoutQueues.includes(props.queue)) return
    promonListLayout.value = await storeAdminDetails.fetchInternalSettingsItem(InternalSettings.promon_list_layout, loadFailureHandler)
    if (promonListLayout.value?.value) {
        promonListLayout.value = promonListLayout.value.value[0]?.columns[0]?.fields[0]?.field_name || "priority_score"
    }
    else promonListLayout.value = "priority_score"
}

watch(
    [filters, filterSchema],
    () => { setFilterConfig() },
    { deep: true }
)

const initializeApp = async () => {
    checkQueueParam()
    setFilterDivPosition()
    initFilters()
    fetchFilterSchema()
    fetchInternalSettings()
    await loadData(null, !!quickSearchQuery.value)
    displayUnderInvestigationOption(hasPermissionForUnreleasedInvestigationQueue.value)
    displayInvestigationQueueOptions(investigationServicesEnabled.value)
    fetchLabelsDropdownList()
    fetchUsersList()
    fetchMailingTemplateList()
}

onMounted(async () => {
    await initializeApp()
})

onUnmounted(() => {
    quickSearchQuery.value = ""
    enabledQuickSearchAllQueue.value = false
    filteringWithQS.value = false
    qsIncludeArchive.value = false
    storeTaxroll.setQuickSearchQuery("")
})

</script>

<style>
@import "@/assets/appvet.css";
</style>
