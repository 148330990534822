<template>
    <div ref="overlayDiv">
        <div v-if="!props.loading" class="d-flex">
            <InputGroup id="quickSearch" class="ms-2" v-if="props.enableQuickSearch">
                <InputText v-model="quickSearchQuery" @keydown.enter="handleQuickSearch"
                    v-tooltip.left="quickSearchQueryTitle" placeholder="Quick Search" class="d-flex" :disabled="toggleShowAllSelectedItems" />
                <span v-if="quickSearchQuery" v-tooltip.bottom="'Clear'" class="fa fa-times clear-icon"
                    @click="() => clearQSFilters()"></span>
                <Button severity="secondary" id="qsBtn" @click="handleQuickSearch" v-tooltip.bottom="'Quick Search'" :disabled="toggleShowAllSelectedItems">
                    <span class="fa fa-search"></span>
                </Button>
            </InputGroup>
            <div class="ms-3 text-icon-container">
                <a href="javascript:;" v-if="props.sortOrder === 'desc'" @click="changeSortOrder()" id="sort-asc-button"
                    class="text-icon-link" v-tooltip.bottom="props.sortTitles.asc">
                    <span class="fa fa-sort-amount-down-alt"></span> Sort
                </a>
                <a href="javascript:;" v-else @click="changeSortOrder()" id="sort-desc-button" class="text-icon-link"
                    v-tooltip.bottom="props.sortTitles.desc">
                    <span class="fa fa-sort-amount-up"></span> Sort
                </a>
            </div>
            <div class="ms-2 text-icon-container">
                <div class="fa-custom-stack-container">
                    <a href="javascript:;" @click="toggleOverlay" id="filter-button" ref="filterButton"
                        class="text-icon-link" :class="{ 'disabled': !props.filterConfig || toggleShowAllSelectedItems }" v-tooltip.bottom="'Filter'">
                        <span class="fa fa-filter"></span> Filter
                        <Badge v-if="activeFilteredCount > 0" :value="activeFilteredCount" severity="success"
                            class="p-badge-circle p-badge-xs"></Badge>
                    </a>
                </div>

                <a href="javascript:;" v-if="activeFilteredCount > 0" @click="showConfirmClearAllFilterModal = true"
                    class="ms-2 text-icon-link" v-tooltip.bottom="'Clear All Filters'" :class="{ 'disabled': toggleShowAllSelectedItems }">
                    <span class="fa fa-times"></span> Clear All
                </a>

                <PopoverOverlay :isFilterPopoverVisible="isFilterPopoverVisible" :style="overlayStyle"
                    v-if="props.filterConfig">
                    <template #content>
                        <FilterComponent :defaultFilters="props.filterConfig.defaultFilters"
                            :activeFilters="props.filterConfig.activeFilters" @submitFilters="handleSubmitFilters"
                            @removeFilters="handleRemoveFilters" @isDirty="onFiltersDirtyChanged"
                            :filterSchema="props.filterConfig.filterSchema"
                            :operatorSubstitutions="props.filterConfig.operatorSubstitutions"
                            :operatorExclusions="props.filterConfig.operatorExclusions"
                            :showDefaultFilters="props.filterConfig.showDefaultFilters"
                            :fieldExclusions="props.filterConfig.fieldExclusions" ref="filterComponentRef" />
                    </template>
                </PopoverOverlay>
            </div>

        </div>
        <div v-else class="d-inline-flex float-end">
            <Skeleton width="25rem" height="2rem" class="me-2" v-if="props.enableQuickSearch"></Skeleton>
            <Skeleton width="4rem" height="2rem" class="me-2"></Skeleton>
            <Skeleton width="4rem" height="2rem" class="me-2"></Skeleton>
        </div>
    </div>

    <ModalDialog v-if="showConfirmClearAllFilterModal" title="Confirm Clear Filters"
        :close="() => showConfirmClearAllFilterModal = false">
        <Message severity="warn" :closable="false" class="my-2">
            Are you sure you want to clear the filters?
            <br>
            <br>
            This will remove all current filters and input, and cannot be undone.
        </Message>

        <template #footer>
            <Button @click="clearAllFilters()" severity="warning">Confirm</Button>
            <Button severity="secondary" @click="showConfirmClearAllFilterModal = false">Close</Button>
        </template>
    </ModalDialog>

</template>

<script setup lang="ts">
import { onBeforeUnmount, onMounted, ref, computed, type PropType } from 'vue'
import type { FilterConfig, FilterFields } from "@/helpers/interface/general"
import FilterComponent from "@/components/Shared/FilterComponent.vue"
import PopoverOverlay from "@/components/Shared/PopoverOverlay.vue"
import ModalDialog from '@/components/Shared/ModalDialog.vue'
import Skeleton from 'primevue/skeleton'
import InputGroup from 'primevue/inputgroup'
import InputText from 'primevue/inputtext'
import Button from 'primevue/button'
import Badge from 'primevue/badge'
import Message from 'primevue/message'

const props = defineProps({
    enableQuickSearch: {
        type: Boolean,
        required: false,
        default: true,
    },
    quickSearchQueryTitle: {
        type: String,
        required: false,
        default: "",
    },
    loading: {
        type: Boolean,
        required: true
    },
    sortOrder: {
        type: String,
        required: true
    },
    filterConfig: {
        type: Object as PropType<FilterConfig | null>,
        required: false
    },
    parentDataView: {
        type: null,
        required: true
    },
    sortTitles: {
        type: Object as PropType<{ asc: string, desc: string }>,
        required: false,
        default: () => ({
            asc: "Sort Oldest First",
            desc: "Sort Newest First"
        })
    },
    toggleShowAllSelectedItems: {
        type: Boolean,
        default: false
    }
})

const quickSearchQuery = defineModel<string | null>("quickSearchQuery")
const filters = defineModel<FilterFields[]>("filters")
const emits = defineEmits(["changeSortOrder", "submitFilters", "removeFilters", "onFiltersDirtyChanged"])
const changeSortOrder = () => emits("changeSortOrder", props.sortOrder)
const activeFilteredCount = computed(() => {
    const currentFilteredFields = props.filterConfig?.currentFilteredFields || 0
    const defaultFilters = props.filterConfig?.defaultFilters?.length || 0
    if (props.filterConfig?.showDefaultFilters) {
        return currentFilteredFields + defaultFilters
    }
    return currentFilteredFields
})



// Filter overlay (popup)
const filterComponentRef = ref<typeof FilterComponent | null>(null)
const isFilterPopoverVisible = ref(false)
const overlayStyle = ref({})
const overlayDiv = ref<HTMLElement | null>(null)
const filterButton = ref<HTMLElement | null>(null)
const toggleOverlay = () => {
    isFilterPopoverVisible.value = !isFilterPopoverVisible.value
    if (isFilterPopoverVisible.value) {
        setFilterDivPosition()
    }
}

let emitFilter = {
    filters: filters.value,
    qs: false,
    includeDefaults: true
}

const updateEmitFilter = (newFilters: FilterFields[] | undefined, qs: boolean = false, includeDefaults: boolean = true) => {
    return {
        filters: newFilters,
        qs: qs,
        includeDefaults: includeDefaults
    }
}

const handleQuickSearch = () => {
    // Note: Store File Taxroll will handle the value of Quick search query
    emitFilter = updateEmitFilter(filters.value, true)
    emits("submitFilters", emitFilter)
}

const handleSubmitFilters = (newFilters: any) => {
    emitFilter = updateEmitFilter(newFilters)
    emits("submitFilters", emitFilter)
    isFilterPopoverVisible.value = false
}

const handleRemoveFilters = (emitFilter: any) => {
    if (emitFilter.removeAll) {
        filterComponentRef.value?.clearFilters()
    }
    isFilterPopoverVisible.value = false
    emits("removeFilters", emitFilter)
}
const onFiltersDirtyChanged = (value: any) => emits("onFiltersDirtyChanged", value)
const setFilterDivPosition = () => {
    if (filterButton.value && props.parentDataView) {
        const filterButtonRect = filterButton.value.getBoundingClientRect()
        const appVetDataViewRect = props.parentDataView.getBoundingClientRect()
        overlayStyle.value = {
            position: 'absolute',
            top: `${filterButtonRect.bottom + 15}px`,
            right: `${appVetDataViewRect.right - filterButtonRect.right + 10}px`
        }
    }
}

const handleClickOutside = (event: MouseEvent) => {
    if (
        overlayDiv.value &&
        !overlayDiv.value.contains(event.target as Node) &&
        !document.querySelector(".p-select-list-container")?.contains(event.target as Node) &&
        !document.querySelector(".p-select-overlay")?.contains(event.target as Node) &&
        !document.querySelector(".p-multiselect-overlay")?.contains(event.target as Node) &&
        !document.querySelector(".p-datepicker-panel")?.contains(event.target as Node)
    ) {

        // This is for the last index of the filter fields
        if (!document.contains(event.target as Node)) return

        // 
        isFilterPopoverVisible.value = false
    }
}

const clearQSFilters = () => {
    quickSearchQuery.value = ""
    handleQuickSearch()
}

const clearFilters = () => {
    filterComponentRef.value?.clearFilters()
}

const showConfirmClearAllFilterModal = ref(false)
const clearAllFilters = () => {
    showConfirmClearAllFilterModal.value = false
    clearFilters()
    // Clear all filters, including default filters, only if they are displayed in the filter section
    const clearIncludingDefaultFilters = !props.filterConfig?.showDefaultFilters
    emitFilter = updateEmitFilter([], false, clearIncludingDefaultFilters)
    emits("submitFilters", emitFilter)
    emits("onFiltersDirtyChanged", false)
    isFilterPopoverVisible.value = false
}

defineExpose({
    clearFilters
})

onMounted(() => {
    setFilterDivPosition()
    document.addEventListener('click', handleClickOutside)
})


onBeforeUnmount(() => {
    document.removeEventListener('click', handleClickOutside)
})

</script>