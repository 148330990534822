<template>
    <div class="element-container">
        <label for="addNote" class="form-label">Add Note</label>
        <Textarea id="addNote" v-model="optionalNote" :maxlength="MAX_NOTE_LENGTH" />
    </div>
</template>

<script setup lang="ts">
import Textarea from 'primevue/textarea';
import { MAX_NOTE_LENGTH } from '@/helpers/common';
const optionalNote = defineModel<string | null>("optionalNote")
</script>
