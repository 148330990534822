<template>
    <div>
        <div class="row">
            <div class="col-lg-6">
                <h3 class="config-header">Config: Internal Settings</h3>

                <div v-if="!fetchInternalSettingsLoading">

                    <b>General</b>
                    <form class="my-3">
                        <div class="card">
                            <div class="card-body">
                                <div class="row pt-2">
                                    <div class="col-12">
                                        <label for="betaFeature">Beta Features (Customer Level)</label>
                                        <br>
                                        <RadioButton v-model="enableBetaFeatures" class="mx-1 mt-2"
                                            inputId="betaFeatureEnable" :value="true" @change="updateBetaFeatures" />
                                        <label for="betaFeatureEnable" class="me-1">Enabled</label>

                                        <RadioButton v-model="enableBetaFeatures" class="mx-1 mt-2"
                                            inputId="betaFeatureDisable" :value="false" @change="updateBetaFeatures" />
                                        <label for="betaFeatureDisable">Disabled</label>

                                        <loading-icon class="ms-2 d-inline-flex"
                                            v-if="isSavingItem('enable_beta_features')" />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>

                    <!-- ######################################################## -->
                    <!--  PROMON -->

                    <b>Proactive Monitoring</b>

                    <form class="my-3">
                        <div class="card">
                            <div class="card-body">
                                <div class="row pt-2">
                                    <div class="col-12">
                                        <label>Availability</label>
                                        <br>
                                        <RadioButton v-model="enableProMon" class="mx-1 mt-2" inputId="proMonEnable"
                                            :value="true" @change="updateProMon" />
                                        <label for="proMonEnable" class="me-1">Enabled</label>

                                        <RadioButton v-model="enableProMon" class="mx-1 mt-2" inputId="proMonDisable"
                                            :value="false" @change="updateProMon" />
                                        <label for="proMonDisable">Disabled</label>

                                        <loading-icon class="ms-2 d-inline-flex"
                                            v-if="isSavingItem(InternalSettings.proactive_monitoring)" />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>

                    <div v-if="enableProMon" class="promon-enabled-settings-container">
                        <form class="my-3">
                            <div class="card">
                                <div class="card-body">
                                    <div class="row pt-2">
                                        <div class="col-12">
                                            <label for="prioritizationModel">Prioritization Model</label>
                                            <br>
                                            <RadioButton v-model="prioritizationModel" class="mx-1 mt-2"
                                                inputId="prioritizationModelAdditive" value="additive"
                                                @change="updatePrioritizationModel" />
                                            <label for="prioritizationModelAdditive" class="me-1">Additive</label>

                                            <RadioButton v-model="prioritizationModel" class="mx-1 mt-2"
                                                inputId="prioritizationModelWeighted" value="weighted"
                                                @change="updatePrioritizationModel" />
                                            <label for="prioritizationModelWeighted">Weighted</label>

                                            <loading-icon class="ms-2 d-inline-flex"
                                                v-if="isSavingItem('prioritization_model')" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>

                        <form class="my-3">
                            <div class="card">
                                <div class="card-body">
                                    <div class="row pt-2">
                                        <div class="col-12">
                                            <label for="promonListLayout">List Layout</label>
                                            <br>
                                            <RadioButton v-model="promonListLayout" class="mx-1 mt-2"
                                                inputId="promonListLayoutPS" :value="'priority_score'"
                                                @change="updatePromonListLayout" />
                                            <label for="promonListLayoutPS" class="me-1">Priority Score</label>

                                            <RadioButton v-model="promonListLayout" class="mx-1 mt-2"
                                                inputId="promonListLayoutF" :value="'flags'"
                                                @change="updatePromonListLayout" />
                                            <label for="promonListLayoutF">Flags</label>

                                            <loading-icon class="ms-2 d-inline-flex"
                                                v-if="isSavingItem(InternalSettings.promon_list_layout)" />

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>

                        <DefaultSnoozeSettings v-model:defaultSnoozeDays="promonDefaultSnoozeDays"
                            :isSavingItem="isSavingItem(InternalSettings.promon_default_snooze_days)"
                            :inputID="'promonDefaultSnoozeDays'"
                            :settingsName="InternalSettings.promon_default_snooze_days" @handleBlur="handleBlur"
                            @handleFocus="handleFocus" />

                        <form @submit.prevent="" class="my-3">
                            <div class="card">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-12">
                                            <label for="promon-dashboard-url">Dashboard URL</label>
                                            <div class="d-flex align-items-center mt-2">
                                                <InputText type="url" v-model="promonDashboardUrl"
                                                    :disabled="isSavingItem(InternalSettings.promon_dashboard_url)"
                                                    :data-property="InternalSettings.promon_dashboard_url"
                                                    maxlength="2048" @focus="handleFocus($event)"
                                                    id="promon-dashboard-url" class="mt-2 me-2 w-100"
                                                    @blur="handleBlur($event)" />

                                                <a v-if="promonDashboardUrl"
                                                    :href="getSettingUrl(promonDashboardUrl.toString())" target="_blank"
                                                    title="Visit Page" class="d-inline-block"
                                                    style="margin-right: 0.5em;">
                                                    <i class="fa fa-external-link" aria-hidden="true"
                                                        aria-label="Visit Page"></i>
                                                </a>

                                                <loading-icon class="d-inline"
                                                    v-if="isSavingItem(InternalSettings.promon_dashboard_url)" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>


                    <!-- ######################################################## -->
                    <!--  APPVET -->

                    <b>Application Vetter</b>

                    <form class="my-3">
                        <div class="card">
                            <div class="card-body">
                                <div class="row pt-2">
                                    <div class="col-12">
                                        <label for="appVetter">Availability</label>
                                        <br>
                                        <RadioButton v-model="enableAppVetter" class="mx-1 mt-2"
                                            inputId="appVetterEnable" :value="true" @change="updateAppVetter" />
                                        <label for="appVetterEnable" class="me-1">Enabled</label>

                                        <RadioButton v-model="enableAppVetter" class="mx-1 mt-2"
                                            inputId="appVetterDisable" :value="false" @change="updateAppVetter" />
                                        <label for="appVetterDisable">Disabled</label>

                                        <loading-icon class="ms-2 d-inline-flex"
                                            v-if="isSavingItem('application_vetter')" />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>

                    <template v-if="enableAppVetter">
                        <DefaultSnoozeSettings v-model:defaultSnoozeDays="appvetDefaultSnoozeDays"
                            :isSavingItem="isSavingItem(InternalSettings.application_default_snooze_days)"
                            :inputID="'appvetDefaultSnoozeDays'"
                            :settingsName="InternalSettings.application_default_snooze_days" @handleBlur="handleBlur"
                            @handleFocus="handleFocus" />

                        <form class="my-3">
                            <div class="card">
                                <div class="card-body">
                                    <div class="row pt-2">
                                        <div class="col-12">
                                            <label for="applicationProcessing">Application Processing</label>
                                            <br>
                                            <RadioButton v-model="applicationProcessing" class="mx-1 mt-2"
                                                inputId="applicationProcessingEnabled" value="enabled"
                                                @change="updateApplicationProcessing" />
                                            <label for="applicationProcessingEnabled" class="me-1">Enabled</label>

                                            <RadioButton v-model="applicationProcessing" class="mx-1 mt-2"
                                                inputId="applicationProcessingSuspended" value="suspended"
                                                @change="updateApplicationProcessing" />
                                            <label for="applicationProcessingSuspended">Suspended</label>

                                            <loading-icon class="ms-2 d-inline-flex"
                                                v-if="isSavingItem('application_processing')" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>

                        <form @submit.prevent="" class="my-3">
                            <div class="card">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-12">
                                            <label for="appIdPropertyName">Application ID Property Name</label>
                                            <div class="d-flex align-items-center mt-2">
                                                <InputText v-model="appIdPropertyName" maxlength="255"
                                                    id="appIdPropertyName" class="mt-2 me-2 w-100"
                                                    @focus="handleFocus($event)" @blur="handleBlur($event)"
                                                    :data-property="InternalSettings.app_id_property_name"
                                                    :disabled="isSavingItem(InternalSettings.app_id_property_name)" />
                                                <loading-icon class="d-inline ml-2"
                                                    v-if="isSavingItem(InternalSettings.app_id_property_name)" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>

                        <form @submit.prevent="" class="my-3">
                            <div class="card">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-12">
                                            <label for="appVetDashboardUrl">Dashboard URL</label>
                                            <div class="d-flex align-items-center mt-2">
                                                <InputText type="url" v-model="appVetDashboardUrl"
                                                    :disabled="isSavingItem(InternalSettings.appvetter_dashboard_url)"
                                                    :data-property="InternalSettings.appvetter_dashboard_url"
                                                    maxlength="2048" @focus="handleFocus($event)"
                                                    id="appVetDashboardUrl" class="mt-2 me-2 w-100"
                                                    @blur="handleBlur($event)" />

                                                <a v-if="appVetDashboardUrl"
                                                    :href="getSettingUrl(appVetDashboardUrl.toString())" target="_blank"
                                                    title="Visit Page" class="d-inline-block"
                                                    style="margin-right: 0.5em;">
                                                    <i class="fa fa-external-link" aria-hidden="true"
                                                        aria-label="Visit Page"></i>
                                                </a>
                                                <loading-icon class="d-inline"
                                                    v-if="isSavingItem(InternalSettings.appvetter_dashboard_url)" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>

                        <form @submit.prevent="" class="my-3">
                            <div class="card">
                                <div class="card-body">
                                    <div class="row pt-2">
                                        <div class="col-12">
                                            <label>Parcel Pane Display</label>
                                            <br>
                                            <div class="d-flex align-items-center mt-2">
                                                <a href="javascript:;" id="configureParcelDisplay"
                                                    @click="showParcelDisplayModal = true">
                                                    <span class="fa fa-cog"></span>
                                                    Configure
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>

                    </template> <!-- end container for appvet enabled settings -->


                    <!-- ######################################################## -->
                    <!--  INVESTIGATION SERVICES -->

                    <b>Investigation Services</b>

                    <form class="my-3">
                        <div class="card">
                            <div class="card-body">
                                <div class="row pt-2">
                                    <div class="col-12">
                                        <label for="investigationServices">Availability</label>
                                        <br>
                                        <RadioButton v-model="enableInvestigationServices" class="mx-1 mt-2"
                                            inputId="investigationServicesEnable" :value="true"
                                            @change="updateInvestigationServices" />
                                        <label for="investigationServicesEnable" class="me-1">Enabled</label>

                                        <RadioButton v-model="enableInvestigationServices" class="mx-1 mt-2"
                                            inputId="investigationServicesDisable" :value="false"
                                            @change="updateInvestigationServices" />
                                        <label for="investigationServicesDisable">Disabled</label>

                                        <loading-icon class="ms-2 d-inline-flex"
                                            v-if="isSavingItem('investigation_services')" />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>

                </div> <!-- end wrapper for settings -->

                <div v-else>
                    <div class="card my-3" v-for="index in 6" :key="index">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-12">
                                    <label for="skeleton">
                                        <Skeleton width="15rem" height="1rem" class="mb-2"></Skeleton>
                                    </label>
                                    <br>
                                    <div class="d-inline-flex">
                                        <Skeleton width="10rem" height="1rem" class="me-2"></Skeleton>
                                        <Skeleton width="10rem" height="1rem" class="me-2"></Skeleton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <ModalDialog v-if="showParcelDisplayModal" :title="`Parcel Pane Display Configuration`" :close="discardChanges"
        :size="'modal-xl'" :maxHeightPercentage="80">
        <div class="flex flex-wrap gap-3">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th width="30%">Name</th>
                        <th width="35%">Display Name</th>
                        <th width="35%">Keys</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(display, index) in parcelDisplayOptions" :key="index">
                        <td>
                            {{ display.name }}
                        </td>
                        <td>
                            <InputText maxlength="255" v-model="display.display_name" class="mt-2 me-2 w-100" />
                        </td>
                        <td>
                            <div class="d-flex" v-for="(key, keyIndex) in display.keys" :key="keyIndex">
                                <Button @click="removeKey(display.name, keyIndex)" severity="secondary"
                                    :disabled="display.keys.length <= 1" v-tooltip.bottom="'Remove'"
                                    class="my-2 me-2 mt-3">
                                    <span class="fa fa-times-circle" aria-hidden="true"></span>
                                </Button>

                                <InputText maxlength="255" v-model="display.keys[keyIndex]" :data-property="key"
                                    class="mt-2 me-2 w-100" @input="applyInputPattern(index, keyIndex, $event)" />

                                <Button :class="{ 'invisible': keyIndex !== display.keys.length - 1 }"
                                    @click="addNewKey(display.name)" severity="info" v-tooltip.bottom="'Add'"
                                    class="my-2 mt-3">
                                    <span class="fa fa-plus-circle" aria-hidden="true"></span>
                                </Button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <template #footer>
            <Button @click="saveParcelPaneEditor()" id="submitParcelPaneEditor">
                <span class="fa fa-edit" aria-hidden="true" v-if="!isSaving"></span>
                <span class="fa fa-spinner fa-spin" aria-hidden="true" v-if="isSaving"></span>
                Submit
            </Button>

            <Button severity="warning" @click="discardChanges">
                <span class="fa fa-times-circle" aria-hidden="true"></span>
                Discard Changes
            </Button>
        </template>
    </ModalDialog>

    <ModalDialog v-if="confirmDiscardChangesModal" title="Discard Changes Confirmation"
        :close="() => confirmDiscardChangesModal = false">
        <Message severity="warn" :closable="false" class="my-2">
            Are you sure you want to discard your changes?
            <br>
            <br>
            Any unsaved modifications will be lost, and this action cannot be undone.
        </Message>

        <template #footer>
            <Button severity="warning" @click="closeParcelDisplayModal()">Yes</Button>
            <Button severity="secondary" @click="cancelDiscardChanges()">Cancel</Button>
        </template>
    </ModalDialog>
</template>

<script setup lang="ts">
import { ref, onMounted, computed, watch } from "vue"
import { toast } from "@/helpers/toast"
import { getApiErrorMessage } from "@/helpers/common"
import { useAPI } from "@/helpers/services/api"
import { useAuth0 } from '@auth0/auth0-vue'
import LoadingIcon from "@/components/Shared/LoadingIcon.vue"
import { InternalSettings } from "@/helpers/common"
import InputText from 'primevue/inputtext'
import Message from 'primevue/message'
import RadioButton from 'primevue/radiobutton'
import Button from 'primevue/button'
import { useAdminDetails } from "@/stores/adminDetails"
import Skeleton from "primevue/skeleton"
import { useProfile } from "@/stores/profile"
import type { InternalSettingsWithParameter } from "@/helpers/interface/general"
import { ClaimsFields } from '@/helpers/roles'
import ModalDialog from "@/components/Shared/ModalDialog.vue"
import DefaultSnoozeSettings from "@/components/Admin/Shared/DefaultSnoozeSettings.vue"

const api = useAPI()
const { user } = useAuth0();
const storeAdminDetails = useAdminDetails()
const storeProfile = useProfile()
const currentCustomerAccess = computed(() => (storeProfile.getCustomerAccess))
type TypeInternalSettings = boolean | string | null
interface ParcelDisplay {
    name: string;
    display_name: string | null;
    keys: string[];
    description: string[];
}


// SETTINGS
const internalSettings = computed(() => storeAdminDetails.getInternalSettings)
const prioritizationModel = ref<TypeInternalSettings>("")
const applicationProcessing = ref<TypeInternalSettings>("")
const appIdPropertyName = ref<TypeInternalSettings>("")
const appVetDashboardUrl = ref<TypeInternalSettings>("")
const promonDashboardUrl = ref<TypeInternalSettings>("")
const enableBetaFeatures = ref<TypeInternalSettings>(null)
const enableProMon = ref<TypeInternalSettings>(null)
const enableAppVetter = ref<TypeInternalSettings>(null)
const enableInvestigationServices = ref<TypeInternalSettings>(null)
const promonListLayout = ref<any>(null)
const promonDefaultSnoozeDays = ref<any>(null)
const appvetDefaultSnoozeDays = ref<any>(null)

// TOGGLE
const showParcelDisplayModal = ref(false)


// OTHER STATE
const fetchInternalSettingsLoading = ref(true)
const currentItemOriginalValue = ref("")
const isSaving = ref(false)
const savingPropertyName = ref("")
const parcelDisplayOptions = ref<ParcelDisplay[]>([])
const initialParcelDisplayOptions = ref<ParcelDisplay[]>([])

const fetchInternalSettingsWithLoading = async () => {
    fetchInternalSettingsLoading.value = true
    await fetchInternalSettings()
    fetchInternalSettingsLoading.value = false
}

const getSettingValue = (name: string): TypeInternalSettings => (internalSettings.value.filter((s) => s.parameter === name)[0]?.value)
const isSavingItem = (name: string): boolean => (isSaving.value && savingPropertyName.value === name)

const fetchInternalSettings = async () => {
    await storeAdminDetails.fetchInternalSettings(loadFailureHandler)
    applicationProcessing.value = getSettingValue("application_processing")
    prioritizationModel.value = getSettingValue("prioritization_model")
    appIdPropertyName.value = getSettingValue("app_id_property_name")
    appVetDashboardUrl.value = getSettingValue("appvetter_dashboard_url")
    promonDashboardUrl.value = getSettingValue(InternalSettings.promon_dashboard_url)
    enableBetaFeatures.value = getSettingValue("enable_beta_features")
    promonDefaultSnoozeDays.value = getSettingValue("promon_default_snooze_days")
    appvetDefaultSnoozeDays.value = getSettingValue("application_default_snooze_days")

    enableProMon.value = currentCustomerAccess.value?.proactive_monitoring as boolean
    enableAppVetter.value = currentCustomerAccess.value?.application_vetter as boolean
    enableInvestigationServices.value = currentCustomerAccess.value?.investigation_services as boolean
    promonListLayout.value = getSettingValue("promon_list_layout")

    if (promonListLayout.value?.[0]) {
        promonListLayout.value = promonListLayout.value[0]?.columns[0]?.fields[0]?.field_name || null
    }
}

const fetchAllParcelDisplayConfig = async () => {
    const displayOptions = await storeAdminDetails.fetchInternalSettingsItem(appParcelDisplayValue, loadFailureHandler)
    if (displayOptions?.value) {
        initialParcelDisplayOptions.value = JSON.parse(JSON.stringify(displayOptions.value))
        parcelDisplayOptions.value = displayOptions.value
    }
}

const updatePrioritizationModel = async () => updateSetting(InternalSettings.prioritization_model, prioritizationModel.value as InternalSettings)
const updateApplicationProcessing = async () => updateSetting(InternalSettings.application_processing, applicationProcessing.value as InternalSettings)
const updateBetaFeatures = async () => updateSetting(InternalSettings.enable_beta_features, enableBetaFeatures.value as InternalSettings)
const updateAppVetter = async () => updateSetting(InternalSettings.application_vetter, enableAppVetter.value as InternalSettings)
const updateProMon = async () => updateSetting(InternalSettings.proactive_monitoring, enableProMon.value as InternalSettings)
const updateInvestigationServices = async () => updateSetting(InternalSettings.investigation_services, enableInvestigationServices.value as InternalSettings)
const updatePromonListLayout = async () => updateSetting(InternalSettings.promon_list_layout, promonListLayout.value as InternalSettings)

const isParcelPaneUpdated = ref(false)
const confirmDiscardChangesModal = ref(false)
const appParcelDisplayValue = "application_parcel_display" as InternalSettings

const addNewKey = (name: string) => {
    parcelDisplayOptions.value.find(display => display.name === name)?.keys.push("")
}

const removeKey = (name: string, index: number) => {
    parcelDisplayOptions.value.find(display => display.name === name)?.keys.splice(index, 1)
}

const closeParcelDisplayModal = () => {
    parcelDisplayOptions.value = JSON.parse(JSON.stringify(initialParcelDisplayOptions.value))
    showParcelDisplayModal.value = false
    confirmDiscardChangesModal.value = false
}

const cancelDiscardChanges = () => {
    confirmDiscardChangesModal.value = false
    showParcelDisplayModal.value = true
}

const applyInputPattern = (displayIndex: number, keyIndex: number, event: Event) => {
    // Allow alphabetic characters, numbers, underscores, and dashes after the first character
    const validPattern = /^[a-zA-Z_][a-zA-Z0-9_-]*$/
    let inputValue = (event.target as HTMLInputElement).value
    if (!validPattern.test(inputValue)) {
        inputValue = inputValue.replace(/[^a-zA-Z0-9_-]/g, '')
        if (/^\d/.test(inputValue)) {
            inputValue = inputValue.replace(/^\d+/, '')
        }
    }
    parcelDisplayOptions.value[displayIndex].keys[keyIndex] = inputValue
}

const discardChanges = () => {
    if (!isParcelPaneUpdated.value) {
        showParcelDisplayModal.value = false
        confirmDiscardChangesModal.value = true
    }
    else {
        closeParcelDisplayModal()
    }
}

const saveParcelPaneEditor = () => {
    updateSetting(appParcelDisplayValue, parcelDisplayOptions.value)
}

watch(() => parcelDisplayOptions.value, (newValue, oldValue) => {
    isParcelPaneUpdated.value = newValue !== oldValue
}, { deep: true });

const handleFocus = (event: FocusEvent) => {
    const input = event.target as HTMLInputElement
    currentItemOriginalValue.value = input.value
}

const handleBlur = (event: FocusEvent, property: string = "") => {
    const input = event.target as HTMLInputElement
    if (input.value === currentItemOriginalValue.value) return

    const name = (input.dataset?.property || property) as InternalSettings
    if (!name) {
        alert("Configuration error. Element is not configured with a property name")
        return
    }
    updateSetting(name, input.value as InternalSettings)
}

const updateSetting = async (settingsName: InternalSettings, value: any) => {
    savingPropertyName.value = settingsName
    isSaving.value = true
    const customerServices = [InternalSettings.application_vetter, InternalSettings.investigation_services, InternalSettings.proactive_monitoring]
    const listLayout = [InternalSettings.promon_list_layout]

    try {
        let payload;

        // *** Investigation Services *** 
        if (customerServices.includes(settingsName)) {
            payload = {} as unknown as any
            payload[settingsName] = value
            await api.patch(`/customers/`, payload);
        }

        // *** Promon List Layout *** 
        else if (listLayout.includes(settingsName)) {
            payload = [
                {
                    "view_name": "inbox",
                    "columns": [
                        {
                            "fields": [{ "field_name": value }],
                            "column_index": 4
                        }
                    ]
                }
            ] as InternalSettingsWithParameter[]
            await api.patch(`/settings/internal/${settingsName}`, { "value": payload })
        }

        // *** Other *** 
        else {
            if (showParcelDisplayModal.value) {
                // Parcel pane payload                                   
                const filtered = () => {
                    return parcelDisplayOptions.value.map(item => ({
                        ...item,
                        keys: item.keys.filter(key => key !== "" && key !== null)
                    }))
                }
                payload = filtered()
                settingsName = appParcelDisplayValue
            } else {
                payload = value
            }

            await api.patch(`/settings/internal/${settingsName}`, { "value": payload })
        }

        toast.success("Saved!")
        storeProfile.fetchCustomerAccess()
        fetchAllParcelDisplayConfig()
    } catch (error: any) {
        toast.error(getApiErrorMessage(error))
    }

    isSaving.value = false
    savingPropertyName.value = ""
}

const loadFailureHandler = (error: any) => {
    const message = getApiErrorMessage(error, { "featureName": "Config: Settings" })
    toast.error(message)
}

const getSettingUrl = (templateUrl: string) => {
    if (templateUrl) {
        return templateUrl.replace("{customer_id}", getCustomerId().toString())
    }
    return templateUrl
}

const getCustomerId = () => {
    const output = ~~(user.value?.[ClaimsFields["app_metadata"]]?.customer_id)
    return output
}

onMounted(async () => {
    await fetchInternalSettingsWithLoading()
    fetchAllParcelDisplayConfig()
})

</script>
