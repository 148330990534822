<template>
    <main class="p-2">
        <component :is="selectedPageComponent" />
    </main>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import AdminConfigChangeCustomer from './AdminConfigChangeCustomer.vue';
import AdminConfigUsers from './AdminConfigUsers.vue';
import AdminWorkflowMailModule from './AdminWorkflowMailModule.vue';
import AdminConfigMailTemplates from './AdminConfigMailTemplates.vue';
import AdminConfigLabels from './AdminConfigLabels.vue';
import AdminUnclaimedExmptDataRequest from './AdminUnclaimedExmptDataRequest.vue';
import AdminDownloadUnclaimedList from './AdminDownloadUnclaimedList.vue';
import AdminOnboarding from './AdminOnboarding.vue';
import AdminReportNewAssessedValue from './AdminReportNewAssessedValue.vue';
import AdminConfigInternalSettings from './AdminConfigInternalSettings.vue';
import AdminReportNewAssessedValueRequest from './AdminReportNewAssessedValueRequest.vue';
import AdminConfigSettings from './AdminConfigSettings.vue';
import AdminUserPreference from './AdminUserPreference.vue';
import AdminConfigOutreachCodes from './AdminConfigOutreachCodes.vue';

const props = defineProps({
    page: {
        type: String,
        required: true
    }
})

const pageComponents: any = {
    "mail-module": AdminWorkflowMailModule,
    "labels": AdminConfigLabels,
    "mail-templates": AdminConfigMailTemplates,
    "change-customer": AdminConfigChangeCustomer,
    "users": AdminConfigUsers,
    "internal-settings": AdminConfigInternalSettings,
    "settings": AdminConfigSettings,
    "report-nave": AdminReportNewAssessedValue,
    "nave-request": AdminReportNewAssessedValueRequest,
    "dl-unclaimed-list": AdminDownloadUnclaimedList,
    "unclaimed-exmpt-data-request": AdminUnclaimedExmptDataRequest,
    "onboarding": AdminOnboarding,
    "user-preference": AdminUserPreference,
    "outreach-codes": AdminConfigOutreachCodes
}
const selectedPageComponent = computed(() => pageComponents[props.page]);

</script>

<style>
@import "@/assets/admin-page.css";
@import "tabulator-tables";
</style>
