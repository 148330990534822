<template>
    <InputMask v-model="phone" :pt="{ pcInputText: { root: phoneInputAttributes } }" :required="required" :id="id"
        :mask="resolvedMask" class="tr-phone-input" v-tooltip.bottom="resolvedTitle"
        @invalid.capture.prevent="elementRequiredHandler"/>
</template>

<script setup lang="ts">
import { phoneInputAttributes } from '@/helpers/common'
import InputMask from 'primevue/inputmask'
const { required, id, elementRequiredHandler, title, mask } = defineProps<{
    required: boolean;
    id: string;
    elementRequiredHandler: (event: Event) => void;
    title?: string;
    mask?: string;
}>();

const phone = defineModel<string>("modelValue")
const resolvedTitle = title ?? "A phone number including area code. Example: 555-123-4567"
const resolvedMask = mask ?? "999-999-9999"
</script>