<template>
    <div class="element-container">
        <label :class="getRequiredClasses(isDriversLicenseRequired)" :for="getId('id-file')">
            Upload: Driver License or state issued personal identification certificate
        </label>
        <input type="file" :ref="'idFile' + ownerNumber" :id="getId('id-file')" :accept="acceptFileTypes"
            :required="isDriversLicenseRequired" @invalid.capture.prevent="elementRequiredHandler"
            :disabled="!customerID">
        <ApplicationFileUploadStatus :containerID="getId('id-file')" />
    </div>

    <div class="element-container">
        <label :for="getId('name')" class="form-label form-label-required">
            Name of Property Owner {{ ownerNumber }}
        </label>
        <input type="text" v-model="name" :id="getId('name')" required maxlength="255" autocapitalize="words"
            autocomplete="name" @invalid.capture.prevent="elementRequiredHandler" spellcheck="false">
    </div>

    <div class="element-container">
        <label :class="getRequiredClasses(requireBirthdate)" :for="getId('birthdate')">Birth Date
            <SupAnchor text="1" target="#footnotes" />
        </label>
        <DateComboBox v-model="birthdate" :element-required-handler="elementRequiredHandler"
            :required="props.requireBirthdate" :id="getId('birthdate')" />

        <div class="bg-attention mt-2" v-if="showBirthDateWarning">
            If you are older than 65 or you are turning 65 this year. You may qualify for the Persons 65 or Older
            exemption
            in Section 1, but have not selected it.
        </div>
    </div>

    <div v-if="ownerNumber === 1" class="element-container">
        <span class="form-label form-label-required">
            I further affirm that the address on the uploaded Texas driver license or state issued personal
            identification certificate matches the physical address of the subject property
        </span>
        <fieldset>
            <input type="radio" class="custom-radio" name="affirm-id-property-address-match"
                v-model="affirm_id_property_address_match" :value="true" required
                :id="getId('affirm_id_property_address_match_1')" @invalid.capture.prevent="elementRequiredHandler">
            <label :for="getId('affirm_id_property_address_match_1')">Yes</label>

            <input type="radio" class="custom-radio" name="affirm-id-property-address-match"
                :id="getId('affirm_id_property_address_match_0')" v-model="affirm_id_property_address_match"
                :value="false" required>
            <label :for="getId('affirm_id_property_address_match_0')">No, I will provide an answer in Section 4:
                Waivers</label>
        </fieldset>
    </div>

    <div class="element-container">
        <label :for="getId('id-number')" :class="getRequiredClasses(isDriversLicenseRequired)">
            Driver License, Personal ID Certificate, or Social Security Number
            <SupAnchor text="2" target="#footnotes" />
        </label>
        <input type="text" v-model="licenseNumber" :id="getId('id-number')" inputmode="numeric" maxlength="30"
            @invalid.capture.prevent="elementRequiredHandler" :required="isDriversLicenseRequired">
    </div>

    <div class="element-container">
        <label :for="getId('percent-ownership-interest')" :class="getRequiredClasses(ownerNumber === 1)">
            Percent Ownership Interest
        </label>
        <input type="number" v-model="percentOwnershipInterest" :id="getId('percent-ownership-interest')"
            @invalid.capture.prevent="elementRequiredHandler" inputmode="numeric" min="1" max="100" step="1"
            :required="ownerNumber === 1">
    </div>

    <div class="element-container">
        <label :for="getId('email')" :class="getRequiredClasses(ownerNumber === 1)">
            Email Address
            <SupAnchor text="3" target="#footnotes" />
        </label>
        <input :id="getId('email')" v-model="email" type="email" maxlength="255" spellcheck="false" autocomplete="email"
            @invalid.capture.prevent="elementRequiredHandler" autocorrect="false" autocapitalize="off"
            title="A valid email address. Example: name@example.com" :required="ownerNumber === 1">
    </div>

    <div class="element-container">
        <label :for="getId('phone')" :class="getRequiredClasses(ownerNumber === 1)">
            Primary Phone Number (with area code)
        </label>
        <PhoneField
            v-model="phone"
            :elementRequiredHandler="elementRequiredHandler"
            :id="getId('phone')"
            :required="ownerNumber === 1"
        />
    </div>
</template>


<script setup lang="ts">
import { ref, computed, onMounted } from "vue"
import SupAnchor from "@/components/Shared/SupAnchor.vue"
import type { PropType } from "vue"
import { useApplicationForm } from "@/stores/applicationForm"
import ApplicationFileUploadStatus from "@/components/ApplicationForm/ApplicationFileUploadStatus.vue"
import type { StateIdExtraction } from "@/helpers/interface/general"
import DateComboBox from "@/components/Shared/DateComboBox.vue"
import dayjs from "dayjs"
import PhoneField from "@/components/Shared/PhoneField.vue"

const name = defineModel<string | null>("name")
const birthdate: any = defineModel("birthdate")
const licenseNumber = defineModel<string | null>("licenseNumber")
const percentOwnershipInterest = defineModel<number | null>("percentOwnershipInterest")
const email = defineModel<string | null>("email")
const phone: any = defineModel("phone")
const affirm_id_property_address_match = defineModel<boolean | null>("affirm_id_property_address_match")

// models updated from ID text extraction (Owner 1 only)
const propertyAddressLine1 = defineModel("propertyAddressLine1")
const propertyCity = defineModel("propertyCity")
const propertyState = defineModel("propertyState")
const propertyPostalCode = defineModel("propertyPostalCode")


const storeApplicationForm = useApplicationForm()
const props = defineProps({
    ownerNumber: {
        type: Number,
        required: true
    },
    fileInputChangeHandler: {
        type: Function as PropType<(e: Event, isDriversLicense: boolean | undefined) => void>,
        required: true
    },
    elementRequiredHandler: {
        type: Function as PropType<(e: Event) => void>,
        required: true
    },
    acceptFileTypes: {
        type: String,
        required: true
    },
    maxDateString: {
        type: String,
        required: true
    },
    requireDriversLicenseDefault: {
        type: Boolean,
        default: false
    },
    requireBirthdate: {
        type: Boolean,
        default: false
    },
    instanceKey: {
        type: String,
        required: true,
    },
    customerID: {
        type: String,
        required: true,
    },
    api_key: {
        type: String,
        required: true,
    }
})

const instanceKey = ref(props.instanceKey)
const getId = (text: string) => (`property-owner-${text}-${props.ownerNumber}`)
const getRequiredClasses = (required: boolean) => ((required) ? ["form-label", "form-label-required"] : ["form-label"])
const isDriversLicenseRequired = computed(() => (props.ownerNumber === 1 && affirm_id_property_address_match.value === true))
const showBirthDateWarning = computed(() => (storeApplicationForm.showBirthDateWarning()))


const populateFromTextExtraction = (data: StateIdExtraction) => {
    if (!name.value) {
        name.value = `${data.first_name} ${data.last_name}`.trim()
    }
    if (!licenseNumber.value) {
        licenseNumber.value = data.document_number || ""
    }
    if (data.dob && !birthdate.value) {
        // ensure DOB is correctly converted from ISO string to Date
        birthdate.value = dayjs(data.dob).toDate()
    }

    if (props.ownerNumber == 1) {
        if (data.address && !propertyAddressLine1.value) {
            propertyAddressLine1.value = data.address
        }
        if (data.city && !propertyCity.value) {
            propertyCity.value = data.city
        }
        if (data.state && !propertyState.value) {
            propertyState.value = data.state
        }
        if (data.postal_code && !propertyPostalCode.value) {
            propertyPostalCode.value = data.postal_code
        }
    }
}
const uploadEvent = async (event: Event) => {
    props.fileInputChangeHandler(event, true)

    const input = event.target as HTMLInputElement
    const file = input.files?.[0]
    if (!file) return

    const id = input.getAttribute("id") || ""
    const upload = await storeApplicationForm.uploadFile(
        instanceKey.value,
        file,
        props.customerID,
        "application_attachments",
        id,
        input,
        props.api_key
    )

    if (upload?.meta.extraction) {
        populateFromTextExtraction(upload.meta.extraction)
    }
}

onMounted(() => {
    const fileInput = document.getElementById(getId('id-file'))
    if (!fileInput) return
    fileInput.addEventListener("input", uploadEvent)
})
</script>