<template>
    <section>
        <div class="config-header">Change Customer</div>
        <p class="actions-description">
            This page provides you with the option to modify the customer account you are currently accessing.
            With this feature, you can easily switch to a different customer account as needed.
        </p>

        <article class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-12 col-lg-4 col-xl-3">
                        <label for="customer-search-select">Customer Name</label>
                        <div class="d-flex align-items-center mt-2">
                            <Select v-if="!getCustomerListLoading" editable v-model="customerID"
                                :options="customerList" optionLabel="name" optionValue="customer_id" class="w-100"
                                :disabled="formLoading || !customerList.length" @change="handleCustomerChange"
                                />
                            <Skeleton height="2.2rem" width="200px" v-else></Skeleton>

                            <loading-icon class="ms-2" v-if="formLoading" />
                        </div>
                    </div>
                </div>
            </div>
        </article>
    </section>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from "vue"
import { useAdminDetails } from "@/stores/adminDetails";
import { useProfile } from "@/stores/profile";
import { toast } from "@/helpers/toast";
import { useAPI } from "@/helpers/services/api";
import { getApiErrorMessage } from "@/helpers/common"
import type { Customer, CustomerList } from '@/helpers/interface/admin-page';
import LoadingIcon from "@/components/Shared/LoadingIcon.vue"
import type { AxiosError } from "axios"
import Select from "primevue/select"
import Skeleton from "primevue/skeleton";

const api = useAPI()
const storeAdminDetails = useAdminDetails();
const storeProfile = useProfile();

const formLoading = ref(false)
const getCustomerListLoading = ref(false)
const availableCustomersList = computed((): CustomerList => (storeAdminDetails.getCustomerList))
const currentCustomerAccess = computed(() => storeProfile.getCustomerAccess)

const customerID = ref<string>(currentCustomerAccess.value?.customer_id as string)
const currentItemOriginalValue = ref<string>(currentCustomerAccess.value?.customer_id as string)
const customerList = computed((): Customer[] => (availableCustomersList.value.data.filter(c => !!c.customer_id)))

const loadFailureHandler = (error: AxiosError) => {
    const message = getApiErrorMessage(error, { "featureName": "Customer List" })
    toast.error(message)
}

const handleCustomerChange = async () => {
    if (customerID.value === currentItemOriginalValue.value) return
    if (!customerList.value.filter(d => d.id === customerID.value).length) return

    await changeCustomerAccess()
}

let changeCustomerAccess = async () => {
    formLoading.value = true
    // Submit Request
    try {
        await api.patch(`/users/me`, { customer_id: customerID.value });
        toast.success(`Your update has been saved`)

        // Delay 1.5s
        setTimeout(() => window.location.reload(), 1000)
    }
    catch (error: any) {
        toast.error(getApiErrorMessage(error))
    }
    finally {
        formLoading.value = false;
    }
}

const fetchCustomersList = async () => {
    if (customerList.value.length) return
    getCustomerListLoading.value = true
    await storeAdminDetails.fetchCustomersList(loadFailureHandler)
    getCustomerListLoading.value = false
}

onMounted(() => fetchCustomersList())
</script>