<template>
    <component :is="selectedComponent" :formId="formId" />
</template>

<script lang="ts" setup>
import { computed } from "vue"
import ApplicationFormTX from '@/components/ApplicationForm/tx/ApplicationForm.vue';
import ApplicationFormGA from '@/components/ApplicationForm/ga/ApplicationForm.vue';
import UnsupportedState from "@/components/ApplicationForm/UnsupportedState.vue";

const props = defineProps({
    state: {
        type: String,
        required: true
    },
    formId: {
        type: String,
        required: true
    }
})

const selectedComponent = (computed(() => {
    switch (props.state.toUpperCase()) {
        case "TX":
            return ApplicationFormTX;
        case "GA":
            return ApplicationFormGA;
        default:
            return UnsupportedState;
    }
}))

</script>
