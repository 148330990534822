<template>
  <div class="container">

    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col"></th> <!-- for selecting  -->
          <th scope="col">Title</th>
          <th scope="col">Created</th>
          <th scope="col">Updated</th>
          <th scope="col">Status</th>
          <th scope="col">Public Link</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="state.dataRequestsLoading">
          <td colspan="6" style="text-align: center">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </td>
        </tr>
        <tr v-for="dataRequest in state.dataRequests" :key="dataRequest.id">
          <td>
            <input type="radio" name="dataRequest" :value="dataRequest.id" @click="$router.push({name: 'dataRequest', params: { data_request_id: dataRequest.id }})">
          </td>
          <td><router-link :to="{name: 'dataRequest', params: { data_request_id: dataRequest.id }}">{{ dataRequest.title }}</router-link></td>
          <td>{{ new Date(dataRequest.created_at).toDateString() }}</td>
          <td>{{ new Date(dataRequest.updated_at).toDateString() }}</td>
          <td>{{ dataRequest.status }}</td>
          <td>
            <button type="button" class="btn btn-outline-primary btn-sm" @click="copyLink(getDataRequestUrl(dataRequest))">Copy Link</button>
          </td>
        </tr>
        <tr>
          <td colspan="6">
            <button type="button" class="btn btn-outline-primary btn-sm" @click="$router.push({name: 'dataRequest', params: { data_request_id: 'new' }})">New Data Request</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup lang="ts">
import { useAPI } from "@/helpers/services/api"
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import {reactive, onMounted,  } from "vue";
import type {
  DataRequest,
} from "@/helpers/interface/trueload";
import { toast } from "@/helpers/toast";
import { hidePFGetHelpButton } from "@/helpers/common";

const state = reactive({
  dataRequests: [] as Array<DataRequest>,
  dataRequestsLoading: false,
})

const getDataRequestUrl = (dataRequest: DataRequest): string => {
  return `${window.location.protocol}//${window.location.host}/data-drop/${dataRequest.id}`
}

const copyLink = (dataRequestUrl: string) => {
  navigator.clipboard.writeText(dataRequestUrl)
  toast.success('Link copied to clipboard')
}

const api = useAPI()

const getDataRequests = async () => {
  state.dataRequestsLoading = true
  const response = await api.get(`data-requests/`)
  state.dataRequests = response.data
  state.dataRequestsLoading = false
}

onMounted(async () => {
  await getDataRequests();
  hidePFGetHelpButton()
});

</script>

<style scoped></style>
@/helpers/interface/trueload