import type { Labels, SubMenuConfig } from "@/helpers/interface/admin-page"
import type { AppVetSummaryData, Observations } from "@/helpers/interface/appvet"
import type { ApplicationDataGA, ApplicationForm } from "@/helpers/interface/applicationForm"
import { OwnerType } from "@/helpers/interface/applicationForm"
import type {
    CandidateDocumentList,
    FlagsAPIResponse,
    HistoryAPIResponse,
    NotesAPIResponse,
    SitusInfo,
} from "@/helpers/interface/candidates"
import type { ColumnDefinition } from "tabulator-tables"
import type { FilterSchema } from "@/helpers/interface/general"
import type { SupportingDocument } from "@/helpers/interface/trueapp"

export const customerList = {
    hasAccess: true,
    data: [
        {
            "id": "None",
            "customer_id": "99999",
            "fips_code": "48999",
            "name": "TEST",
            "schema_name": "tx_sample_county",
            "proactive_monitoring": false,
            "state": "TX"
        },
        {
            "id": "None",
            "customer_id": "111111",
            "fips_code": "13103",
            "name": "EFFINGHAM CUVA TEST",
            "schema_name": "ga_effingham_county_cuva",
            "proactive_monitoring": false,
            "state": "GA"
        }
    ]
}

export const situsInfo: SitusInfo = {
    "tru_id": "163f838e-8a52-407b-a30d-d54309eabecd",
    "situs_full_taxroll": "12503 Split Rail Pkwy, Austin, 78750",
    "parcel_num": "R062126",
    "owner_name": "ROCKY CLARK CLARK CINDI",
    "legal_description": "DEERBROOK VILLAGE, BLOCK A, LOT 66",
    "exmpt_description": "Homestead",
    "market_value": "$305,000.00",
    "assessed_value": "$305,000.00",
    "deed_date": "2007-1-1",
    "county_lookup_url": "httpR062126",
    "mail_full_taxroll": "10902 Rustic Manor Ln, Austin, Tx 78750",
    "mail_county": "Williamson",
    "taxable_value": "$305,000.00",
    "recovered_amount": null,
    "queue": "inbox",
    "reason": "Snooze Alarm",
    "unqualified_start_year": null,
    "lien_or_back_taxes": false,
    "snooze_until": null,
    "final_outcome": null,
    "assigned_user_id": null,
}

export const auth0User = {
    name: "John Doe",
    picture: "https://s.gravatar.com/avatar/066c0b4c51f2c37d5d31e2047e645d09?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fkh.png", // KH
    value: { sub: "auth0|test-user" }
}

export const mailTemplateList = [
    {
        "id": 739,
        "name": "Cert Removal Letter",
        "response_days": null,
        "updated_at": "2023-05-11T16:56:05+00:00",
        "is_demo": false,
        "created_at": "2023-05-11T16:56:05+00:00"
    },
    {
        "id": 661,
        "name": "DEMO - Intent to Deny",
        "response_days": 30,
        "updated_at": "2022-10-11T10:57:20+00:00",
        "is_demo": false,
        "created_at": "2022-10-11T10:57:20+00:00"
    }
]

export const reasonsList = [
    "Not Primary Residence",
    "Deceased",
    "Multiple Homesteads",
    "Other"
]

export const labelList: Labels[] = [
    {
        "id": 496,
        "label_name": "Awaiting Acadia feedback",
        "candidates": true,
        "applications": true,
        "created_at": "2023-06-26T10:40:05",
        "updated_at": null
    },
    {
        "id": 485,
        "label_name": "Clerk of court review",
        "candidates": true,
        "applications": true,
        "created_at": "2023-06-06T14:22:49",
        "updated_at": "2023-06-14T08:02:36"
    }
]

export const applicationLabelList = [
    {
        "customer_id": "89",
        "label_name": "Demo Highlights",
        "internal_only": false,
        "applications": true,
        "created_by": "141",
        "id": 68,
        "deactivated": false,
        "candidates": true,
        "created_at": "2021-04-13T13:31:08",
        "updated_at": "2024-03-07T19:31:55.096654"
    },
    {
        "customer_id": "89",
        "label_name": "Clerk of court review",
        "internal_only": false,
        "applications": true,
        "created_by": "auth0|63f77f7e7af7d78587aef4f0",
        "id": 485,
        "deactivated": false,
        "candidates": true,
        "created_at": "2024-03-06T14:50:42.870433",
        "updated_at": "2024-03-07T19:37:51.111222"
    }
]

export const permissionList = [
    "create:labels",
    "create:mailing_templates",
    "create:postal_contact_workflows",
    "create:released_investigation_reports",
    "create:unreleased_investigation_reports",
    "create:users",
    "delete:unreleased_investigation_reports",
    "delete:users",
    "read:applications",
    "read:candidates",
    "read:candidates_assigned",
    "read:customers",
    "read:data_requests",
    "read:ingestion_configs",
    "read:labels",
    "read:internal_release",
    "read:mailing_templates",
    "read:postal_contact_workflows",
    "read:released_investigation_reports",
    "read:reports_assessed_value",
    "read:reports_unclaimed",
    "read:unreleased_investigation_reports",
    "read:uploads",
    "read:users",
    "update:applications",
    "update:applications_vet",
    "update:candidates",
    "update:candidates_archived",
    "update:data_requests",
    "update:ingestion_configs",
    "update:mailing_templates",
    "update:postal_contact_workflows",
    "update:unreleased_investigation_reports",
    "update:users",
    "read:investigation_quick_links",
]

export const current = {
    "id": "99999",
    "customer_id": "99999",
    "fips_code": "99999",
    "name": "TEST",
    "schema_name": "test",
    "proactive_monitoring": true,
    "application_vetter": true,
    "state": "TEST"
}

export const userList = [
    {
        "blocked": true,
        "created_at": "2023-08-21T14:29:54.480Z",
        "email": "test@gmail.com",
        "email_verified": true,
        "name": "test",
        "nickname": "test",
        "picture": "https://s.gravatar.com/avatar/268c2428026b86f00ae76acc5253b717?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fgy.png",
        "updated_at": "2023-11-16T17:32:16.322Z",
        "user_id": "auth0|64e374e2e998cf75d2124319",
        "user_metadata": {},
        "app_metadata": {
            "customer_id": "64",
            "invitation_pending": true,
            "is_client": true,
            "roles": [
                "rol_8iSPNYJHzCRtIJpv"
            ]
        },
        "roles": [
            {
                "id": "rol_8iSPNYJHzCRtIJpv",
                "name": "Client Admin",
                "description": "Admin level at the client"
            }
        ]
    },
    {
        "blocked": false,
        "created_at": "2023-08-25T09:40:34.249Z",
        "email": "test2@trueroll.io",
        "email_verified": true,
        "name": "test2",
        "nickname": "test2",
        "picture": "https://s.gravatar.com/avatar/285a296539353877ef4630292cc092a5?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fiv.png",
        "updated_at": "2023-11-16T17:32:14.813Z",
        "user_id": "auth0|64e877120b38b07996806c3d",
        "user_metadata": {},
        "app_metadata": {
            "customer_id": "64",
            "invitation_pending": true,
            "is_client": true,
            "roles": [
                "rol_6UMvhkoXITM270C8"
            ]
        },
        "roles": [
            {
                "id": "rol_6UMvhkoXITM270C8",
                "name": "Client Specialist",
                "description": "A focused client level account with limited access."
            }
        ]
    }
]

export const appvetSummaryCountsTab = {
    "current_week_app_count": 1,
    "previous_week_app_count": 1,
    "less_than_30_days_remaining_count": 1,
    "unvetted_count": 923,
    "yesterday_count": 1,
    "multi_year_current_week_app_count": 1,
    "multi_year_previous_week_app_count": 1,
    "multi_year_less_than_30_days_remaining_count": 1,
    "multi_year_unvetted_count": 47,
    "multi_year_yesterday_count": 1
} as AppVetSummaryData


export const documentList = [
    {
        "link": "",
        "notes": "",
        "tru_id": "233991fa-64eb-45b3-8d26-3218fb691d13",
        "document_name": "Testing",
        "download_url": "https://tr-candidate-documents-dev.s3.amazonaws.com/fl/sarasota/candidate-documents/233991fa-64eb-45b3-8d26-3218fb691d13/076cfffd-6dd2-49ee-b545-58f632f6ee2a.png?AWSAccessKeyId=ASIA3YCB4M45AKWNVDHE&Signature=52hw676gUzf8Musm5%2FaaDRjUD0c%3D&x-amz-security-token=IQoJb3JpZ2luX2VjEDgaCXVzLXdlc3QtMiJHMEUCIQDe2Zw3ywGU8E%2BsBQJXDME5Jt5QTxSk9QakK4ym0AFv6AIgVWu8C2LFFDVS63NOfahklvHVzuLeD7zJOmc0MV%2FksToq9gMI8f%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAAGgw4MDc1OTIwMjc5NjIiDPpQFHDoTkyLHQxI9yrKA1kkgFII5tiNYG3Hm%2FGnzSpxynRyyXkRSIhkGvoQI1IDsgW9hYh0e5UNvtPwe7n4Y7XSsrksPQ8UDhYVmXHZ1vp5qt5484BExXjUM3kP1Joxpn9zX828H%2FPqoi3vZ3zJTexR6PI6ZztNYjb7hPQnTlhN6iPy8yaoM9CjALTOH00BSpW6dlCmoXKBa6PtIyXp15hVzeeQ%2FV%2BYvLfeN0ljkMt%2FiYCRiTPXVnGrADwWci%2BNWAVwthBVMouldTzwLHyBn%2BENm4qhM6EkM2qz8zS96R6PvvytHnT%2BgU2w1cQOTTxRuwo0bhaXgztqiJb2X%2BBg06uc6q1hd0dEEmRbyLjxi97pCErJdgUhMec6Zw78%2FydAln2N%2BA%2B7W%2BLPGhrNFiCtzv9I3Jcb09vrwqRy%2BAVnd1jUzI85%2FNb09ZEL8uxxTEKu7IiTTQvyIzuH2uzAdWHTWNKDduGMK85psz103eJbYDqyZRmmW991GCzi5%2BEqUSYSUMFNYEAswTb3latB74zR0kdgw4frKK5KJIfzHiiP8mwJtebWVeNc%2ByTs3f5dDkVd1wNzf24baEfIRZ8vAhZ74Xsk1OL1DAx5kcTfODqzVgBkinyN8uoUJ6vEMPCV360GOqUBszMy8t4yTBkuiIh94aAgDkZj1LEupygDNHgeIMFPs7aje4s6W32W4kcqOm1Lk0kf7o7bhlPZFu1BH%2FAbkFlM0ofI7p6GBsdt4nHng%2Fi4Yt9CreWMiC2dEX1M0L2NRfYhA%2BXkdhvlEEf5VF9GM6mmBOs3yqTS9BMFkzgYyp8Wr5EefXionsvzhpR8D3f0mban9t2WLjN%2BcfC1KfIgmmjbTJHnspld&Expires=1706587596",
        "file_name": "test-image.png",
        "id": "076cfffd-6dd2-49ee-b545-58f632f6ee2a"
    },
    {
        "link": "https://tests.com",
        "notes": "Testing link",
        "tru_id": "233991fa-64eb-45b3-8d26-3218fb691d13",
        "document_name": "Testing 2",
        "id": "4be9d635-f349-4e6c-aff8-b3ceb5a1d968"
    }
] as CandidateDocumentList[]

export const narData = {
    "application_id": "156",
    "assigned_user": "khurt + williamson",
    "assigned_user_id": "auth0|65f9b70eb5436c9c9272ffd0",
    "tru_id": "b57a0e76-33fb-431d-b987-41dae27daec8",
    "parcel_num": "45939",
    "parcel_num_standardized": "45939",
    "situs_full_taxroll": "999 S Preston St, Wolfe City, TX 1234567",
    "situs_address_line_1": "519 S Preston St",
    "situs_address_line_3": "Wolfe City, TX 99999",
    "owner_name_full": "Test User 0",
    "mail_full_taxroll": "000 S Preston St, Wolfe City, TX 1234567",
    "mail_address_line_1": "123 S Preston St",
    "mail_address_line_3": "Wolfe City, TX 99999",
    "mail_county": "Hunt",
    "prev_addr_app": null,
    "prev_addr_cass": null,
    "effective_date": "2023-08-26",
    "effective_date_raw": "2024-02-01",
    "county_lookup_url": "http45939",
    "occupancy_date": "2024-02-01",
    "flags": [
        {
            "eligibility_rule_id": 98,
            "flag_name": "Voter Conflict at Previous",
            "display_html": "<table class=\"notestable\">\n    <tr><td class=\"object\" colspan=\"2\">Test User 999</td></tr>\n    <tr>\n        <td class=\"label\">voter date of birth:</td>\n        <td class=\"value\">1989-09-29</td>\n    </tr><tr><td class=\"label\">voter id:</td>\n        <td class=\"value\">1146830009</td>\n    </tr>\n    <tr>\n        <td class=\"label\">voter registration address:</td>\n        <td class=\"value\" unit_level_id=\"3c363ea1-0f79-6ba0-c445-f69f87638d0b\">999 TESORO TRL, AUSTIN, TX 0000</td>\n    </tr>\n    \n    <tr><td class=\"label\">voter registration date:</td>\n        <td class=\"value\">2018-11-06</td>\n    </tr>\n    \n    \n    <tr><td class=\"label\">voter mail address:</td>\n        <td class=\"value\" unit_level_id=\"3c363ea1-0f79-6ba0-c445-f69f87638d0b\">999 TESORO TRL, AUSTIN, TX 0000</td>\n    </tr>\n    \n    <tr><td class=\"label\">source:</td>\n        <td class=\"value\">voter file</td>\n    </tr>\n    <tr>\n        <td class=\"label\">match type:</td>\n        <td class=\"value\">name, address</td>\n    </tr>\n    <tr>\n        <td class=\"label\">date label:</td>\n        <td class=\"value\">voter file date</td>\n    </tr>\n</table>",
            "event_date": "2022-02-28",
            "observation_id": "22cb4e18885366ac185b1f4627bde650"
        },
        {
            "eligibility_rule_id": 99,
            "flag_name": "DMV Conflict at Previous",
            "display_html": "<table class=\"notestable\">\n    <tr><td class=\"object\" colspan=\"2\">Test User 999</td></tr>\n    <tr>\n        <td class=\"label\">date of birth:</td>\n        <td class=\"value\">1989-09-29</td>\n    </tr>\n    \n    <tr>\n        <td class=\"label\">DL address:</td>\n        <td class=\"value\" unit_level_id=\"3c363ea1-0f79-6ba0-c445-f69f87638d0b\">9999 TESORO TRAIL, AUSTIN, TX 123577</td>\n    </tr>\n    \n    <tr>\n        <td class=\"label\">county:</td>\n        <td class=\"value\">WILLIAMSON COUNTY, TX</td>\n    </tr>\n    \n    <tr>\n        <td class=\"label\">DL issue date:</td>\n        <td class=\"value\">2007-03-07</td>\n    </tr>\n    <tr>\n        <td class=\"label\">source:</td>\n        <td class=\"value\">driver license database</td>\n    </tr>\n    <tr>\n        <td class=\"label\">match type:</td>\n        <td class=\"value\">address, name</td>\n    </tr>\n    <tr>\n        <td class=\"label\">date label:</td>\n        <td class=\"value\">driver license, as of</td>\n    </tr>\n</table>",
            "event_date": "2022-05-30",
            "observation_id": "22d3e540f6cbc6a06c5a4e2b07f60b49"
        },
        {
            "eligibility_rule_id": 99,
            "flag_name": "DMV Conflict at Previous",
            "display_html": "<table class=\"notestable\">\n    <tr><td class=\"object\" colspan=\"2\">Test User 0000</td></tr>\n    <tr>\n        <td class=\"label\">date of birth:</td>\n        <td class=\"value\">1991-04-02</td>\n    </tr>\n    \n    <tr>\n        <td class=\"label\">DL address:</td>\n        <td class=\"value\" unit_level_id=\"3c363ea1-0f79-6ba0-c445-f69f87638d0b\">9999 TESORO TRAIL, AUSTIN, TX 123577</td>\n    </tr>\n    \n    <tr>\n        <td class=\"label\">county:</td>\n        <td class=\"value\">WILLIAMSON COUNTY, TX</td>\n    </tr>\n    \n    <tr>\n        <td class=\"label\">DL issue date:</td>\n        <td class=\"value\">2006-06-08</td>\n    </tr>\n    <tr>\n        <td class=\"label\">source:</td>\n        <td class=\"value\">driver license database</td>\n    </tr>\n    <tr>\n        <td class=\"label\">match type:</td>\n        <td class=\"value\">address, name</td>\n    </tr>\n    <tr>\n        <td class=\"label\">date label:</td>\n        <td class=\"value\">driver license, as of</td>\n    </tr>\n</table>",
            "event_date": "2022-05-30",
            "observation_id": "6b1147d621e540d4527ef39a69bd9e98"
        },
        {
            "eligibility_rule_id": 100,
            "flag_name": "DMV Conflict",
            "display_html": "<table class=\"notestable\">\n    <tr><td class=\"object\" colspan=\"2\">Test User 99</td></tr><tr>\n        <td class=\"label\">source:</td>\n        <td class=\"value\">driver license database</td>\n    </tr>\n    <tr>\n        <td class=\"label\">match type:</td>\n        <td class=\"value\">address, name</td>\n    </tr>\n    <tr>\n        <td class=\"label\">date label:</td>\n        <td class=\"value\">drivers license update</td>\n    </tr>\n</table>",
            "event_date": "2022-05-30",
            "observation_id": "d653464f9e3108aeb0953344f8eb038d"
        }
    ],
    "notes": [
        {
            "eligibility_rule_id": 4,
            "flag_name": "Voters registered at situs",
            "display_html": "<table class=\"notestable\">\n    <tr><td class=\"object\" colspan=\"2\">John Doe Jr.</td></tr>\n    <tr>\n        <td class=\"label\">voter date of birth:</td>\n        <td class=\"value\">1947-12-04</td>\n    </tr><tr>\n        <td class=\"label\">voter id:</td>\n        <td class=\"value\">1129642010</td>\n    </tr>\n    <tr>\n        <td class=\"label\">registration address:</td>\n        <td class=\"value\">11111 WESTERN LAKE DR, ROUND ROCK, TX 22222</td>\n    </tr>\n    <tr>\n        <td class=\"label\">mail address:</td>\n        <td class=\"value\" unit_level_id=\"6fe17179-ce06-1ea2-d506-9300523aefb1\">11111 WESTERN LAKE DR, ROUND ROCK, TX 22222</td>\n    </tr>\n    \n    <tr>\n        <td class=\"label\">registration date:</td>\n        <td class=\"value\">2016-12-03</td>\n    </tr>\n    <tr>\n        <td class=\"label\">source:</td>\n        <td class=\"value\">voter roll</td>\n    </tr>\n    <tr>\n        <td class=\"label\">match type:</td>\n        <td class=\"value\">situs address, registration address</td>\n    </tr>\n    <tr>\n        <td class=\"label\">date label:</td>\n        <td class=\"value\">registration as of</td>\n    </tr>\n</table>",
            "event_date": "2021-09-08",
            "observation_id": "7788ea57c99f2568ae7d8232f53453de"
        },
        {
            "eligibility_rule_id": 4,
            "flag_name": "Voters registered at situs",
            "display_html": "<table class=\"notestable\">\n    <tr><td class=\"object\" colspan=\"2\">Juan Dela Cruz</td></tr>\n    <tr>\n        <td class=\"label\">voter date of birth:</td>\n        <td class=\"value\">1990-06-26</td>\n    </tr><tr>\n        <td class=\"label\">voter id:</td>\n        <td class=\"value\">2177528687</td>\n    </tr>\n    <tr>\n        <td class=\"label\">registration address:</td>\n        <td class=\"value\">11111 WESTERN LAKE DR, ROUND ROCK, TX 22222</td>\n    </tr>\n    <tr>\n        <td class=\"label\">mail address:</td>\n        <td class=\"value\" unit_level_id=\"6fe17179-ce06-1ea2-d506-9300523aefb1\">11111 WESTERN LAKE DR, ROUND ROCK, TX 22222</td>\n    </tr>\n    \n    <tr>\n        <td class=\"label\">registration date:</td>\n        <td class=\"value\">2021-02-07</td>\n    </tr>\n    <tr>\n        <td class=\"label\">source:</td>\n        <td class=\"value\">voter roll</td>\n    </tr>\n    <tr>\n        <td class=\"label\">match type:</td>\n        <td class=\"value\">situs address, registration address</td>\n    </tr>\n    <tr>\n        <td class=\"label\">date label:</td>\n        <td class=\"value\">registration as of</td>\n    </tr>\n</table>",
            "event_date": "2022-02-28",
            "observation_id": "4210c14dd6265f5082ae014f6461ddd2"
        },
        {
            "eligibility_rule_id": 4,
            "flag_name": "Voters registered at situs",
            "display_html": "<table class=\"notestable\">\n    <tr><td class=\"object\" colspan=\"2\">Test User 9999</td></tr>\n    <tr>\n        <td class=\"label\">voter date of birth:</td>\n        <td class=\"value\">1992-07-24</td>\n    </tr><tr>\n        <td class=\"label\">voter id:</td>\n        <td class=\"value\">2176453892</td>\n    </tr>\n    <tr>\n        <td class=\"label\">registration address:</td>\n        <td class=\"value\">11111 WESTERN LAKE DR, ROUND ROCK, TX 22222</td>\n    </tr>\n    <tr>\n        <td class=\"label\">mail address:</td>\n        <td class=\"value\" unit_level_id=\"6fe17179-ce06-1ea2-d506-9300523aefb1\">11111 WESTERN LAKE DR, ROUND ROCK, TX 22222</td>\n    </tr>\n    \n    <tr>\n        <td class=\"label\">registration date:</td>\n        <td class=\"value\">2020-12-03</td>\n    </tr>\n    <tr>\n        <td class=\"label\">source:</td>\n        <td class=\"value\">voter roll</td>\n    </tr>\n    <tr>\n        <td class=\"label\">match type:</td>\n        <td class=\"value\">situs address, registration address</td>\n    </tr>\n    <tr>\n        <td class=\"label\">date label:</td>\n        <td class=\"value\">registration as of</td>\n    </tr>\n</table>",
            "event_date": "2022-02-28",
            "observation_id": "e3886112bab095ce4cbbf4a972156353"
        },
        {
            "eligibility_rule_id": 24,
            "flag_name": "Non-Owner Drivers License at Situs",
            "display_html": "<table class=\"notestable\">\n    <tr><td class=\"object\" colspan=\"2\">Jane Doe</td></tr>\n    <tr>\n        <td class=\"label\">date of birth:</td>\n        <td class=\"value\">1990-06-26</td>\n    </tr>\n    \n    <tr>\n        <td class=\"label\">DL address:</td>\n        <td class=\"value\">11111 WESTERN LAKE DR, ROUND ROCK, TX 22222</td>\n    </tr>\n    \n    <tr>\n        <td class=\"label\">DL issue date:</td>\n        <td class=\"value\">2007-06-01</td>\n    </tr>\n    <tr>\n        <td class=\"label\">source:</td>\n        <td class=\"value\">driver license database</td>\n    </tr>\n    <tr>\n        <td class=\"label\">match type:</td>\n        <td class=\"value\">address</td>\n    </tr>\n    <tr>\n        <td class=\"label\">date label:</td>\n        <td class=\"value\">driver license, as of</td>\n    </tr>\n</table>",
            "event_date": "2022-05-30",
            "observation_id": "bf48bfd7184994a6d86509ba9292c0a3"
        }
    ],
    "processed_on": "2024-02-09",
    "submitted_date": "2024-02-09",
    "tax_year": "2024,2025"
}

export const narSupportingDocuments = [
    {
        "document_type": "exemption-disabled-documentation-file",
        "file_name": "vscode-keyboard-shortcuts-windows.pdf",
        "file_size": 145725,
        "content_type": "application/pdf",
        "download_url": "https://tr-candidate-documents-dev.s3.amazonaws.com/application-attachments/8f572821-5641-4a05-a88b-12b2cadcb47d.pdf?response-content-disposition=inline%3B%20filename%3D%22vscode-keyboard-shortcuts-windows.pdf%22&AWSAccessKeyId=ASIA3YCB4M45CHKP7JG7&Signature=dxrh31AlLALpVNRtp8o%2FIgnggoI%3D&x-amz-security-token=IQoJb3JpZ2luX2VjEOT%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLXdlc3QtMiJGMEQCICATz6SWX4dFWv0xF51WKkbtPuw6HLiSPetmDAjp%2FZtEAiArbTzFYVELqt64TwzklrM%2FEkmltPSNFCha947GsXItuCr2AwjN%2F%2F%2F%2F%2F%2F%2F%2F%2F%2F8BEAAaDDgwNzU5MjAyNzk2MiIMXGxBEJVAeDVQiY0hKsoDEgqUNwvqG1jdqe%2B6eJ4bb7%2B%2FwwQQZ%2BDsHguLtc1svt4JPuC770p2qn90k1wa43Wxf7gTtZmD9%2BLNrQGkj86PgasyVBGmwLKtFUiAWEaDe%2FBpP2y9utyswH27WmTnQxzEIIrW9SGY8NYm4nxQCL9vZl2jCFEOVMrYk9tjozXEGSHiUybTuIU8I43LzhgDcgZ3HZJ%2BELgEOjDQSupUdOrVPLpPPu2sDDXueiWhgrNM1wziLvj6T1n%2BZ%2FwZYqjhdoDOVVxvfYY%2BM%2F60hM0L0M1SRWj5QtU4TweG%2FnZN3utWejWGmAMIly0XGmYOHeYl25euRuLjti2H6g54gQsXkj3CSJzxbNz6Yqu%2BHXHSbgti6LfBZA81qwQJE97KSfAk5OpNqDmFcn%2BaprH3PCbz%2B0Ajwfy%2Fyn8gIpi7UV7Ihf6qxh1dqSFjHD6BOZTxPt3Ln6LOGpq%2FphVjaomMbz4%2FxY6Iwo3%2FkzsghswnTF7FUXcEv7xrJPcm8OacoqBcCzmUxqp%2FKE6XHMWo5OXc24ec83bElViyBGSdp9gYPs2S8zmZCxr7Ck9EPo3HZ9E%2FZmFuggqq3%2Bq96gBCBgZ4b9FmkLJozyizN9ncU6ki0a4wrMT1rgY6pgHEMvOw903WuK8Pu6xZMf25KdRrY41Na4ULAwko4pdYAWfneyBhhY5DYLzQYxohtNxEPzFpWXOAotswVoKzw5X9%2FuXgKEEgxV%2BHyqBIRTsYm69%2BIZFlMQXDDjHix0ZCJwZB1HIYfNx1ZPbofy8DLqK9Lb2Nw9FvimMc%2BGcG%2BMak67ablUnEMfWSZVo5%2BFS3Vtd3ujlXufkPIrE4UWaDAwsR7%2BV2llX2&Expires=1709009011"
    },
    {
        "document_type": "property-owner-id-file-1",
        "file_name": "john_doe_drivers_license.png",
        "file_size": 2138521,
        "content_type": "image/png",
        "download_url": "https://tr-candidate-documents-dev.s3.amazonaws.com/application-attachments/ef501499-6020-4e3c-9d52-c5fd0ed4487a.png?response-content-disposition=inline%3B%20filename%3D%22john_doe_drivers_license.png%22&AWSAccessKeyId=ASIA3YCB4M45CHKP7JG7&Signature=IxzqM17eQzn2g5Ys84k4Zly0hAQ%3D&x-amz-security-token=IQoJb3JpZ2luX2VjEOT%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLXdlc3QtMiJGMEQCICATz6SWX4dFWv0xF51WKkbtPuw6HLiSPetmDAjp%2FZtEAiArbTzFYVELqt64TwzklrM%2FEkmltPSNFCha947GsXItuCr2AwjN%2F%2F%2F%2F%2F%2F%2F%2F%2F%2F8BEAAaDDgwNzU5MjAyNzk2MiIMXGxBEJVAeDVQiY0hKsoDEgqUNwvqG1jdqe%2B6eJ4bb7%2B%2FwwQQZ%2BDsHguLtc1svt4JPuC770p2qn90k1wa43Wxf7gTtZmD9%2BLNrQGkj86PgasyVBGmwLKtFUiAWEaDe%2FBpP2y9utyswH27WmTnQxzEIIrW9SGY8NYm4nxQCL9vZl2jCFEOVMrYk9tjozXEGSHiUybTuIU8I43LzhgDcgZ3HZJ%2BELgEOjDQSupUdOrVPLpPPu2sDDXueiWhgrNM1wziLvj6T1n%2BZ%2FwZYqjhdoDOVVxvfYY%2BM%2F60hM0L0M1SRWj5QtU4TweG%2FnZN3utWejWGmAMIly0XGmYOHeYl25euRuLjti2H6g54gQsXkj3CSJzxbNz6Yqu%2BHXHSbgti6LfBZA81qwQJE97KSfAk5OpNqDmFcn%2BaprH3PCbz%2B0Ajwfy%2Fyn8gIpi7UV7Ihf6qxh1dqSFjHD6BOZTxPt3Ln6LOGpq%2FphVjaomMbz4%2FxY6Iwo3%2FkzsghswnTF7FUXcEv7xrJPcm8OacoqBcCzmUxqp%2FKE6XHMWo5OXc24ec83bElViyBGSdp9gYPs2S8zmZCxr7Ck9EPo3HZ9E%2FZmFuggqq3%2Bq96gBCBgZ4b9FmkLJozyizN9ncU6ki0a4wrMT1rgY6pgHEMvOw903WuK8Pu6xZMf25KdRrY41Na4ULAwko4pdYAWfneyBhhY5DYLzQYxohtNxEPzFpWXOAotswVoKzw5X9%2FuXgKEEgxV%2BHyqBIRTsYm69%2BIZFlMQXDDjHix0ZCJwZB1HIYfNx1ZPbofy8DLqK9Lb2Nw9FvimMc%2BGcG%2BMak67ablUnEMfWSZVo5%2BFS3Vtd3ujlXufkPIrE4UWaDAwsR7%2BV2llX2&Expires=1709009011"
    }
]

export const narDupsApplication = [
    {
        "fips_code": "48491",
        "tru_id": "0c3b552f-e964-495f-8259-6a8ad288aa76",
        "application_id": "135",
        "parcel_num": "R12345",
        "applicant": "john doe",
        "flags": [
            "Non-USPS Address"
        ],
        "exemptions": [
            "Homestead"
        ],
        "submitted_date": "2024-01-17T13:37:42.295637+00:00",
        "application_status": "received",
        "application_status_recommendation": "inquire",
        "application_status_last_edited_by": null,
        "tax_year": "2024,2023,2022,2021",
        "late_application_check": true
    },
    {
        "fips_code": "48491",
        "tru_id": "dcf0beb4-fa45-473d-8f2a-c8f204655f26",
        "application_id": "107",
        "parcel_num": "R12345",
        "applicant": "john doe",
        "flags": [
            "Non-USPS Address"
        ],
        "exemptions": [
            "Disabled Person",
            "Homestead"
        ],
        "submitted_date": "2024-01-09T22:54:30.683062+00:00",
        "application_status": "approved",
        "application_status_recommendation": "inquire",
        "application_status_last_edited_by": "ed@trueroll.io",
        "tax_year": "2025,2024,2023,2022",
        "late_application_check": true
    }
]

export const narHistoryList = [
    {
        "id": 3731656,
        "created_by": "khurt",
        "update_details": "Test from list",
        "created_at": "2024-02-21",
        "update_type_id": 27
    },
    {
        "id": 3731655,
        "created_by": "khurt",
        "update_details": "Test 3",
        "created_at": "2024-02-21",
        "update_type_id": 27
    },
    {
        "id": 3731654,
        "created_by": "khurt",
        "update_details": "Test 2",
        "created_at": "2024-02-21",
        "update_type_id": 27
    }
]

export const mailList = [
    {
        "id": 9005,
        "mail_id": "2M365V75",
        "mail_status": "created",
        "mail_template": "Notice of Intent-Rental RXQ/CXQ",
        "mail_to": "Test User 1 (applicant); Test User 2 (applicant)",
        "mail_address": "9999 Golf View Dr",
        "mail_address2": "Englewood, FL 9999",
        "mailed_at": "",
        "note_for_template": "",
        "created_at": "2023-09-27T15:12:05+00:00",
        "updated_at": "2023-09-27T15:12:05+00:00",
        "expires_on": ""
    },
    {
        "id": 5766,
        "mail_id": "H8DPPNNL",
        "mail_status": "completed",
        "mail_template": "Notice of Intent to Lien",
        "mail_to": "Test User 00 (applicant); Test User 99 (applicant)",
        "mail_address": "0000 Palm Ave E",
        "mail_address2": "Nokomis, FL 9999",
        "mailed_at": "2023-03-01T00:00:00+00:00",
        "note_for_template": null,
        "created_at": "2023-02-07T14:03:40+00:00",
        "updated_at": "2023-04-13T10:42:30+00:00",
        "expires_on": "2023-03-31T00:00:00+00:00"
    }
]

export const flagList = [
    {
        "name": "Rental",
        "created_by": "--system event--",
        "event_date": "2022-06-04",
        "display_html": "<table class=\"notestable\">\n        <tr>\n            <td class=\"object\" colspan=\"2\">9999 CONSERVATION DR, AUSTIN, TX 9999</td>\n        </tr>\n        \n        <tr>\n            <td class=\"label\">rent:</td>\n            <td class=\"value\">$2,495.00</td>\n        </tr>\n        \n        \n        \n        <tr>\n            <td class=\"label\">listed on:</td>\n            <td class=\"value\">2022-06-04</td>\n        </tr>\n        \n        <tr>\n            <td class=\"label\">source:</td>\n            <td class=\"value\"><a href=\"https://www.zillow.com/\" target=\"_blank\">zillow.com</a></td> <!-- nosemgrep  Links are populated before the user reaches the HTML -->\n            </tr>\n        <tr>\n            <td class=\"label\">match type:</td>\n            <td class=\"value\">address</td>\n        </tr>\n        <tr>\n            <td class=\"label\">date label:</td>\n            <td class=\"value\">seen on</td>\n        </tr>\n    </table>",
        "observation_id": "55d780cfc8ab6a7880e4be8ff02526b7"
    },
    {
        "name": "Multiple Properties",
        "created_by": "--system event--",
        "event_date": "2020-04-10",
        "display_html": "<table class=\"notestable\">\n\n    <tr><td class=\"object\" colspan=\"2\" unit_level_id=\"6fa807d0-4975-c21b-9ce0-96cc4274bd7f\">9999 FOLCHI DR, CINCINNATI, OH 9999</td></tr><tr><td class=\"label\">county:</td>\n        <td class=\"value\">HAMILTON COUNTY, OH</td></tr>\n\n    <tr><td class=\"label\">parcel:</td>\n        <td class=\"value\">9999999999</td></tr>\n    <tr><td class=\"label\">property classification:</td>\n        <td class=\"value\">single family residence</td></tr>\n    <tr><td class=\"label\">owner name:</td>\n        <td class=\"value\">TEST USER</td></tr>\n\n    <tr><td class=\"label\">mailing address:</td>\n        <td class=\"value\">99999 CAMBRIDGE DR, MASON, OH 9999</td></tr>\n    <tr><td class=\"label\">assessed value:</td>\n        <td class=\"value\">$21,421</td></tr>\n    \n    <tr><td class=\"label\">market value:</td>\n        <td class=\"value\">$61,200</td></tr>\n    \n    <tr><td class=\"label\">deed date:</td>\n        <td class=\"value\">2015-01-15</td></tr>\n    <tr><td class=\"label\">source:</td>\n        <td class=\"value\">national parcel database</td></tr>\n\n    <tr><td class=\"label\">match type:</td>\n        <td class=\"value\">rare first &amp; last name</td></tr>\n\n    <tr><td class=\"label\">date label:</td>\n        <td class=\"value\">last refresh</td></tr>\n\n</table>",
        "observation_id": "9962992087c3d093792155c2a42f26a9"
    },
    {
        "name": "I'm a User Created Flag",
        "created_by": "auth0|nobody",
        "event_date": "2020-04-10",
        "display_html": "<div>Hello, World!</div>",
        "observation_id": null
    }
] as FlagsAPIResponse[]

export const noteList = [
    {
        "name": "Custom Note",
        "display_html": "\n    <table class=\"notestable\">\n    \n    <tr>\n        <td class=\"label\">codes:</td>\n        <td class=\"value\">O</td>\n    </tr>\n    <tr>\n        <td class=\"label\">source:</td>\n        <td class=\"value\">SCPA marriage flag</td>\n    </tr>\n    <tr>\n        <td class=\"label\">match type:</td>\n        <td class=\"value\">parcel number</td>\n    </tr>\n    <tr>\n        <td class=\"label\">date label:</td>\n        <td class=\"value\">generated on</td>\n    </tr>\n    </table>\n    ",
        "event_date": "2023-06-06",
        "observation_id": "bffedb8c45927827844704d83a22898c"
    },
    {
        "name": "Custom Note",
        "display_html": "\n    <table class=\"notestable\">\n    \n    <tr>\n        <td class=\"label\">codes:</td>\n        <td class=\"value\">O</td>\n    </tr>\n    <tr>\n        <td class=\"label\">source:</td>\n        <td class=\"value\">SCPA marriage flag</td>\n    </tr>\n    <tr>\n        <td class=\"label\">match type:</td>\n        <td class=\"value\">parcel number</td>\n    </tr>\n    <tr>\n        <td class=\"label\">date label:</td>\n        <td class=\"value\">generated on</td>\n    </tr>\n    </table>\n    ",
        "event_date": "2023-06-06",
        "observation_id": "c7a7767fedca4a4da2149dd18a3d2162"
    },
] as NotesAPIResponse[]

export const historyList = [
    {
        "id": 2934020,
        "created_by": "--system event--",
        "update_details": "Candidate moved from: Snooze to: Inbox based on Snooze Date",
        "created_at": "2024-01-02",
        "update_type_id": 3
    },
    {
        "id": 2438226,
        "created_by": "--taxroll updater--",
        "update_details": "**Exemption change**<br>from: <br>Constitutional Homestead | Additional Homestead Exemption<br>to: Soh 100% | Hxu 100% | Constitutional Homestead | Additional Homestead Exemption<br>",
        "created_at": "2023-10-25",
        "update_type_id": 20
    },
] as HistoryAPIResponse[]

export const taxRollList = [
    {
        "tru_id": "08a88d2a-d565-4f65-a4ef-f187680104de",
        "parcel_num": "99999999",
        "owner": "TEST USER 1",
        "situs": "99999 Montaque Dr, Austin, 9999",
        "mail": "99999 Longitude Trl, Austin, Tx 9999",
        "exemptions": "Homestead",
        "deed_date": "2014-1-1",
        "county_lookup_url": "httpR302253",
        "market_value": "$411,394.00",
        "assessed_value": "$411,394.00",
        "taxable_value": "$411,394.00",
        "flags": [
            "Address Change",
            "DMV Conflict",
            "Lives Elsewhere",
            "Multiple HSE Within Jurisdiction",
            "Situs and Mail Differ",
            "Voter Conflict"
        ],
        "queue": "archive",
        "prev_queue": "snooze",
        "has_history": true,
        "released_ir": false,
        "updated_on": "2023-06-13T12:20:03+00:00",
        "final_outcome": "Exemption Removed",
        "snooze_until": "2023-06-28T00:00:00+00:00",
        "lien_or_back_taxes": false,
        "unqualified_start_year": null,
        "identified_on": "2022-09-02T19:12:34.398939+00:00",
        "assigned_to": null,
        "assigned_to_id": null,
        "priority_score": 5,
        "owner_type": 1,
        "parcel_created": "2022-08-29T19:47:04.971462+00:00",
        "parcel_updated": "2023-07-07T21:50:48.718230+00:00",
        "labels": null
    },
    {
        "tru_id": "164553e9-ba4f-4d74-8e67-5701380c0094",
        "parcel_num": "099999",
        "owner": "TEST USER 2",
        "situs": "111 Conservation Dr, Austin, 12345",
        "mail": "111 Conservation Dr, Austin, Tx 12345",
        "exemptions": "Homestead",
        "deed_date": "2021-1-1",
        "county_lookup_url": "httpR594815",
        "market_value": "$371,514.00",
        "assessed_value": "$371,514.00",
        "taxable_value": "$371,514.00",
        "flags": [
            "Address Change",
            "Lives Elsewhere",
            "Multiple HSE Out of Jurisdiction",
            "Multiple Properties",
            "Rental"
        ],
        "queue": "inbox",
        "prev_queue": "snooze",
        "has_history": true,
        "released_ir": false,
        "updated_on": "2023-12-01T23:22:24.378659+00:00",
        "final_outcome": null,
        "snooze_until": null,
        "lien_or_back_taxes": false,
        "unqualified_start_year": null,
        "identified_on": "2022-09-02T19:25:04.928887+00:00",
        "assigned_to": null,
        "assigned_to_id": null,
        "priority_score": 5,
        "owner_type": 2,
        "parcel_created": "2022-08-29T19:47:04.971462+00:00",
        "parcel_updated": "2023-07-07T21:50:48.718230+00:00",
        "labels": null
    }
]

export const omnisearchData = {
    "unit_level_id": "f830bfb4-607c-ec17-71b1-24b31d7f6c77",
    "address": "9999 Pagedale Dr, Cedar Park, TX 9999",
    "dpv": "Entire address valid\nSingle Unit Street Address",
    "county": "WILLIAMSON, TX (48491)",
    "county_lookup_url": "https://tax.wilco.org",
    "d2p_url": "http://search.wcad.org",
    "owner": "TEST USER",
    "ownership_details": null,
    "parcel_num": "1-23-456-7890-123-12345",
    "parcel_num_alt": null,
    "mail_address": "9999 Pagedale Dr, Cedar Park, TX 99999",
    "mail_unit_level_id": "f830bfb4-607c-ec17-71b1-24b31d7f6c77",
    "mail_county_lookup_url": "https://tax.wilco.org/",
    "mail_county": "WILLIAMSON, TX (48491)",
    "exemptions": "Homestead",
    "deed_date": "2016-03-29",
    "val_assessed": 401304,
    "val_market": 401304,
    "prop_use": "SINGLE FAMILY RESIDENCE",
    "freshness": "2022-12-12",
    "listed": null,
    "sold": "2016-03-25 | zillow.com",
    "voter": "2004-11-02 | test user\n2016-11-08 | test user2",
    "vacancy": null
}

export const subMenuSettings = {
    selectedSubPage: "user-preference",
    menu: [
        {
            "menu": "User Preferences",
            "page": "user_preference",
            "url": "user-preference",
            "requiredPermissions": [
                "read:customers"
            ]
        },
    ] as SubMenuConfig[],
    header: "Settings"
}

export const subMenuAdmin = {
    selectedSubPage: "workflow_mail_module",
    menu: [
        {
            "menu": "Workflow: Mail Module",
            "page": "workflow_mail_module",
            "url": "mail-module",
            "requiredPermissions": ["read:postal_contact_workflows", "update:postal_contact_workflows", "create:postal_contact_workflows"]
        },
        {
            "menu": "Config: Labels",
            "page": "config_labels",
            "url": "labels",
            "requiredPermissions": ["create:labels"]
        },
        {
            "menu": "Config: Mail Templates",
            "page": "config_mail_templates",
            "url": "mail-templates",
            "requiredPermissions": ["update:mailing_templates", "create:mailing_templates"]
        },
        {
            "menu": "Config: Change Customer",
            "page": "config_change_customer",
            "url": "change-customer",
            "requiredPermissions": ["read:customers"]
        },
        {
            "menu": "Config: Users",
            "page": "config_users",
            "url": "users",
            "requiredPermissions": ["create:users", "update:users"]
        },
        {
            "menu": "Update: Assign Candidate",
            "page": "update_assign_candidate",
            "url": "assign-candidate",
            "requiredPermissions": ["update:candidates"]
        },
        {
            "menu": "Report: New Assessed Value Estimate",
            "page": "report_new_assessed_value",
            "url": "report-nave",
            "requiredPermissions": ["read:reports_assessed_value"]
        },
        {
            "menu": "Report: Download Unclaimed List",
            "page": "dl_unclaimed_list",
            "url": "dl-unclaimed-list",
            "requiredPermissions": ["read:reports_unclaimed"]
        },
        {
            "menu": "Unclaimed Exemption Data Request",
            "page": "unclaimed_exmpt_data_req",
            "url": "unclaimed-exmpt-data-request",
            "requiredPermissions": []
        },
        {
            "menu": "Onboarding",
            "page": "onboarding",
            "url": "onboarding",
            "requiredPermissions": ["read:ingestion_configs", "update:ingestion_configs"]
        }
    ],
    header: "Admin"
};

export const docketReportInfo: SitusInfo = {
    "tru_id": "164553e9-ba4f-4d74-8e67-5701380c0094",
    "situs_full_taxroll": "999 Conservation Dr, Austin, 123456",
    "parcel_num": "R594815",
    "owner_name": "Test User 1 & Test User 2 & Test User 3",
    "legal_description": "12345 - GREAT OAKS AT BRUSHY CREEK TOWNHOMES, BUILDING 999, UNIT 999, 99.99% COMMON AREA INTEREST",
    "exmpt_description": "Homestead",
    "market_value": "$371,514.00",
    "assessed_value": "$371,514.00",
    "deed_date": "2021-1-1",
    "county_lookup_url": "httpR594815",
    "mail_full_taxroll": "999 Conservation Dr, Austin, Tx 123456",
    "mail_county": "Williamson",
    "taxable_value": "$371,514.00",
    "recovered_amount": null,
    "queue": "inbox",
    "reason": "Snooze Alarm",
    "unqualified_start_year": null,
    "lien_or_back_taxes": false,
    "snooze_until": null,
    "final_outcome": null,
    "assigned_user_id": "",
}

export const docketReportFlags = [
    {
        "name": "Rental",
        "created_by": "--system event--",
        "event_date": "2022-06-04",
        "display_html": "<table class=\"notestable\">\n <tr>\n <td class=\"object\" colspan=\"2\">22 CONSERVATION DR, AUSTIN, TX 9999</td>\n </tr>\n \n <tr>\n <td class=\"label\">rent:</td>\n <td class=\"value\">$2,495.00</td>\n </tr>\n \n \n \n <tr>\n <td class=\"label\">listed on:</td>\n <td class=\"value\">2022-06-04</td>\n </tr>\n \n <tr>\n <td class=\"label\">source:</td>\n <td class=\"value\"><a href=\"https://www.zillow.com\" target=\"_blank\">zillow.com</a></td> <!-- nosemgrep Links are populated before the user reaches the HTML -->\n </tr>\n <tr>\n <td class=\"label\">match type:</td>\n <td class=\"value\">address</td>\n </tr>\n <tr>\n <td class=\"label\">date label:</td>\n <td class=\"value\">seen on</td>\n </tr>\n </table>",
        "created_by_id": "4",
        "observation_id": "55d780cfc8ab6a7880e4be8ff02526b7"
    },
    {
        "name": "Multiple Properties",
        "created_by": "--system event--",
        "event_date": "2020-04-10",
        "display_html": "<table class=\"notestable\">\n <tr>\n <td class=\"object\" colspan=\"2\">111 CONSERVATION DR, AUSTIN, TX 9999</td>\n </tr>\n \n <tr>\n <td class=\"label\">rent:</td>\n <td class=\"value\">$2,495.00</td>\n </tr>\n \n \n \n <tr>\n <td class=\"label\">listed on:</td>\n <td class=\"value\">2022-06-04</td>\n </tr>\n \n <tr>\n <td class=\"label\">source:</td>\n <td class=\"value\"><a href=\"https://www.zillow.com\" target=\"_blank\">zillow.com</a></td> <!-- nosemgrep Links are populated before the user reaches the HTML -->\n </tr>\n <tr>\n <td class=\"label\">match type:</td>\n <td class=\"value\">address</td>\n </tr>\n <tr>\n <td class=\"label\">date label:</td>\n <td class=\"value\">seen on</td>\n </tr>\n </table>",
        "created_by_id": "4",
        "observation_id": "9962992087c3d093792155c2a42f26a9"
    }
]

export const docketReportNotes = [{
    "name": "Address History",
    "display_html": "<table class=\"notestable\">\n <tr><td class=\"object\" colspan=\"2\">Test User 9999</td></tr>\n \n <tr>\n <td class=\"label\" unit_level_id=\"dc1d9c5b-be9c-5b4a-57ad-3778d91c6aac\">22222 HARMON AVE APT 00, AUSTIN, TX</td>\n <td class=\"value\">2018-10 - 2022-09</td>\n </tr>\n \n <tr>\n <td class=\"label\">111 CONSERVATION DR # 111, AUSTIN, TX</td>\n <td class=\"value\">2022-02 - 2022-06</td>\n </tr>\n \n <tr>\n <td class=\"label\">222 CONSERVATION DR, AUSTIN, TX</td>\n <td class=\"value\">2018-10 - 2022-06</td>\n </tr>\n \n <tr>\n <td class=\"label\" unit_level_id=\"49607051-ef1d-f696-475d-b21296e44830\">333333 LAGOS WAY, CATHEDRAL CITY, CA</td>\n <td class=\"value\">2022-05 - 2022-05</td>\n </tr>\n \n <tr>\n <td class=\"label\" unit_level_id=\"37b22dc1-26e2-e179-ec02-3e7c588064ba\">00000 HARMON AVE APT 0000, AUSTIN, TX</td>\n <td class=\"value\">2018-10 - 2020-12</td>\n </tr>\n \n <tr>\n <td class=\"label\" unit_level_id=\"91b4c626-0f9e-f14a-05cd-0189ae15f8c5\">0000 E CARMEL DR, CARMEL, IN</td>\n <td class=\"value\">2019-12 - 2019-12</td>\n </tr>\n \n <tr>\n <td class=\"label\" unit_level_id=\"6012f520-e98f-d7ba-e77a-62632e8dd93d\">225 PROVIDENCE BLVD, CARMEL, IN</td>\n <td class=\"value\">2016-11 - 2018-12</td>\n </tr>\n \n <tr>\n <td class=\"label\" unit_level_id=\"71ef020c-41d5-547c-1323-a5510b3d2489\">99999 MICHIGAN AVE NW, ROANOKE, VA</td>\n <td class=\"value\">2018-10 - 2018-10</td>\n </tr>\n \n <tr>\n <td class=\"label\">source:</td>\n <td class=\"value\">credit headers</td>\n </tr>\n <tr>\n <td class=\"label\">match type:</td>\n <td class=\"value\">owner, address</td>\n </tr>\n <tr>\n <td class=\"label\">date label:</td>\n <td class=\"value\">credit header last updated</td>\n </tr>\n</table>",
    "event_date": "2022-09-11",
    "observation_id": "f7b466d38d981e65da792338a6aa19e5"
},
{
    "name": "Move From Address to Situs",
    "display_html": "<table class=\"notestable\">\n <tr><td class=\"object\" colspan=\"2\">Test User 9999</td></tr>\n <tr>\n <td class=\"label\">date of birth:</td>\n <td class=\"value\">1985-10-13</td>\n </tr>\n <tr>\n <td class=\"label\">address:</td>\n <td class=\"value\" unit_level_id=\"dc1d9c5b-be9c-5b4a-57ad-3778d91c6aac\">77777 HARMON AVE APT 5, AUSTIN, TX 7777</td>\n </tr>\n <tr>\n <td class=\"label\">postal class:</td>\n <td class=\"value\">residential</td>\n </tr>\n \n <tr>\n <td class=\"label\">first seen at address:</td>\n <td class=\"value\">2018-10-01</td>\n </tr>\n <tr>\n <td class=\"label\">credit address, as of:</td>\n <td class=\"value\">2022-09-11</td>\n </tr>\n <tr>\n <td class=\"label\">source:</td>\n <td class=\"value\">credit headers</td>\n </tr>\n <tr>\n <td class=\"label\">match type:</td>\n <td class=\"value\">name, address, dob</td>\n </tr>\n <tr>\n <td class=\"label\">date label:</td>\n <td class=\"value\">last seen at address</td>\n </tr>\n</table>",
    "event_date": "2022-09-09",
    "observation_id": "870212e932722d9805dcae8242ef3e6d"
}
]

export const docketReportHistory = [
    {
        "id": 3016426,
        "created_by": "--taxroll updater--",
        "update_details": "**Mail address change**<br>from: 2222 Conservation Dr<br>to: 999 Harmon Ave, Apt 5",
        "created_at": "2024-01-11",
        "update_type_id": 20
    },
    {
        "id": 2821992,
        "created_by": "--system event--",
        "update_details": "Candidate moved from: Snooze to: Inbox based on Snooze Date",
        "created_at": "2023-12-01",
        "update_type_id": 3
    },
    {
        "id": 870337,
        "created_by": "P Harper",
        "update_details": "Candidate moved from: Inbox to Snooze<br>Snooze Until: 2023-07-20<br>Note: ABC DEFG LTR REQ 06/20/2023",
        "created_at": "2023-06-13",
        "update_type_id": 2
    }
]


export const investigationReportData: SitusInfo = {
    "tru_id": "58669507-4fd9-484a-86af-265615887b61",
    "situs_full_taxroll": "999 Golf View Dr, Englewood, 99999",
    "parcel_num": "0486010024",
    "owner_name": "John Doe Jane Doe User JAY",
    "legal_description": "LOT 999 ENGLEWOOD GOLF COURSE UNIT 1",
    "exmpt_description": "Additional Homestead Exemption | Veterans-Service Connected Disability 10% To 99% | Constitutional Homestead | Hxu 100% | Soh 100%",
    "market_value": "$361,100.00",
    "assessed_value": "$238,384.00",
    "deed_date": "2021-1-1",
    "county_lookup_url": "https://www.sc-pa.com",
    "mail_full_taxroll": "9999 Golf View Dr, Englewood, Fl 9999",
    "mail_county": "Sarasota",
    "taxable_value": "$183,384.00",
    "recovered_amount": null,
    "queue": "inbox",
    "reason": null,
    "unqualified_start_year": null,
    "lien_or_back_taxes": null,
    "snooze_until": null,
    "final_outcome": null,
    "assigned_user_id": "",
}

export const investigationReportSummary = [
    {
        "key": "Recommendation Summary",
        "value": "User and User 2 acquired the subject property on October 7, 2020 (Instrument #2020141328) as husband and wife.&nbsp;<br />\r\n<br />\r\nOur research indicates User and User 2 may be domiciled at 190 N 100 W, UT. It appears the subject property is being utilized as a rental.&nbsp;<br />\r\n<br />\r\nWe recommend removal of the homestead exemption for tax year 2022.&nbsp;<br />\r\n<br />\r\nDOR: 0100 - Single Family Detached / RSF1 - Residential, Single Family"
    },
    {
        "key": "First Unqualified Year",
        "value": "2022"
    },
    {
        "key": "Primary Violation Reason",
        "value": "Rental"
    },
    {
        "key": "Owner/Applicant Evidence",
        "value": "<p>Sevier County Utah confirms User and User 2&rsquo;s ownership of 190 N 100 W (Parcel # 1-M20-88).<br />\r\n<br />\r\nSevier County, Utah, confirms User and User 2 acquired parcel number 1-M20-88 on May 11, 2022.<br />\r\n<br />\r\n&nbsp;</p>"
    },
    {
        "key": "Situs Residents Evidence",
        "value": "<p>Homesnap.com shows the subject property having been listed as an annual rental beginning on December 9, 2021 and rented as of February 24, 2022.</p>"
    }
]

export const investigationReportTaxRoll = [
    {
        "key": "Parcel Number",
        "value": "0486010024"
    },
    {
        "key": "Owner/Applicant Name(s)",
        "value": "GEISLER User 2 BEATRIZ GEISLER User JAY"
    },
    {
        "key": "Situs",
        "value": "14 Golf View Dr, Englewood, 34223"
    },
    {
        "key": "Mail",
        "value": "14 Golf View Dr, Englewood, Fl 34223"
    },
    {
        "key": "Exemption(s)",
        "value": "Additional Homestead Exemption | Veterans-Service Connected Disability 10% To 99% | Constitutional Homestead | Hxu 100% | Soh 100%"
    },
    {
        "key": "Legal",
        "value": "LOT 14 ENGLEWOOD GOLF COURSE UNIT 1 "
    }
]

export const investigationReportDetail = [
    { "key": "TrueReviewer", "value": "RW" },
    { "key": "Report Created On", "value": "1/04/2023" }
]

export const investigationReportDocumentList = [
    {
        "link": "https://drive.google.com/",
        "notes": "Test Note 1",
        "tru_id": "58669507-4fd9-484a-86af-265615887b61",
        "user_id": "384",
        "document_name": "Homestead Exemption in place",
        "id": "18ee8050-1da2-4eee-9084-522e26844147"
    },
    {
        "link": "https://drive.google.com/",
        "notes": "Test Note 2",
        "tru_id": "58669507-4fd9-484a-86af-265615887b61",
        "user_id": "384",
        "document_name": "Homesnap.com rental ad",
        "id": "7fede3ca-845c-4d7b-a7a9-e342a3c282d4"
    },
    {
        "link": "https://drive.google.com/",
        "notes": "Test Note 3",
        "tru_id": "58669507-4fd9-484a-86af-265615887b61",
        "user_id": "384",
        "document_name": "Ownership of 190 N 100 W, UT",
        "id": "c078c2cf-7df9-46ed-8c61-a22cd8cf57f2"
    },
    {
        "link": "https://drive.google.com/",
        "notes": "Test Note 4",
        "tru_id": "58669507-4fd9-484a-86af-265615887b61",
        "user_id": "384",
        "document_name": "Acquisition date for Utah property", "id": "f646e184-7e4e-4286-8aa2-fe99c6b34567"
    }
]

export const customerAccess = {
    "id": "64",
    "customer_id": "64",
    "fips_code": "12115",
    "name": "Sarasota County, FL",
    "schema_name": "fl_sarasota_county",
    "state": "FL"
}

export const flagTypeDropdown = [
    { "id": 18, "name": "Active Investigation" },
    { "id": 9, "name": "Address Change" },
    { "id": 11, "name": "Corporate Owner" },
    { "id": 1, "name": "Deceased" },
    { "id": 8, "name": "DMV Conflict" },
    { "id": 10, "name": "Lives Elsewhere" },
    { "id": 16, "name": "Multiple HSE Out of Jurisdiction" },
    { "id": 15, "name": "Multiple HSE Within Jurisdiction" },
    { "id": 17, "name": "Multiple Properties" },
    { "id": 20, "name": "Other" },
    { "id": 4, "name": "Out of County Mail" },
    { "id": 5, "name": "Out of State Mail" },
    { "id": 2, "name": "Rental" },
    { "id": 3, "name": "Situs and Mail Differ" },
    { "id": 19, "name": "Vacancy" },
    { "id": 7, "name": "Voter Conflict" }
]

export const candidateLabelListDropdown = [
    { "id": 439, "label_name": "Divorced 2022", "created_at": "2023-01-23T11:04:14", "updated_at": null },
    { "id": 406, "label_name": "Immediate Priority", "created_at": "2022-11-17T11:38:21", "updated_at": null },
    { "id": 411, "label_name": "Ineligible Years-Verifiy", "created_at": "2022-11-21T12:36:50", "updated_at": null },
    { "id": 410, "label_name": "Mailing Address Conflict", "created_at": "2022-11-21T11:20:47", "updated_at": null },
    { "id": 438, "label_name": "Married 2023", "created_at": "2023-01-23T11:04:04", "updated_at": null },
    { "id": 1, "label_name": "Post Database Update Test (Edited)", "created_at": "2023-10-06T21:34:47.505046", "updated_at": "2023-11-15T19:42:09.580441" },
    { "id": 488, "label_name": "Preliminary -SCPA", "created_at": "2023-06-08T14:00:43", "updated_at": null },
    { "id": 407, "label_name": "Special Mailing Address", "created_at": "2022-11-17T11:38:46", "updated_at": null },
    { "id": 401, "label_name": "TR awaiting HSE confirm", "created_at": "2022-10-28T10:42:53", "updated_at": "2022-11-03T11:59:11" }
]

export const candidateLabelList = [
    { "id": 39298, "tru_id": "df65714e-7d4d-4174-a138-5d847ce26c39", "label_name": "Compliance Review", "label_id": 408, "created_by": "Trisha L Oliver", "created_at": "2023-04-14T09:33:43+00:00", "updated_at": "2023-04-14T09:33:43+00:00", "deleted": false },
    { "id": 68661, "tru_id": "df65714e-7d4d-4174-a138-5d847ce26c39", "label_name": "Divorced 2022", "label_id": 439, "created_by": "khurt", "created_at": "2024-03-02T04:45:09.751404+00:00", "updated_at": "2024-03-02T04:45:09.751406+00:00", "deleted": false }
]

export const roleList = [
    { "id": "rol_8iSPNYJHzCRtIJpv", "name": "Client Admin", "slug": "client_admin", "description": "Admin level at the client" },
    { "id": "rol_fGaokMv0yoOZzhQR", "name": "Client Investigator", "slug": "client_investigator", "description": "Restricted access to admin functions but open access to candidates" },
    { "id": "rol_6UMvhkoXITM270C8", "name": "Client Specialist", "slug": "client_specialist", "description": "A focused client level account with limited access" }
]

export const mailModuleAll = [
    {
        "id": 11290,
        "mail_id": "OZJVMJNU",
        "mail_template": "Cert Removal Letter",
        "mail_template_id": 739,
        "updated_at": "2024-01-11T20:04:50.513978+00:00",
        "situs_address": "12345 Rusted Nail Cv, Austin, 4321",
        "mail_address": "12345 Rusted Nail Cv, Austin, 4321",
        "mailed_at": "2024-01-11T20:04:50.513978+00:00",
        "expires_on": "2024-01-11T20:04:50.513978+00:00",
        "tru_id": "0ea8e2da-1540-4c87-9747-666dcbc24d8d",
        "mail_status": "created",
        "created_at": "2024-01-11T20:04:50.513978+00:00",
        "mail_to": "Juan Dela Cruz",
        "mail_address2": "12345 Rusted Nail Cv, Austin, 4321",
        "note_for_template": "Test",
        "candidate_queue": "unqualified",
        "parcel_number": "R062072",
        "sold_at": null,
        "candidate_deactivated_at": "2024-01-11T20:04:50.513978+00:00"
    }
]

export const mailModuleNotMailed = [
    {
        "id": 11290,
        "mail_id": "OZJVMJNU",
        "mail_template": "Cert Removal Letter",
        "mail_template_id": 739,
        "updated_at": "2024-01-11T20:04:50.513978+00:00",
        "situs_address": "1234 Rusted Nail Cv, Austin, 4567",
        "mail_address": "1234 Rusted Nail Cv, Austin, 4567",
        "mailed_at": "2024-01-11T20:04:50.513978+00:00",
        "expires_on": "2024-01-11T20:04:50.513978+00:00",
        "tru_id": "0ea8e2da-1540-4c87-9747-666dcbc24d8d",
        "mail_status": "created",
        "created_at": "2024-01-11T20:04:50.513978+00:00",
        "mail_to": "Jane & Johnny Doe",
        "mail_address2": "2024-01-11T20:04:50.513978+00:00",
        "note_for_template": "test",
        "candidate_queue": "unqualified",
        "parcel_number": "R062072",
        "sold_at": "2024-01-11T20:04:50.513978+00:00",
        "candidate_deactivated_at": "2024-01-11T20:04:50.513978+00:00"
    },
    {
        "id": 11289,
        "mail_id": "R2F8ZXV5",
        "mail_template": "Cert Removal Letter",
        "mail_template_id": 739,
        "updated_at": "2024-01-11T16:23:44.848824+00:00",
        "situs_address": "1234 Lignite Dr, Jarrell, 4321",
        "mail_address": "1234 Vinewood St",
        "mailed_at": "2024-01-11T20:04:50.513978+00:00",
        "expires_on": "2024-01-11T20:04:50.513978+00:00",
        "tru_id": "05aa29dc-8b69-4d33-9879-15fabfdd2838",
        "mail_status": "created",
        "created_at": "2024-01-11T16:23:44.848824+00:00",
        "mail_to": "John Doe",
        "mail_address2": "Amarillo, TX 79108",
        "note_for_template": "test",
        "candidate_queue": "unqualified",
        "parcel_number": "R498914",
        "sold_at": "2024-01-11T20:04:50.513978+00:00",
        "candidate_deactivated_at": "2024-01-11T20:04:50.513978+00:00"
    }
]

export const mailModuleTableColumns = [
    {
        "title": "",
        "formatter": "rowSelection",
        "titleFormatter": "rowSelection",
        "headerSort": false,
        "visible": true
    },
    {
        "title": "Candidate",
        "field": "name",
        "width": "7vh",
        "headerSort": false
    },
    {
        "title": "Parcel",
        "field": "parcel_number",
        "width": "7vh",
        "headerSort": false
    },
    {
        "title": "Template",
        "field": "mail_template",
        "width": "7vh",
        "headerSort": false
    },
    {
        "title": "Queue",
        "field": "candidate_queue",
        "width": "8vh",
        "headerSort": false
    },
    {
        "title": "Mail To",
        "field": "mail_to",
        "width": "7vh",
        "headerSort": false
    },
    {
        "title": "Mail 1 & 2",
        "field": "mail_address",
        "width": "8vh",
        "headerSort": false
    },
    {
        "title": "Situs",
        "field": "situs_address",
        "width": "8vh",
        "headerSort": false
    },
    {
        "title": "Mailer Status",
        "field": "mail_status",
        "width": "8vh",
        "headerSort": false
    },
    {
        "title": "Created On",
        "field": "created_at",
        "width": "8vh",
        "headerSort": false
    },
    {
        "title": "Mailed On",
        "field": "mailed_at",
        "width": "8vh",
        "headerSort": false
    },
    {
        "title": "Note",
        "field": "note_for_template",
        "width": "8vh",
        "headerSort": false
    },
    {
        "title": "ID",
        "field": "mail_id",
        "width": "8vh",
        "headerSort": false
    }
] as ColumnDefinition[]

export const mailModuleQueue = [
    "monitor",
    "inbox",
    "questionnaire",
    "unqualified",
    "snooze",
    "archive"
]

export const mailModuleMailStatus = [
    "created",
    "mailed",
    "completed",
    "expired",
    "mailing issues",
    "collections"
]

export const candidateDetailsOwners = [
    {
        "tru_id": "7fd8e49f-8862-4418-8e63-196fcb1f25ae",
        "first_name": "juan",
        "middle_name": "dela",
        "last_name": "cruz",
        "suffix": null,
        "full_name": "juan dela cruz",
        "commonality_score": 0.4744892,
        "birth_year": null,
        "birth_month": null,
        "birth_day": null,
        "birth_date": "1900-01-01",
        "city": "Egan",
        "state": "LA",
        "state_name": "Louisiana"
    },
    {
        "tru_id": "7fd8e49f-8862-4418-8e63-196fcb1f25ae",
        "first_name": "john",
        "middle_name": "bryan",
        "last_name": "doe",
        "suffix": null,
        "full_name": "john bryan doe",
        "commonality_score": 0.2684457,
        "birth_year": null,
        "birth_month": null,
        "birth_day": null,
        "birth_date": "1900-01-01",
        "city": "Egan",
        "state": "LA",
        "state_name": "Louisiana"
    }
]

export const candidateList = [
    {
        "exemptions": "Disabled Person | Homestead",
        "prev_queue": "unreleased_ir",
        "identified_on": "2022-09-02T19:12:35.897935+00:00",
        "last_queue_change": "2022-09-02T19:12:35.897935+00:00",
        "labels": [
            "Reapplications",
            "Reapplications 2",
        ],
        "disabled_veteran_exmpt_flag": false,
        "deed_date": "2015-1-1",
        "has_history": true,
        "assigned_to": null,
        "queue_priority": 8,
        "parcel_num": "R075425",
        "county_lookup_url": "httpR075425",
        "released_ir": true,
        "assigned_to_id": null,
        "has_ir": true,
        "tru_id": "57fbcaec-fb8b-4af8-bb8f-961ae2263620",
        "market_value": "$383,341.00",
        "updated_on": "2024-03-29T15:02:26.792108+00:00",
        "priority_score": 5,
        "first_unqualified_year": 2021,
        "owner": "Jane Doe",
        "assessed_value": "$378,182.00",
        "final_outcome": null,
        "homestead_exmpt_flag": true,
        "senior_exmpt_flag": false,
        "situs": "12345 Split Rail Pkwy, Austin, 54321",
        "taxable_value": "$383,341.00",
        "snooze_until": null,
        "owner_type": 1,
        "seniorfreeze_exmpt_flag": false,
        "mail": "12345 Split Rail Pkwy, Austin, Tx 54321",
        "flags": [
            "Address Change",
            "DMV Conflict",
            "Lives Elsewhere",
            "Rental",
            "Voter Conflict"
        ],
        "lien_or_back_taxes": null,
        "parcel_created": "2022-08-29T19:47:04.971462+00:00",
        "veteran_exmpt_flag": false,
        "queue": "inbox",
        "unqualified_start_year": null,
        "parcel_updated": "2023-07-07T21:50:48.718230+00:00",
        "disabled_exmpt_flag": true
    },
    {
        "exemptions": "Homestead | Disabled Veteran",
        "prev_queue": "unreleased_ir",
        "identified_on": "2022-09-02T19:12:36.330862+00:00",
        "last_queue_change": "2022-09-02T19:12:36.330862+00:00",
        "labels": null,
        "disabled_veteran_exmpt_flag": true,
        "deed_date": "2018-1-1",
        "has_history": true,
        "assigned_to": null,
        "queue_priority": 8,
        "parcel_num": "R546216",
        "county_lookup_url": "httpR546216",
        "released_ir": true,
        "assigned_to_id": null,
        "has_ir": true,
        "tru_id": "6fafab06-1f21-462d-b761-75ff23d71759",
        "market_value": "$288,838.00",
        "updated_on": "2024-03-29T15:05:33.755239+00:00",
        "priority_score": 5,
        "first_unqualified_year": 2025,
        "owner": "John Doe",
        "assessed_value": "$233,819.00",
        "final_outcome": null,
        "homestead_exmpt_flag": true,
        "senior_exmpt_flag": false,
        "situs": "123 Circle Way, Jarrell, 4321",
        "taxable_value": "$288,838.00",
        "snooze_until": null,
        "owner_type": 2,
        "seniorfreeze_exmpt_flag": false,
        "mail": "10506 Timbercrest Ln, Austin, 78750",
        "flags": [
            "Address Change",
            "DMV Conflict",
            "Lives Elsewhere",
            "Multiple HSE Within Jurisdiction",
            "Multiple Properties",
            "Rental",
            "Situs and Mail Differ",
            "Voter Conflict"
        ],
        "lien_or_back_taxes": null,
        "parcel_created": "2022-08-29T19:47:04.971462+00:00",
        "veteran_exmpt_flag": false,
        "queue": "inbox",
        "unqualified_start_year": null,
        "parcel_updated": "2023-07-07T21:50:48.718230+00:00",
        "disabled_exmpt_flag": false
    },
]


export const flagNames = [
    "Address Change",
    "Address Conflict",
    "Corporate Owner",
    "Deceased",
    "DMV Conflict",
    "DMV Conflict at Previous",
    "Historical",
    "Ineligible Applicant",
    "Lives Elsewhere",
    "Missing Applicant",
    "Multiple HSE Out of Jurisdiction",
    "Multiple HSE Within Jurisdiction",
    "Multiple Properties",
    "Multiple properties within Jurisdiction",
    "Multi principal residence in Jurisdiction",
    "Non-USPS Address",
    "Out of County Mail",
    "Out of State Mail",
    "Out of USA Mail",
    "Rental",
    "Situs and Mail Differ",
    "Trust Owner",
    "Vacancy",
    "Voter Conflict",
    "Voter Conflict at Previous",
    "Wrong County"
]

export const exemptionTypes = [
    "100% Disabled Veteran",
    "Agriculture Use",
    "Armed Forces Active Duty",
    "Builders Inventory",
    "Bus/Pers Mix Use Vehicle Ex-HB 1022",
    "Charity Donated DV Homestead",
    "Comm Housing Dev - 2004",
    "Disabled Person",
    "Disabled Veteran",
    "Disabled Veteran Tax Deferral",
    "DP Tax Deferral",
    "DSSTR",
    "Exempt Property",
    "First Responder (Surviving Spouse)",
    "Freeport",
    "Goods In Transit",
    "Historical",
    "Homestead",
    "House Bill 366",
    "Lease Vehicles Ex",
    "Permanent Total Disability",
    "Pollution Control",
    "Prorated Full Exemption",
    "Solar",
    "Surviving Spouse of 100% Disabled Veteran",
    "Surviving Spouse of Member of Armed Forces",
    "Surviving Spouse of Over 65 Exemption Holder",
    "Tax Code 11.13(c) Exemption",
    "Tax Code 11.13(c) Exemption TD",
    "Waiver of Special Appr"
]

export const applicationVetterList = [
    {
        "fips_code": "48491",
        "tru_id": "9fb52905-2e23-4be7-9526-33f14ea5f144",
        "application_id": "40885",
        "parcel_num": "john-00000009",
        "applicant": "john doe",
        "flags": [
            "DMV Conflict",
            "Wrong County"
        ],
        "exemptions": null,
        "submitted_date": "2024-04-23T18:08:53.731559+00:00",
        "application_status": "approved",
        "application_status_recommendation": "inquire",
        "application_status_last_edited_by": "john@doe.com",
        "tax_year": "2025",
        "late_application_check": false,
        "assigned_user_id": null,
        "assigned_user": null,
        "id_check": false,
        "logic_check": false,
        "deed_check": true,
        "multi_hs_check": true,
        "multi_prop_check": null,
        "voter_check": null,
        "labels": [],
        "snooze_until": "2023-06-28T00:00:00+00:00",
    },
    {
        "fips_code": "48491",
        "tru_id": "17c94954-2680-4336-bba7-bfac7fb4331e",
        "application_id": "215",
        "parcel_num": "robert-00000004",
        "applicant": "robert swanson",
        "flags": [
            "DMV Conflict",
            "Wrong County"
        ],
        "exemptions": null,
        "submitted_date": "2024-04-09T20:20:39.340744+00:00",
        "application_status": "approved",
        "application_status_recommendation": "inquire",
        "application_status_last_edited_by": "robert@swanson.io",
        "tax_year": "2025",
        "late_application_check": false,
        "assigned_user_id": null,
        "assigned_user": null,
        "id_check": false,
        "logic_check": false,
        "deed_check": true,
        "multi_hs_check": true,
        "multi_prop_check": null,
        "voter_check": null,
        "labels": [],
        "snooze_until": "2023-06-28T00:00:00+00:00",
    }
]

export const bulkDataSelected = ["2c3a411f-d9bc-41a0-8318-353acef24a0e", "5b9563ed-b03e-4981-ac44-8f921244ce18"]

export const applicationVetterSelectedLabel = [
    {
        "id": 542,
        "label_name": "Awesome Application Label",
        "candidates": false,
        "applications": true,
        "created_at": "2024-03-07T23:05:34.974708",
        "updated_at": "2024-03-08T16:11:58.215025"
    }
]


export const applicationVeterLabelList = [
    {
        "id": 542,
        "label_name": "Awesome Application Label",
        "candidates": false,
        "applications": true,
        "created_at": "2024-03-07T23:05:34.974708",
        "updated_at": "2024-03-08T16:11:58.215025"
    },
    {
        "id": 68,
        "label_name": "Demo Highlights",
        "candidates": true,
        "applications": true,
        "created_at": "2021-04-13T13:31:08",
        "updated_at": "2024-03-07T19:31:55.096654"
    },
    {
        "id": 533,
        "label_name": "Khurt Test Label",
        "candidates": true,
        "applications": true,
        "created_at": "2024-03-06T14:50:42.870433",
        "updated_at": "2024-03-07T19:37:51.111222"
    },
    {
        "id": 534,
        "label_name": "Pete Test Label",
        "candidates": false,
        "applications": true,
        "created_at": "2024-03-06T14:50:00",
        "updated_at": "2024-03-06T18:00:44.663008"
    },
    {
        "id": 535,
        "label_name": "Test Application Label",
        "candidates": false,
        "applications": true,
        "created_at": "2024-03-06T14:50:00",
        "updated_at": "2024-03-06T18:01:08.211075"
    }
]


export const applicationSubmission: ApplicationForm = {
    appraisal_district_account_number: "R-0123456789",
    filing_late_application: true,
    start_year_of_exemption: (new Date()).getFullYear(),
    exemption_general: true,
    exemption_disabled_person: false,
    exemption_age_65_plus: false,
    exemption_disabled_veteran: false,
    is_veteran_disability_permanent: false,
    exemption_surviving_spouse_armed_services: false,
    exemption_surviving_spouse_first_responder: false,
    exemption_donated_residence: false,
    percent_disability_rating: 70,
    living_on_property: true,
    co_op_housing: true,
    co_op_housing_name: "Shady Acres",
    receiving_exemption_previous_residence: false,
    transferring_exemption_previous_residence: false,
    transferring_tax_limitation: false,
    previous_residence_address_line_1: null,
    previous_residence_unit_number: null,
    previous_residence_city: null,
    previous_residence_state: null,
    previous_residence_postal_code: null,
    previous_residence_county: null,
    property_owner_type_1: OwnerType.Single,
    property_owner_name_1: "Jane Doe",
    property_owner_birthdate_1: new Date("1970-05-01"),
    property_owner_id_number_1: "0123456789",
    property_owner_percent_ownership_interest_1: 100,
    property_owner_email_1: "janedoe@trueroll.io",
    property_owner_phone_1: "555-555-5555",
    property_owner_name_2: null,
    property_owner_birthdate_2: null,
    property_owner_id_number_2: null,
    property_owner_percent_ownership_interest_2: null,
    property_owner_email_2: null,
    property_owner_phone_2: null,
    deceased_spouse_birthdate: null,
    deceased_spouse_name: null,
    affirm_id_property_address_match: true,
    applicant_mailing_address_different: false,
    property_date_acquired: new Date("2010-11-01"),
    property_date_occupied: new Date("2011-11-30"),
    property_address_line_1: "100 Main St",
    property_unit_number: "",
    property_city: "AnyCity",
    property_state: "TX",
    property_postal_code: "90210",
    property_legal_description: "",
    applicant_mail_line_1: null,
    applicant_mail_unit_number: null,
    applicant_mail_city: null,
    applicant_mail_state: null,
    applicant_mail_postal_code: null,
    applicant_identified_on_deed: true,
    court_filing_number: "",
    is_heir_property: false,
    heir_property_owners_occupy_property: false,
    property_manufactured_home: true,
    manufactured_home_make: "Acme",
    manufactured_home_model: "H-001",
    manufactured_home_id: "123000",
    property_produces_income: false,
    percent_producing_income: "",
    property_acres_occupied: 10,
    waive_reason_resident_of_facility: false,
    facility_name_address: "",
    waive_reason_participator_address_confidential_program: false,
    waive_reason_active_duty: false,
    active_duty_documentation_type: null,
    waive_reason_special_drivers_license: false,
    additional_info: "This is a test submission",
    other_owned_properties: "",
    authorized_print_name: "Jane Doe",
    title_authorization: "Property Owner",
    signature_of_applicant: "",
    id_details_property_owner_1: null,
    id_details_property_owner_2: null
}


export const applicationFilterScheme: FilterSchema[] = [
    {
        "field_name": "situs_address",
        "display_name": "Address",
        "type": "str",
        "operators": [
            "=",
            "ilike",
            "starts"
        ],
        "default_operator": "ilike"
    },
    {
        "field_name": "applicant",
        "display_name": "Applicant Name",
        "type": "str",
        "operators": [
            "=",
            "like",
            "starts"
        ],
        "default_operator": "like"
    },
    {
        "field_name": "assigned_to",
        "display_name": "Assigned To",
        "type": "str",
        "operators": [
            "=",
            "in"
        ],
        "default_operator": "=",
        "lookup_url": "users/lookup"
    },
    {
        "field_name": "checks_failed",
        "display_name": "Checks Failed",
        "type": "str",
        "operators": [
            "in"
        ],
        "default_operator": "in",
        "possible_values": [
            "Complaint Submission",
            "ID Verification",
            "Applicants Match Deed",
            "Multiple Homesteads",
            "Voter Registration",
            "Enhanced"
        ]
    },
    {
        "field_name": "checks_passed",
        "display_name": "Checks Passed",
        "type": "str",
        "operators": [
            "in"
        ],
        "default_operator": "in",
        "possible_values": [
            "Complaint Submission",
            "ID Verification",
            "Applicants Match Deed",
            "Multiple Homesteads",
            "Voter Registration",
            "Enhanced"
        ]
    },
    {
        "field_name": "application_id",
        "display_name": "Confirmation Number",
        "type": "str",
        "operators": [
            "=",
            "ilike",
            "starts"
        ],
        "default_operator": "ilike"

    },
    {
        "field_name": "effective_date",
        "display_name": "Effective Date",
        "type": "date",
        "operators": [
            "=",
            "<",
            ">="
        ],
        "default_operator": ">="
    },
    {
        "field_name": "exemptions",
        "display_name": "Exemptions",
        "type": "JSONB",
        "operators": [
            "in",
            "not in"
        ],
        "default_operator": "in",
        "lookup_url": "applications/exemption_types"
    },
    {
        "field_name": "labels",
        "display_name": "Labels",
        "type": "list",
        "operators": [
            "in"
        ],
        "default_operator": "in",
        "lookup_url": "labels/lookup?applications=true"
    },
    {
        "field_name": "last_action",
        "display_name": "Last Action",
        "type": "date",
        "operators": [
            "=",
            "<",
            ">="
        ],
        "default_operator": ">="
    },
    {
        "field_name": "parcel_num",
        "display_name": "Parcel Number",
        "type": "str",
        "operators": [
            "=",
            "ilike",
            "starts"
        ],
        "default_operator": "ilike"
    },
    {
        "field_name": "source",
        "display_name": "Source",
        "type": "str",
        "operators": [
            "in"
        ],
        "default_operator": "in",
        "lookup_url": "applications/sources"
    },
    {
        "field_name": "application_status",
        "display_name": "Status",
        "type": "str",
        "operators": [
            "=",
            "in"
        ],
        "default_operator": "=",
        "possible_values": [
            "pending",
            "deferred",
            "approved",
            "denied"
        ]
    },
    {
        "field_name": "application_status_last_edited_by",
        "display_name": "Status Edited By",
        "type": "str",
        "operators": [
            "=",
            "like",
            "starts"
        ],
        "default_operator": "starts"
    },
    {
        "field_name": "submitted_date",
        "display_name": "Submission Date",
        "type": "date",
        "operators": [
            "=",
            "<",
            ">="
        ],
        "default_operator": ">="
    },
    {
        "field_name": "unassigned",
        "display_name": "Unassigned",
        "type": "bool",
        "operators": [
            "="
        ],
        "default_operator": "="
    },
    {
        "field_name": "exclude_this_field",
        "display_name": "This field should not be included in the field dropdown",
        "type": "bool",
        "operators": ["="],
        "default_operator": "="
    }
]

export const appvetSummaryDetails = {
    tru_id: "89fee7ec-2b15-4897-bbb7-3f78b638e1a9",
    application_id: "35326",
    application_status: "pending",
    situs_parcel_id: "R063309",
    situs_full: "9605 Quilberry Drive, Austin, 78729",
    applicants_names: "larry reno\nsusan reno",
    mail_full: "9605 Quilberry Drive, Austin, 78729",
    prev_situs_full: null,
    exemptions: [
        "Disabled Person"
    ],
    application_timestamp: "2024-05-30T16:17:30.422924+00:00",
    effective_date: "2023-01-01",
    assigned_user_id: "auth0|65f9b70eb5436c9c9272ffd0",
    assigned_user: "khurt + williamson",
    source: null,
    labels: ["Awesome Application Label"],
    snooze_until: null,
    outreach_id: 999,
    outreach_code: "test-999",
    outreach_description: null,
}

export const appvetParties = [{
    "applicant_name": "larry reno",
    "identity_document": null,
    "document_number": null,
    "dob": "1968-02-28",
    "ownership_interest": null,
    "email": "lpeppers620@gmail.com",
    "phone": "512-801-2329",
    "address": "12 Happy St",
    "city": "Pleasantville",
    "state": "NY",
    "postal_code": "00000"
}, {
    "applicant_name": "susan reno",
    "identity_document": null,
    "document_number": null,
    "dob": "1970-08-10",
    "ownership_interest": null,
    "email": "lpeppers620@gmail.com",
    "phone": "512-801-2329",
    "address": "12 Happy St",
    "city": "Pleasantville",
    "state": "NY",
    "postal_code": "00000"
}]


export const appvetParcel = {
    "tru_id": "89fee7ec-2b15-4897-bbb7-3f78b638e1a9",
    "form_id": "f3a5d653-2aa9-4b83-8a22-d31f610a0c9e",
    "tax_years": [
        2023
    ],
    "co_op_housing": false,
    "property_city": "AUSTIN",
    "application_id": "35326",
    "effective_date": "2023-01-01",
    "property_state": "TX",
    "additional_info": null,
    "is_heir_property": false,
    "exemption_general": false,
    "co_op_housing_name": null,
    "living_on_property": true,
    "applicant_mail_city": null,
    "court_filing_number": null,
    "title_authorization": "Property Owner",
    "applicant_mail_state": null,
    "deceased_spouse_name": null,
    "manufactured_home_id": null,
    "property_postal_code": "78729",
    "property_unit_number": null,
    "applicant_mail_line_1": null,
    "authorized_print_name": "Larry Reno",
    "exemption_age_65_plus": false,
    "facility_name_address": null,
    "property_owner_name_1": "LARRY RENO",
    "property_owner_name_2": "Susan Reno",
    "property_owner_type_1": "married",
    "manufactured_home_make": null,
    "other_owned_properties": null,
    "property_date_acquired": "2009-07-06",
    "property_date_occupied": "2009-07-06",
    "property_owner_email_1": "lpeppers620@gmail.com",
    "property_owner_email_2": "lpeppers620@gmail.com",
    "property_owner_phone_1": "512-801-2329",
    "property_owner_phone_2": "512-801-2329",
    "filing_late_application": true,
    "manufactured_home_model": null,
    "previous_residence_city": null,
    "property_acres_occupied": null,
    "property_address_line_1": "9605 QUILBERRY DRIVE",
    "percent_producing_income": null,
    "previous_residence_state": null,
    "property_produces_income": false,
    "waive_reason_active_duty": false,
    "deceased_spouse_birthdate": null,
    "exemption_disabled_person": true,
    "percent_disability_rating": null,
    "previous_residence_county": null,
    "applicant_mail_postal_code": null,
    "applicant_mail_unit_number": null,
    "exemption_disabled_veteran": false,
    "property_legal_description": null,
    "property_manufactured_home": false,
    "property_owner_birthdate_1": "1968-02-28",
    "property_owner_birthdate_2": "1970-08-10",
    "property_owner_id_number_1": "13236221",
    "property_owner_id_number_2": "17673027",
    "exemption_donated_residence": false,
    "transferring_tax_limitation": false,
    "applicant_identified_on_deed": true,
    "active_duty_documentation_type": null,
    "previous_residence_postal_code": null,
    "previous_residence_unit_number": null,
    "is_veteran_disability_permanent": null,
    "affirm_id_property_address_match": true,
    "appraisal_district_account_number": "R063309",
    "previous_residence_address_line_1": null,
    "waive_reason_resident_of_facility": false,
    "applicant_mailing_address_different": false,
    "heir_property_owners_occupy_property": null,
    "waive_reason_special_drivers_license": false,
    "receiving_exemption_previous_residence": false,
    "exemption_surviving_spouse_armed_services": false,
    "transferring_exemption_previous_residence": false,
    "exemption_surviving_spouse_first_responder": false,
    "property_owner_percent_ownership_interest_1": 100,
    "property_owner_percent_ownership_interest_2": null,
    "waive_reason_participator_address_confidential_program": false
}

export const applicationObservations = {
    flags: [
        {
            "name": "Rental",
            "created_by": "--system event--",
            "event_date": "2022-06-04",
            "display_html": "<table class=\"notestable\">\n        <tr>\n            <td class=\"object\" colspan=\"2\">9999 CONSERVATION DR, AUSTIN, TX 9999</td>\n        </tr>\n        \n        <tr>\n            <td class=\"label\">rent:</td>\n            <td class=\"value\">$2,495.00</td>\n        </tr>\n        \n        \n        \n        <tr>\n            <td class=\"label\">listed on:</td>\n            <td class=\"value\">2022-06-04</td>\n        </tr>\n        \n        <tr>\n            <td class=\"label\">source:</td>\n            <td class=\"value\"><a href=\"https://www.zillow.com/\" target=\"_blank\">zillow.com</a></td> <!-- nosemgrep  Links are populated before the user reaches the HTML -->\n            </tr>\n        <tr>\n            <td class=\"label\">match type:</td>\n            <td class=\"value\">address</td>\n        </tr>\n        <tr>\n            <td class=\"label\">date label:</td>\n            <td class=\"value\">seen on</td>\n        </tr>\n    </table>",
            "observation_id": "55d780cfc8ab6a7880e4be8ff02526b7"
        },
        {
            "name": "Multiple Properties",
            "created_by": "--system event--",
            "event_date": "2020-04-10",
            "display_html": "<table class=\"notestable\">\n\n    <tr><td class=\"object\" colspan=\"2\" unit_level_id=\"6fa807d0-4975-c21b-9ce0-96cc4274bd7f\">9999 FOLCHI DR, CINCINNATI, OH 9999</td></tr><tr><td class=\"label\">county:</td>\n        <td class=\"value\">HAMILTON COUNTY, OH</td></tr>\n\n    <tr><td class=\"label\">parcel:</td>\n        <td class=\"value\">9999999999</td></tr>\n    <tr><td class=\"label\">property classification:</td>\n        <td class=\"value\">single family residence</td></tr>\n    <tr><td class=\"label\">owner name:</td>\n        <td class=\"value\">TEST USER</td></tr>\n\n    <tr><td class=\"label\">mailing address:</td>\n        <td class=\"value\">99999 CAMBRIDGE DR, MASON, OH 9999</td></tr>\n    <tr><td class=\"label\">assessed value:</td>\n        <td class=\"value\">$21,421</td></tr>\n    \n    <tr><td class=\"label\">market value:</td>\n        <td class=\"value\">$61,200</td></tr>\n    \n    <tr><td class=\"label\">deed date:</td>\n        <td class=\"value\">2015-01-15</td></tr>\n    <tr><td class=\"label\">source:</td>\n        <td class=\"value\">national parcel database</td></tr>\n\n    <tr><td class=\"label\">match type:</td>\n        <td class=\"value\">rare first &amp; last name</td></tr>\n\n    <tr><td class=\"label\">date label:</td>\n        <td class=\"value\">last refresh</td></tr>\n\n</table>",
            "observation_id": "9962992087c3d093792155c2a42f26a9"
        },
        {
            "name": "I'm a User Created Flag",
            "created_by": "auth0|nobody",
            "event_date": "2020-04-10",
            "display_html": "<div>Hello, World!</div>",
            "observation_id": null
        }
    ],
    notes: [
        {
            "eligibility_rule_id": 205,
            "name": "Applicants Exceed Deeded Owners",
            "display_html": "\n<table class=\"notestable\">\n    \n        <tr><td class=\"object\" colspan=\"2\">APPLICANTS EXCEED DEEDED OWNERS</td></tr>\n    \n    \n        \n            \n                <tr>\n                    <td class=\"label\">applicant name:</td>\n                    <td class=\"value\">SUSAN RENO</td>\n                </tr>\n            \n        \n    \n        \n            \n                <tr>\n                    <td class=\"label\">source:</td>\n                    <td class=\"value\">tax roll</td>\n                </tr>\n            \n        \n    \n        \n            \n                <tr>\n                    <td class=\"label\">match type:</td>\n                    <td class=\"value\">parcel number</td>\n                </tr>\n            \n        \n    \n        \n            \n                <tr>\n                    <td class=\"label\">date label:</td>\n                    <td class=\"value\">application date</td>\n                </tr>\n            \n        \n    \n</table>", "event_date": "2024-05-30", "observation_id": "3d9d5e71fa4ef86c3242fcb855832d03"
        },
        {
            "eligibility_rule_id": 209,
            "name": "Source Confirmation", "display_html": "\n<table class=\"notestable\">\n    \n        <tr><td class=\"object\" colspan=\"2\">VERIFIED IDENTIFICATION</td></tr>\n    \n    \n        \n            \n                <tr>\n                    <td class=\"label\">name on id:</td>\n                    <td class=\"value\">SUSAN LYN RENO</td>\n                </tr>\n            \n        \n    \n        \n            \n                <tr>\n                    <td class=\"label\">name on application:</td>\n                    <td class=\"value\">SUSAN RENO</td>\n                </tr>\n            \n        \n    \n        \n            \n                <tr>\n                    <td class=\"label\">source:</td>\n                    <td class=\"value\">dmv</td>\n                </tr>\n            \n        \n    \n        \n            \n                <tr>\n                    <td class=\"label\">match type:</td>\n                    <td class=\"value\">situs address, applicant birthdate</td>\n                </tr>\n            \n        \n    \n        \n            \n                <tr>\n                    <td class=\"label\">date label:</td>\n                    <td class=\"value\">dmv record</td>\n                </tr>\n            \n        \n    \n</table>", "event_date": "2021-05-29", "observation_id": "50f2c781dac0abe5156acd5338e7402d"
        },
        {
            "eligibility_rule_id": 209,
            "name": "Source Confirmation", "display_html": "\n<table class=\"notestable\">\n    \n        <tr><td class=\"object\" colspan=\"2\">VERIFIED IDENTIFICATION</td></tr>\n    \n    \n        \n            \n                <tr>\n                    <td class=\"label\">name on id:</td>\n                    <td class=\"value\">LARRY DEWAYNE RENO</td>\n                </tr>\n            \n        \n    \n        \n            \n                <tr>\n                    <td class=\"label\">name on application:</td>\n                    <td class=\"value\">LARRY RENO</td>\n                </tr>\n            \n        \n    \n        \n            \n                <tr>\n                    <td class=\"label\">source:</td>\n                    <td class=\"value\">dmv</td>\n                </tr>\n            \n        \n    \n        \n            \n                <tr>\n                    <td class=\"label\">match type:</td>\n                    <td class=\"value\">situs address, applicant birthdate</td>\n                </tr>\n            \n        \n    \n        \n            \n                <tr>\n                    <td class=\"label\">date label:</td>\n                    <td class=\"value\">dmv record</td>\n                </tr>\n            \n        \n    \n</table>",
            "event_date": "2021-05-29",
            "observation_id": "e96c032faf3c08989e0f859137ca0b8e"
        }
    ]
} as Observations


export const applicationHistoryList = [{
    "id": 3850603,
    "created_by": "pete",
    "update_details": "test 2",
    "created_at": "2024-06-13",
    "update_type_id": 27
}, {
    "id": 3850602,
    "created_by": "pete",
    "update_details": "test",
    "created_at": "2024-06-13",
    "update_type_id": 27
}, {
    "id": 3850601,
    "created_by": "",
    "update_details": "Attached<br>Name: signature.png",
    "created_at": "2024-06-12",
    "update_type_id": 14
}] as HistoryAPIResponse[]

export const applicationDocumentList = [
    {
        "document_type": "exemption-disabled-documentation-file",
        "file_name": "1920px-Vincent_Van_Gogh_-_Wheatfield_with_Crows.jpg",
        "file_size": 833124,
        "content_type": "image/jpeg",
        "created_at": "2024-06-12T18:32:41.979390+00:00",
        "download_url": "https://tr-candidate-documents-dev.s3.amazonaws.com/application-attachments/16199368-5aaa-4ea9-90ee-791cf265abef.jpg?response-content-disposition=inline%3B%20filename%3D%221920px-Vincent_Van_Gogh_-_Wheatfield_with_Crows.jpg%22&response-content-type=image%2Fjpeg&AWSAccessKeyId=ASIA3YCB4M45E5MN65T6&Signature=XGSYOacipj3O8HeeRgp9NaPx%2FYw%3D&x-amz-security-token=IQoJb3JpZ2luX2VjEPj%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLXdlc3QtMiJHMEUCIQCa%2BnqHcJ09byf4iHwbPCxByS9lF9g3oojp6TewcvkszwIgeDZcramGEU9TTDIIQcgTy5dzTNYS6KxBilce4FVk7TIq9gMIkf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAAGgw4MDc1OTIwMjc5NjIiDJ%2Fq0Kpl%2BL7DfOS0rCrKA5y5SjTZ4ok%2F%2FPiUc4kr9sOurxfKlxGrJw5zS0ucBLe3rwdAK7oU%2BhEr01JZbyp4J7qJQMCVTyendVw1CIGDuw5rYhLtOQeK9oBX4qTdknb0ZP2IVZYHoHTfv1ziWagiA2qpM1GPzPB0OMF1tGTb9fdUN0G%2BhM%2FzCBI0lV0pVLczpGd2Fva4YqOhNFyUYV4gKNN0kQhclMhtZE2X%2FrW11pBLLE%2BReRxDs41YotpMEoCowd5S%2FpzTngosqg%2FlyIG7aARSdB30tYeQal1w5rM7cqseRAW1EOZTvdITmIGoCENYD0o87SoLGtFSiQpvbZunLqvUkqCMbTMX2IM5Rj9pc6kBW1gB%2BvJ9xjWowR9mac8z8z98%2B20576hR3Px9TyhA4efJU2aDC%2FATKbZ0iLVVt2hZUVW4gQMEKgVT9PYkLTom%2Fg%2F8McKiunVekjaBW0SlRSeo8arvS%2BqUAEDseAZ8XvY%2FrdgSLQqZ7STGrDg%2Fs%2Fu%2FMDnj5nmnw3Ukit%2BGK3BEIPEojh9VmbO5c5JF0G2ZoEWHU0bjmHXNV%2FN3wnGYGz7gWgAFcI8BXipCxu4Tbaq0BS2o5vEQ8ZoVV91UOaaghIKZpwQm9cjp1MHvMNKdrLMGOqUBMcHvaOMJuG1N1CMhNRMF9PxLCavNHETsZcHwJSAAVyk2Plp9sNWrS1mtaon%2B7mfQQI1iypsbw4q4r8CHuOljw9vzD0Mj0H4V5129KEPt6wrPVvuV7Y%2FFa0ZvZOi62p539AaWFmFgHKP24Hr5xzfaPhgb62OALx6y677HnbD0aBa1We%2Bmc5gMnMiP2uVgWvZb8EqyBm1AZLmGp1OAW1ii61iKOBRy&Expires=1718297088"
    },
    {
        "document_type": "exemption-disabled-veteran-documentation-file",
        "file_name": "appvet-layout-take-02.png",
        "file_size": 154024,
        "content_type": "image/png",
        "created_at": "2024-06-12T18:33:07.931188+00:00",
        "download_url": "https://tr-candidate-documents-dev.s3.amazonaws.com/application-attachments/cca77c3d-ec52-44f3-9b58-69616de31702.png?response-content-disposition=inline%3B%20filename%3D%22appvet-layout-take-02.png%22&response-content-type=image%2Fpng&AWSAccessKeyId=ASIA3YCB4M45E5MN65T6&Signature=X2Mvz2PITy%2FIb%2BJ45Ghg131E5Hk%3D&x-amz-security-token=IQoJb3JpZ2luX2VjEPj%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLXdlc3QtMiJHMEUCIQCa%2BnqHcJ09byf4iHwbPCxByS9lF9g3oojp6TewcvkszwIgeDZcramGEU9TTDIIQcgTy5dzTNYS6KxBilce4FVk7TIq9gMIkf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAAGgw4MDc1OTIwMjc5NjIiDJ%2Fq0Kpl%2BL7DfOS0rCrKA5y5SjTZ4ok%2F%2FPiUc4kr9sOurxfKlxGrJw5zS0ucBLe3rwdAK7oU%2BhEr01JZbyp4J7qJQMCVTyendVw1CIGDuw5rYhLtOQeK9oBX4qTdknb0ZP2IVZYHoHTfv1ziWagiA2qpM1GPzPB0OMF1tGTb9fdUN0G%2BhM%2FzCBI0lV0pVLczpGd2Fva4YqOhNFyUYV4gKNN0kQhclMhtZE2X%2FrW11pBLLE%2BReRxDs41YotpMEoCowd5S%2FpzTngosqg%2FlyIG7aARSdB30tYeQal1w5rM7cqseRAW1EOZTvdITmIGoCENYD0o87SoLGtFSiQpvbZunLqvUkqCMbTMX2IM5Rj9pc6kBW1gB%2BvJ9xjWowR9mac8z8z98%2B20576hR3Px9TyhA4efJU2aDC%2FATKbZ0iLVVt2hZUVW4gQMEKgVT9PYkLTom%2Fg%2F8McKiunVekjaBW0SlRSeo8arvS%2BqUAEDseAZ8XvY%2FrdgSLQqZ7STGrDg%2Fs%2Fu%2FMDnj5nmnw3Ukit%2BGK3BEIPEojh9VmbO5c5JF0G2ZoEWHU0bjmHXNV%2FN3wnGYGz7gWgAFcI8BXipCxu4Tbaq0BS2o5vEQ8ZoVV91UOaaghIKZpwQm9cjp1MHvMNKdrLMGOqUBMcHvaOMJuG1N1CMhNRMF9PxLCavNHETsZcHwJSAAVyk2Plp9sNWrS1mtaon%2B7mfQQI1iypsbw4q4r8CHuOljw9vzD0Mj0H4V5129KEPt6wrPVvuV7Y%2FFa0ZvZOi62p539AaWFmFgHKP24Hr5xzfaPhgb62OALx6y677HnbD0aBa1We%2Bmc5gMnMiP2uVgWvZb8EqyBm1AZLmGp1OAW1ii61iKOBRy&Expires=1718297088"
    },
    {
        "document_type": "property-owner-id-file-1",
        "file_name": "Swanson_Robert.png",
        "file_size": 461638,
        "content_type": "image/png",
        "created_at": "2024-06-12T18:33:39.410487+00:00",
        "download_url": "https://tr-candidate-documents-dev.s3.amazonaws.com/application-attachments/03ad4e17-e0ef-46d7-bc80-ce85f5e690f4.png?response-content-disposition=inline%3B%20filename%3D%22Swanson_Robert.png%22&response-content-type=image%2Fpng&AWSAccessKeyId=ASIA3YCB4M45E5MN65T6&Signature=d201yyF%2BBk2RvSA5FkdfOfTrCrw%3D&x-amz-security-token=IQoJb3JpZ2luX2VjEPj%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLXdlc3QtMiJHMEUCIQCa%2BnqHcJ09byf4iHwbPCxByS9lF9g3oojp6TewcvkszwIgeDZcramGEU9TTDIIQcgTy5dzTNYS6KxBilce4FVk7TIq9gMIkf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAAGgw4MDc1OTIwMjc5NjIiDJ%2Fq0Kpl%2BL7DfOS0rCrKA5y5SjTZ4ok%2F%2FPiUc4kr9sOurxfKlxGrJw5zS0ucBLe3rwdAK7oU%2BhEr01JZbyp4J7qJQMCVTyendVw1CIGDuw5rYhLtOQeK9oBX4qTdknb0ZP2IVZYHoHTfv1ziWagiA2qpM1GPzPB0OMF1tGTb9fdUN0G%2BhM%2FzCBI0lV0pVLczpGd2Fva4YqOhNFyUYV4gKNN0kQhclMhtZE2X%2FrW11pBLLE%2BReRxDs41YotpMEoCowd5S%2FpzTngosqg%2FlyIG7aARSdB30tYeQal1w5rM7cqseRAW1EOZTvdITmIGoCENYD0o87SoLGtFSiQpvbZunLqvUkqCMbTMX2IM5Rj9pc6kBW1gB%2BvJ9xjWowR9mac8z8z98%2B20576hR3Px9TyhA4efJU2aDC%2FATKbZ0iLVVt2hZUVW4gQMEKgVT9PYkLTom%2Fg%2F8McKiunVekjaBW0SlRSeo8arvS%2BqUAEDseAZ8XvY%2FrdgSLQqZ7STGrDg%2Fs%2Fu%2FMDnj5nmnw3Ukit%2BGK3BEIPEojh9VmbO5c5JF0G2ZoEWHU0bjmHXNV%2FN3wnGYGz7gWgAFcI8BXipCxu4Tbaq0BS2o5vEQ8ZoVV91UOaaghIKZpwQm9cjp1MHvMNKdrLMGOqUBMcHvaOMJuG1N1CMhNRMF9PxLCavNHETsZcHwJSAAVyk2Plp9sNWrS1mtaon%2B7mfQQI1iypsbw4q4r8CHuOljw9vzD0Mj0H4V5129KEPt6wrPVvuV7Y%2FFa0ZvZOi62p539AaWFmFgHKP24Hr5xzfaPhgb62OALx6y677HnbD0aBa1We%2Bmc5gMnMiP2uVgWvZb8EqyBm1AZLmGp1OAW1ii61iKOBRy&Expires=1718297088"
    },
    {
        "document_type": "property-owner-id-file-2",
        "file_name": "Smith Sarah.png",
        "file_size": 461053,
        "content_type": "image/png",
        "created_at": "2024-06-12T18:33:58.627360+00:00",
        "download_url": "https://tr-candidate-documents-dev.s3.amazonaws.com/application-attachments/822d8ede-9107-4589-9888-a66b2eb83705.png?response-content-disposition=inline%3B%20filename%3D%22Smith%20Sarah.png%22&response-content-type=image%2Fpng&AWSAccessKeyId=ASIA3YCB4M45E5MN65T6&Signature=QM%2BT3s%2FsZq11ahFGzV97AC8NWYE%3D&x-amz-security-token=IQoJb3JpZ2luX2VjEPj%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLXdlc3QtMiJHMEUCIQCa%2BnqHcJ09byf4iHwbPCxByS9lF9g3oojp6TewcvkszwIgeDZcramGEU9TTDIIQcgTy5dzTNYS6KxBilce4FVk7TIq9gMIkf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAAGgw4MDc1OTIwMjc5NjIiDJ%2Fq0Kpl%2BL7DfOS0rCrKA5y5SjTZ4ok%2F%2FPiUc4kr9sOurxfKlxGrJw5zS0ucBLe3rwdAK7oU%2BhEr01JZbyp4J7qJQMCVTyendVw1CIGDuw5rYhLtOQeK9oBX4qTdknb0ZP2IVZYHoHTfv1ziWagiA2qpM1GPzPB0OMF1tGTb9fdUN0G%2BhM%2FzCBI0lV0pVLczpGd2Fva4YqOhNFyUYV4gKNN0kQhclMhtZE2X%2FrW11pBLLE%2BReRxDs41YotpMEoCowd5S%2FpzTngosqg%2FlyIG7aARSdB30tYeQal1w5rM7cqseRAW1EOZTvdITmIGoCENYD0o87SoLGtFSiQpvbZunLqvUkqCMbTMX2IM5Rj9pc6kBW1gB%2BvJ9xjWowR9mac8z8z98%2B20576hR3Px9TyhA4efJU2aDC%2FATKbZ0iLVVt2hZUVW4gQMEKgVT9PYkLTom%2Fg%2F8McKiunVekjaBW0SlRSeo8arvS%2BqUAEDseAZ8XvY%2FrdgSLQqZ7STGrDg%2Fs%2Fu%2FMDnj5nmnw3Ukit%2BGK3BEIPEojh9VmbO5c5JF0G2ZoEWHU0bjmHXNV%2FN3wnGYGz7gWgAFcI8BXipCxu4Tbaq0BS2o5vEQ8ZoVV91UOaaghIKZpwQm9cjp1MHvMNKdrLMGOqUBMcHvaOMJuG1N1CMhNRMF9PxLCavNHETsZcHwJSAAVyk2Plp9sNWrS1mtaon%2B7mfQQI1iypsbw4q4r8CHuOljw9vzD0Mj0H4V5129KEPt6wrPVvuV7Y%2FFa0ZvZOi62p539AaWFmFgHKP24Hr5xzfaPhgb62OALx6y677HnbD0aBa1We%2Bmc5gMnMiP2uVgWvZb8EqyBm1AZLmGp1OAW1ii61iKOBRy&Expires=1718297088"
    },
    {
        "document_type": "applicant-not-on-deed-documentation-file",
        "file_name": "nebraska_example_id.pdf",
        "file_size": 53716,
        "content_type": "application/pdf",
        "created_at": "2024-06-12T18:34:30.633403+00:00",
        "download_url": "https://tr-candidate-documents-dev.s3.amazonaws.com/application-attachments/0aea2ba4-7bc8-4ad8-9717-7fe54de84dfb.pdf?response-content-disposition=inline%3B%20filename%3D%22nebraska_example_id.pdf%22&response-content-type=application%2Fpdf&AWSAccessKeyId=ASIA3YCB4M45E5MN65T6&Signature=eLHy9SBU5%2BUftk58M5F03ixfrC0%3D&x-amz-security-token=IQoJb3JpZ2luX2VjEPj%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLXdlc3QtMiJHMEUCIQCa%2BnqHcJ09byf4iHwbPCxByS9lF9g3oojp6TewcvkszwIgeDZcramGEU9TTDIIQcgTy5dzTNYS6KxBilce4FVk7TIq9gMIkf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAAGgw4MDc1OTIwMjc5NjIiDJ%2Fq0Kpl%2BL7DfOS0rCrKA5y5SjTZ4ok%2F%2FPiUc4kr9sOurxfKlxGrJw5zS0ucBLe3rwdAK7oU%2BhEr01JZbyp4J7qJQMCVTyendVw1CIGDuw5rYhLtOQeK9oBX4qTdknb0ZP2IVZYHoHTfv1ziWagiA2qpM1GPzPB0OMF1tGTb9fdUN0G%2BhM%2FzCBI0lV0pVLczpGd2Fva4YqOhNFyUYV4gKNN0kQhclMhtZE2X%2FrW11pBLLE%2BReRxDs41YotpMEoCowd5S%2FpzTngosqg%2FlyIG7aARSdB30tYeQal1w5rM7cqseRAW1EOZTvdITmIGoCENYD0o87SoLGtFSiQpvbZunLqvUkqCMbTMX2IM5Rj9pc6kBW1gB%2BvJ9xjWowR9mac8z8z98%2B20576hR3Px9TyhA4efJU2aDC%2FATKbZ0iLVVt2hZUVW4gQMEKgVT9PYkLTom%2Fg%2F8McKiunVekjaBW0SlRSeo8arvS%2BqUAEDseAZ8XvY%2FrdgSLQqZ7STGrDg%2Fs%2Fu%2FMDnj5nmnw3Ukit%2BGK3BEIPEojh9VmbO5c5JF0G2ZoEWHU0bjmHXNV%2FN3wnGYGz7gWgAFcI8BXipCxu4Tbaq0BS2o5vEQ8ZoVV91UOaaghIKZpwQm9cjp1MHvMNKdrLMGOqUBMcHvaOMJuG1N1CMhNRMF9PxLCavNHETsZcHwJSAAVyk2Plp9sNWrS1mtaon%2B7mfQQI1iypsbw4q4r8CHuOljw9vzD0Mj0H4V5129KEPt6wrPVvuV7Y%2FFa0ZvZOi62p539AaWFmFgHKP24Hr5xzfaPhgb62OALx6y677HnbD0aBa1We%2Bmc5gMnMiP2uVgWvZb8EqyBm1AZLmGp1OAW1ii61iKOBRy&Expires=1718297088"
    },
    {
        "document_type": "pdf",
        "file_name": "44230_TEST-0002.pdf",
        "file_size": 1393026,
        "content_type": "application/pdf",
        "created_at": "2024-06-12T18:34:59.061865+00:00",
        "download_url": "https://homestead-application-forms-dev.s3.amazonaws.com/02282a88-e5d5-48b8-9589-670124ec40a2.pdf?response-content-disposition=inline%3B%20filename%3D%2244230_TEST-0002.pdf%22&response-content-type=application%2Fpdf&AWSAccessKeyId=ASIA3YCB4M45E5MN65T6&Signature=VpR37kLF0vM13L%2BKwpLqSlsiSMU%3D&x-amz-security-token=IQoJb3JpZ2luX2VjEPj%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLXdlc3QtMiJHMEUCIQCa%2BnqHcJ09byf4iHwbPCxByS9lF9g3oojp6TewcvkszwIgeDZcramGEU9TTDIIQcgTy5dzTNYS6KxBilce4FVk7TIq9gMIkf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAAGgw4MDc1OTIwMjc5NjIiDJ%2Fq0Kpl%2BL7DfOS0rCrKA5y5SjTZ4ok%2F%2FPiUc4kr9sOurxfKlxGrJw5zS0ucBLe3rwdAK7oU%2BhEr01JZbyp4J7qJQMCVTyendVw1CIGDuw5rYhLtOQeK9oBX4qTdknb0ZP2IVZYHoHTfv1ziWagiA2qpM1GPzPB0OMF1tGTb9fdUN0G%2BhM%2FzCBI0lV0pVLczpGd2Fva4YqOhNFyUYV4gKNN0kQhclMhtZE2X%2FrW11pBLLE%2BReRxDs41YotpMEoCowd5S%2FpzTngosqg%2FlyIG7aARSdB30tYeQal1w5rM7cqseRAW1EOZTvdITmIGoCENYD0o87SoLGtFSiQpvbZunLqvUkqCMbTMX2IM5Rj9pc6kBW1gB%2BvJ9xjWowR9mac8z8z98%2B20576hR3Px9TyhA4efJU2aDC%2FATKbZ0iLVVt2hZUVW4gQMEKgVT9PYkLTom%2Fg%2F8McKiunVekjaBW0SlRSeo8arvS%2BqUAEDseAZ8XvY%2FrdgSLQqZ7STGrDg%2Fs%2Fu%2FMDnj5nmnw3Ukit%2BGK3BEIPEojh9VmbO5c5JF0G2ZoEWHU0bjmHXNV%2FN3wnGYGz7gWgAFcI8BXipCxu4Tbaq0BS2o5vEQ8ZoVV91UOaaghIKZpwQm9cjp1MHvMNKdrLMGOqUBMcHvaOMJuG1N1CMhNRMF9PxLCavNHETsZcHwJSAAVyk2Plp9sNWrS1mtaon%2B7mfQQI1iypsbw4q4r8CHuOljw9vzD0Mj0H4V5129KEPt6wrPVvuV7Y%2FFa0ZvZOi62p539AaWFmFgHKP24Hr5xzfaPhgb62OALx6y677HnbD0aBa1We%2Bmc5gMnMiP2uVgWvZb8EqyBm1AZLmGp1OAW1ii61iKOBRy&Expires=1718297088"
    }
] as SupportingDocument[]

export const internalSettings = [{
    "value": [{
        "columns": [{
            "fields": [{
                "field_name": "priority_score"
            }],
            "column_index": 4
        }],
        "view_name": "inbox"
    }],
    "parameter": "promon_list_layout"
}, {
    "value": "Test 123",
    "parameter": "app_id_property_name"
}, {
    "value": false,
    "parameter": "enable_beta_features"
}, {
    "value": "additive",
    "parameter": "prioritization_model"
}, {
    "value": null,
    "parameter": "application_processing"
}, {
    "value": "https://trueroll.io",
    "parameter": "appvetter_dashboard_url"
}]



export const appvetDefaultParcelDisplayResponse = {
    "value": [
        {
            "name": "Appraisal District Account Number",
            "display_name": null,
            "description": null,
            "keys": [
                "appraisal_district_account_number"
            ]
        },
        {
            "name": "Filing Late Application",
            "display_name": null,
            "description": null,
            "keys": [
                "filing_late_application"
            ]
        },
        {
            "name": "Exemption General",
            "display_name": null,
            "description": null,
            "keys": [
                "exemption_general"
            ]
        },
        {
            "name": "Exemption Disabled Person",
            "display_name": null,
            "description": null,
            "keys": [
                "exemption_disabled_person"
            ]
        },
        {
            "name": "Exemption Age 65 Plus",
            "display_name": null,
            "description": null,
            "keys": [
                "exemption_age_65_plus"
            ]
        },
        {
            "name": "Exemption Disabled Veteran",
            "display_name": null,
            "description": null,
            "keys": [
                "exemption_disabled_veteran"
            ]
        },
        {
            "name": "Is Veteran Disability Permanent",
            "display_name": null,
            "description": null,
            "keys": [
                "is_veteran_disability_permanent"
            ]
        },
        {
            "name": "Exemption Surviving Spouse Armed Services",
            "display_name": null,
            "description": null,
            "keys": [
                "exemption_surviving_spouse_armed_services"
            ]
        },
        {
            "name": "Exemption Surviving Spouse First Responder",
            "display_name": null,
            "description": null,
            "keys": [
                "exemption_surviving_spouse_first_responder"
            ]
        },
        {
            "name": "Exemption Donated Residence",
            "display_name": null,
            "description": null,
            "keys": [
                "exemption_donated_residence"
            ]
        },
        {
            "name": "Percent Disability Rating",
            "display_name": null,
            "description": null,
            "keys": [
                "percent_disability_rating"
            ]
        },
        {
            "name": "Living On Property",
            "display_name": null,
            "description": null,
            "keys": [
                "living_on_property"
            ]
        },
        {
            "name": "Co Op Housing",
            "display_name": null,
            "description": null,
            "keys": [
                "co_op_housing"
            ]
        },
        {
            "name": "Co Op Housing Name",
            "display_name": null,
            "description": null,
            "keys": [
                "co_op_housing_name"
            ]
        },
        {
            "name": "Receiving Exemption Previous Residence",
            "display_name": null,
            "description": null,
            "keys": [
                "receiving_exemption_previous_residence"
            ]
        },
        {
            "name": "Transferring Exemption Previous Residence",
            "display_name": null,
            "description": null,
            "keys": [
                "transferring_exemption_previous_residence"
            ]
        },
        {
            "name": "Transferring Tax Limitation",
            "display_name": null,
            "description": null,
            "keys": [
                "transferring_tax_limitation"
            ]
        },
        {
            "name": "Previous Residence Address Line 1",
            "display_name": null,
            "description": null,
            "keys": [
                "previous_residence_address_line_1"
            ]
        },
        {
            "name": "Previous Residence Unit Number",
            "display_name": null,
            "description": null,
            "keys": [
                "previous_residence_unit_number"
            ]
        },
        {
            "name": "Previous Residence City",
            "display_name": null,
            "description": null,
            "keys": [
                "previous_residence_city"
            ]
        },
        {
            "name": "Previous Residence State",
            "display_name": null,
            "description": null,
            "keys": [
                "previous_residence_state"
            ]
        },
        {
            "name": "Previous Residence Postal Code",
            "display_name": null,
            "description": null,
            "keys": [
                "previous_residence_postal_code"
            ]
        },
        {
            "name": "Previous Residence County",
            "display_name": null,
            "description": null,
            "keys": [
                "previous_residence_county"
            ]
        },
        {
            "name": "Property Owner Type 1",
            "display_name": null,
            "description": null,
            "keys": [
                "property_owner_type_1"
            ]
        },
        {
            "name": "Property Owner Name 1",
            "display_name": null,
            "description": null,
            "keys": [
                "property_owner_name_1"
            ]
        },
        {
            "name": "Property Owner Birthdate 1",
            "display_name": null,
            "description": null,
            "keys": [
                "property_owner_birthdate_1"
            ]
        },
        {
            "name": "Property Owner Id Number 1",
            "display_name": null,
            "description": null,
            "keys": [
                "property_owner_id_number_1"
            ]
        },
        {
            "name": "Property Owner Percent Ownership Interest 1",
            "display_name": null,
            "description": null,
            "keys": [
                "property_owner_percent_ownership_interest_1"
            ]
        },
        {
            "name": "Property Owner Email 1",
            "display_name": null,
            "description": null,
            "keys": [
                "property_owner_email_1"
            ]
        },
        {
            "name": "Property Owner Phone 1",
            "display_name": null,
            "description": null,
            "keys": [
                "property_owner_phone_1"
            ]
        },
        {
            "name": "Property Owner Name 2",
            "display_name": null,
            "description": null,
            "keys": [
                "property_owner_name_2"
            ]
        },
        {
            "name": "Property Owner Birthdate 2",
            "display_name": null,
            "description": null,
            "keys": [
                "property_owner_birthdate_2"
            ]
        },
        {
            "name": "Property Owner Id Number 2",
            "display_name": null,
            "description": null,
            "keys": [
                "property_owner_id_number_2"
            ]
        },
        {
            "name": "Property Owner Percent Ownership Interest 2",
            "display_name": null,
            "description": null,
            "keys": [
                "property_owner_percent_ownership_interest_2"
            ]
        },
        {
            "name": "Property Owner Email 2",
            "display_name": null,
            "description": null,
            "keys": [
                "property_owner_email_2"
            ]
        },
        {
            "name": "Property Owner Phone 2",
            "display_name": null,
            "description": null,
            "keys": [
                "property_owner_phone_2"
            ]
        },
        {
            "name": "Deceased Spouse Birthdate",
            "display_name": null,
            "description": null,
            "keys": [
                "deceased_spouse_birthdate"
            ]
        },
        {
            "name": "Deceased Spouse Name",
            "display_name": null,
            "description": null,
            "keys": [
                "deceased_spouse_name"
            ]
        },
        {
            "name": "Affirm Id Property Address Match",
            "display_name": null,
            "description": null,
            "keys": [
                "affirm_id_property_address_match"
            ]
        },
        {
            "name": "Applicant Mailing Address Different",
            "display_name": null,
            "description": null,
            "keys": [
                "applicant_mailing_address_different"
            ]
        },
        {
            "name": "Property Date Acquired",
            "display_name": null,
            "description": null,
            "keys": [
                "property_date_acquired"
            ]
        },
        {
            "name": "Property Date Occupied",
            "display_name": null,
            "description": null,
            "keys": [
                "property_date_occupied"
            ]
        },
        {
            "name": "Property Address Line 1",
            "display_name": null,
            "description": null,
            "keys": [
                "property_address_line_1"
            ]
        },
        {
            "name": "Property Unit Number",
            "display_name": null,
            "description": null,
            "keys": [
                "property_unit_number"
            ]
        },
        {
            "name": "Property City",
            "display_name": null,
            "description": null,
            "keys": [
                "property_city"
            ]
        },
        {
            "name": "Property State",
            "display_name": null,
            "description": null,
            "keys": [
                "property_state"
            ]
        },
        {
            "name": "Property Postal Code",
            "display_name": null,
            "description": null,
            "keys": [
                "property_postal_code"
            ]
        },
        {
            "name": "Property Legal Description",
            "display_name": null,
            "description": null,
            "keys": [
                "property_legal_description"
            ]
        },
        {
            "name": "Applicant Mail Line 1",
            "display_name": null,
            "description": null,
            "keys": [
                "applicant_mail_line_1"
            ]
        },
        {
            "name": "Applicant Mail Unit Number",
            "display_name": null,
            "description": null,
            "keys": [
                "applicant_mail_unit_number"
            ]
        },
        {
            "name": "Applicant Mail City",
            "display_name": null,
            "description": null,
            "keys": [
                "applicant_mail_city"
            ]
        },
        {
            "name": "Applicant Mail State",
            "display_name": null,
            "description": null,
            "keys": [
                "applicant_mail_state"
            ]
        },
        {
            "name": "Applicant Mail Postal Code",
            "display_name": null,
            "description": null,
            "keys": [
                "applicant_mail_postal_code"
            ]
        },
        {
            "name": "Applicant Identified On Deed",
            "display_name": null,
            "description": null,
            "keys": [
                "applicant_identified_on_deed"
            ]
        },
        {
            "name": "Court Filing Number",
            "display_name": null,
            "description": null,
            "keys": [
                "court_filing_number"
            ]
        },
        {
            "name": "Is Heir Property",
            "display_name": null,
            "description": null,
            "keys": [
                "is_heir_property"
            ]
        },
        {
            "name": "Heir Property Owners Occupy Property",
            "display_name": null,
            "description": null,
            "keys": [
                "heir_property_owners_occupy_property"
            ]
        },
        {
            "name": "Property Manufactured Home",
            "display_name": null,
            "description": null,
            "keys": [
                "property_manufactured_home"
            ]
        },
        {
            "name": "Manufactured Home Make",
            "display_name": null,
            "description": null,
            "keys": [
                "manufactured_home_make"
            ]
        },
        {
            "name": "Manufactured Home Model",
            "display_name": null,
            "description": null,
            "keys": [
                "manufactured_home_model"
            ]
        },
        {
            "name": "Manufactured Home Id",
            "display_name": null,
            "description": null,
            "keys": [
                "manufactured_home_id"
            ]
        },
        {
            "name": "Property Produces Income",
            "display_name": null,
            "description": null,
            "keys": [
                "property_produces_income"
            ]
        },
        {
            "name": "Percent Producing Income",
            "display_name": null,
            "description": null,
            "keys": [
                "percent_producing_income"
            ]
        },
        {
            "name": "Property Acres Occupied",
            "display_name": null,
            "description": null,
            "keys": [
                "property_acres_occupied"
            ]
        },
        {
            "name": "Waive Reason Resident Of Facility",
            "display_name": null,
            "description": null,
            "keys": [
                "waive_reason_resident_of_facility"
            ]
        },
        {
            "name": "Facility Name Address",
            "display_name": null,
            "description": null,
            "keys": [
                "facility_name_address"
            ]
        },
        {
            "name": "Waive Reason Participator Address Confidential Program",
            "display_name": null,
            "description": null,
            "keys": [
                "waive_reason_participator_address_confidential_program"
            ]
        },
        {
            "name": "Waive Reason Active Duty",
            "display_name": null,
            "description": null,
            "keys": [
                "waive_reason_active_duty"
            ]
        },
        {
            "name": "Active Duty Documentation Type",
            "display_name": null,
            "description": null,
            "keys": [
                "active_duty_documentation_type"
            ]
        },
        {
            "name": "Waive Reason Special Drivers License",
            "display_name": null,
            "description": null,
            "keys": [
                "waive_reason_special_drivers_license"
            ]
        },
        {
            "name": "Additional Info",
            "display_name": null,
            "description": null,
            "keys": [
                "additional_info"
            ]
        },
        {
            "name": "Other Owned Properties",
            "display_name": null,
            "description": null,
            "keys": [
                "other_owned_properties"
            ]
        },
        {
            "name": "Authorized Print Name",
            "display_name": null,
            "description": null,
            "keys": [
                "authorized_print_name"
            ]
        },
        {
            "name": "Title Authorization",
            "display_name": null,
            "description": null,
            "keys": [
                "title_authorization"
            ]
        }
    ],
    "parameter": "application_parcel_display"
}

export const gaFormDummyData: ApplicationDataGA = {
    tru_id: "1234567890",

    // section a
    other_property_homestead_exemption_address: "123 Example St, Sampletown, GA, 12345",
    georgia_resident_and_immigration_status: true,
    legal_alien_registration_number: "A123456789",

    // property info
    parcel_number: "GA-1234-5678",
    property_date_purchased: "1/1/2022",
    property_from_whom_purchased: "John Doe",
    property_purchase_price: 250000,
    property_amount_lien: 50000,
    property_title_type: 1,
    property_lien_due_to: 1,
    property_kind_of_title_held: "Joint",
    property_lot_size_or_acres: 0.5,
    property_land_lot_numbers: 789,
    property_land_district_number: 5,
    property_book: "BK2022",
    property_page: "45A",

    // property address
    property_address_line_1: "456 Example Dr",
    property_unit_number: "Unit B",
    property_city: "Sampletown",
    property_state: "GA",
    property_postal_code: "12345",

    // residence
    is_primary_residence: true,
    residence_address_line_1: "456 Example Dr",
    residence_unit_number: "Unit B",
    residence_city: "Sampletown",
    residence_state: "GA",
    residence_postal_code: "12345",
    has_spouse_or_additional_owner: false,

    // owner 1
    property_owner_name_1: "Jane Doe",
    property_owner_address_line_1: "456 Example Dr",
    property_owner_unit_number_1: "Unit B",
    property_owner_city_1: "Sampletown",
    property_owner_state_1: "GA",
    property_owner_postal_code_1: "12345",
    property_owner_ssn_1: "123213213",
    property_owner_birthdate_1: "1/1/1980",
    property_owner_phone_1: "555-123-4567",
    property_owner_email_1: "jane.doe@example.com",
    property_owner_id_number_1: "GA-987654321",
    property_owner_car_registration_county_1: "Sample County",
    property_owner_voter_registration_county_1: "Sample County",

    // owner 1 continued
    is_resident_citizen_or_authorized: true,
    legal_alien_registration: "A123456789",

    // owner 2
    property_owner_name_2: null,
    property_owner_address_line_2: null,
    property_owner_unit_number_2: null,
    property_owner_city_2: null,
    property_owner_state_2: null,
    property_owner_postal_code_2: null,
    property_owner_ssn_2: null,
    property_owner_birthdate_2: null,
    property_owner_phone_2: null,
    property_owner_email_2: null,
    property_owner_id_number_2: null,
    property_owner_voter_registration_county_2: null,
    military_service_home_of_record: "Sample Base, GA",
    receiving_homestead_exemption_elsewhere: false,
    location_of_homestead_exemption: null,

    // owner continued
    is_applicant_100_percent_disabled: false,
    is_applicant_100_percent_disabled_file: null,
    is_surviving_spouse_of_service_member: false,
    is_surviving_spouse_of_service_member_file: null,
    is_surviving_spouse_of_first_responder: false,
    is_surviving_spouse_of_first_responder_file: null,
    is_disabled_since_jan1_and_below_income_threshold: false,
    is_disabled_since_jan1_and_below_income_threshold_file: null,
    age_70_or_older_as_of_jan_1: true,
    age_70_or_older_as_of_jan_1_file: null,
    age_65_or_older_and_income_limit_10280: false,
    age_65_or_older_and_income_limit_10280_file: null,
    age_62_or_older_and_income_limit_25000: false,
    age_62_or_older_and_income_limit_25000_file: null,
    disability_and_income_below_25000_as_of_jan_1: false,
    disability_and_income_below_25000_as_of_jan_1_file: null,
    disability_and_income_below_25000_as_of_jan_1_file_2: null,

    is_100_percent_disabled_veteran_or_surviving_spouse: false,
    is_surviving_spouse_of_kia_firefighter_or_officer: false,

    // property continued
    is_property_used_for_business: false,
    property_business_use_description: null,
    is_property_rented: false,
    property_part_rented: null,

    // signature & date
    signature_of_applicant: "Jane Doe",
    signature_date: "12/3/2024",

    sectionC1_income_tax_year: 2023,
    sectionC1: [
        { description: 'Line 1: Total Income from Public or Private retirement, disability or pension system', applicant1: 30000, applicant2: 20000 },
        { description: 'Line 2: Total Income from Social Security', applicant1: 15000, applicant2: 10000 },
        { description: 'Line 3: Total Income from both retirement and Social Security (Line 1 plus Line 2)', applicant1: 45000, applicant2: 30000 },
        { description: 'Line 4: Maximum Social Security amount (from Tax Receiver)', applicant1: 20000, applicant2: 20000 },
        { description: 'Line 5: Retirement Income over maximum Social Security (Line 3 less Line 4) - If less than 0, use 0', applicant1: 25000, applicant2: 10000 },
        { description: 'Line 6: Other income from all sources', applicant1: 5000, applicant2: 3000 },
        { description: 'Line 7: Adjusted Income (Line 5 plus Line 6)', applicant1: 30000, applicant2: 13000 },
        { description: 'Line 8: Standard or Itemized Deductions from Georgia Income Tax Return', applicant1: 12000, applicant2: 12000 },
        { description: 'Line 9: Personal Exemption amount from Georgia Income Tax Return', applicant1: 3000, applicant2: 3000 },
        { description: 'Line 10: Net Income (Line 7 less Lines 8 and 9)', applicant1: 15000, applicant2: 3000 }
    ],

    sectionC2_income_tax_year: 2023,
    sectionC2: [
        { description: "Line 1: Income Adjustment A", name: "Adjustment A", value: 1000 },
        { description: "Line 2: Income Adjustment B", name: "Adjustment B", value: 2000 },
        { description: "Line 3: Income Adjustment C", name: "Adjustment C", value: 1500 },
        { description: "Line 4: Income Adjustment D", name: "Adjustment D", value: 500 },
        { description: "Line 5: Income Adjustment E", name: "Adjustment E", value: 100 },
        { description: "Line 6: Income Adjustment F", name: "Adjustment F", value: 0 },
        { description: "Line 7: Income Adjustment G", name: "Adjustment G", value: 250 }
    ],
    sectionC2_gross_income_total: 7850,
    agree_to_terms_and_conditions: true
};

export const outreachList = [
    {
        "id": 1,
        "code": "test-1",
        "description": "description-1",
        "created_at": "2024-12-10T08:21:14.899546+00:00",
        "created_by": "auth0|63f77f7e7af7d78587aef4f0",
        "updated_by": "auth0|63f77f7e7af7d78587aef4f0",
        "updated_at": "2024-12-10T17:30:00.184539+00:00"
    },
    {
        "id": 2,
        "code": "test-2",
        "description": "description-2",
        "created_at": "2024-12-10T08:31:35.811122+00:00",
        "created_by": "auth0|63f77f7e7af7d78587aef4f0",
        "updated_by": "auth0|63f77f7e7af7d78587aef4f0",
        "updated_at": "2024-12-10T17:30:35.283496+00:00"
    },
    {
        "id": 3,
        "code": "test-3",
        "description": "description-3",
        "created_at": "2024-12-10T17:07:57.615253+00:00",
        "created_by": "auth0|63f77f7e7af7d78587aef4f0",
        "updated_by": "auth0|63f77f7e7af7d78587aef4f0",
        "updated_at": "2024-12-10T17:53:34.770662+00:00"
    },
    {
        "id": 4,
        "code": "test-4",
        "description": "description-4",
        "created_at": "2024-12-10T18:01:15.023192+00:00",
        "created_by": "auth0|63f77f7e7af7d78587aef4f0",
        "updated_by": null,
        "updated_at": "9999-12-31T23:59:59.999999"
    }
]
