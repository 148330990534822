<template>
  <div class="">
    <div class="modal-dialog" v-if="!state.loading">
      <div class="modal-content">
        <div class="modal-header">
            <div class="modal-title">
                <h5><b>Assign Config:</b></h5>
            </div>
        </div>
        <hr>
        <div class="modal-body" v-if="!state.loading">
          <div class="mb-3">
            <label for="customerInput" class="form-label">Customer</label>
            <input type="text" class="form-control" id="customerInput" placeholder="Select Customer"
              list="customers" v-model="customerSelectedLabel">
            <datalist id="customers">
              <option v-for="customer in state.customers" :key="customer.customer_id"
                :value="`(${customer.customer_id}) ${customer.name}`" />
            </datalist>
          </div>
        </div>
        <div class="modal-body" v-if="!state.loading">
          <div class="mb-3">
            <label for="configInput" class="form-label">Config</label>
            <input type="text" class="form-control" id="configInput" placeholder="Select Config"
              list="configList" v-model="configSelectedLabel">
            <datalist id="configList">
              <option v-for="config in state.configs" :key="config.id"
                :value="`(${config.id}) ${config.name}`" />
            </datalist>
          </div>
        </div>
        <div class="modal-body" v-if="!state.loading">
          <div class="mb-3">
            <label for="configType" class="form-label">Config Type</label>
            <select id="configType" class="form-control" placeholder="Config Type" v-model="state.config_type">
              <option
                v-for="option in options"
                :key="option.value"
                :value="option.value">
                {{ option.text }}
              </option>
            </select>
          </div>
        </div>
        <div class="modal-body" v-if="!state.loading">
          <button type="button" class="btn btn-outline-primary btn-sm m-1" @click="assignConfig" :disabled="!state.config_type || !state.customer_id || !state.config_id">
            Assign Config
          </button>
        </div>
        <div class="modal-footer">
          <button @click="closePopup" style="border: 1px solid #ccc" class="btn btn-default">Close</button>
        </div>
      </div>
    </div>
    <loading-icon v-else />
  </div>
</template>

<script setup lang="ts">

import {computed, onMounted, reactive, ref} from 'vue';
import type {Config} from "@/helpers/interface/onboarding";
import type {Customer} from "@/helpers/interface/trueload";
import LoadingIcon from "@/components/Shared/LoadingIcon.vue";
import {useAPI} from "@/helpers/services/api";
import {toast} from "@/helpers/toast";

const api = useAPI()

const emits = defineEmits(['closePopup'])
const closePopup = () => emits("closePopup", false)

const props = defineProps({
    customer_id: {
      type: String,
      default: '',
    },
    config_id: {
      type: String,
      default: '',
    },
    config_type: {
      type: String,
      default: '',
    }
})

const state = reactive({
  customer_id: '',
  config_id: '',
  config_type: '',
  configs: [] as Array<Config>,
  customers: [] as Array<Customer>,
  loading: false,
})

const options = ref([
      { value: 'taxroll_config', text: 'Taxroll' },
      { value: 'ocr_config', text: 'OCR Applications' },
      { value: 'digital_config', text: 'Digital Applications' },
    ]);

const assignConfig = async () => {
  try {
    await api.patch(`onboarding/ingestions/configs/${state.customer_id}/${state.config_type}/${state.config_id}`)
  } catch (e: any) {
    toast.error(e?.message || "Error assigning config")
    throw e
  } finally {
    toast.success("Config Assigned")
  }
}

const getCustomers = async () => {
  const response = await api.get(`customers/`);
  state.customers = response.data;
}

const getConfigs = async () => {
  const response = await api.get(`onboarding/configs/`);
  state.configs = response.data;
}

const customerSelectedLabel = computed({
  get() {
    return state.customer_id ? `(${state.customer_id}) ${state.customers.find(c => c.customer_id == state.customer_id)?.name}` : ''
  },
  set(value) {
    const match = value.match(/^\(([^)]+)\)/)
    if (match) {
      state.customer_id = match[1]
    }
  }
})

const configSelectedLabel = computed({
  get() {
    return state.config_id ? `(${state.config_id}) ${state.configs.find(c => c.id == state.config_id)?.name}` : ''
  },
  set(value) {
    const match = value.match(/[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}/i)
    if (match) {
      state.config_id = match[0]
    }
  }
})

onMounted(async () => {
  state.loading = true
  state.customer_id = props.customer_id
  state.config_id = props.config_id
  state.config_type = props.config_type
  await Promise.all([
    getCustomers(),
    getConfigs()
  ])
  state.loading = false
})

</script>
